import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import * as Tokens from '@shared/core/tokens';

import * as Utils from '@shared/core/utils';

import { Observable, combineLatest, of } from 'rxjs';
import { map, filter, withLatestFrom, take, switchMap } from 'rxjs/operators';
import { OnlineMenuMapper } from '@shared/core/mappers/online-menu.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class OnlineMenuService {
    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
        public store: Store<OLO.State>,
    ) {}

    public getMenuPages(p: APICommon.OnlineMenuGetParams = { includePrices: true }): Observable<OLO.DTO.OnlineMenuResponseModel> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                ...(p as any),
            },
        });

        return this.httpClient
            .get<APIv3.OnlineMenuResponseModel>(`${this.config.api.base}/OnlineMenu`, { params })
            .pipe(map((menuPages: APIv3.MenuFlowDetailsModel) => OnlineMenuMapper.mapGetMenuPages(menuPages, p.virtualLocationNo)));
    }

    public getFlatProductsFromMenuPages(pages: OLO.DTO.OnlineMenuPageResponseModel[] = []): OLO.DTO.OnlineMenuProductResponseModel[] {
        //
        //  Gets all products in online menu (All pages) and creates flat collection
        //  - usefull for further processing, i.e. getting images for these products.
        //
        const products: OLO.DTO.OnlineMenuProductResponseModel[] = [];

        pages.forEach((page) => {
            page.Products.forEach((product) => (products.find((p) => p.Id === product.Id) ? null : products.push(product)));
        });

        return products;
    }

    public requestOnlineMenuForLocation(locationNo: number): void {
        combineLatest([
            this.store.pipe(
                select(selectors.getAvailablePickupTimesForLocation(locationNo)),
                filter((obj) => obj?.isCalculating === false),
            ),
            this.store.pipe(select(selectors.getLocationDetails(locationNo))),
            this.store.pipe(select(selectors.getSelectedOrderTypeId)),
        ])
            .pipe(
                switchMap(([, locationDetails, orderTypeId]) => {
                    const orderInfo = Utils.LocationPickups.getCompleteOrderInfoByDate({ location: locationDetails, date: null, orderTypeId });

                    return of(orderInfo).pipe(
                        filter((obj) => obj.isOpen !== null),
                        withLatestFrom(
                            this.store.pipe(select(selectors.getCart)),
                            this.store.pipe(
                                select(selectors.getLocationFilters),
                                map((filters) => filters.pickupTime),
                            ),
                            this.store.pipe(select(selectors.getCurrentPickupTime)),
                            this.store.pipe(select(selectors.getAppLocationMode)),
                            this.store.pipe(select(selectors.getCollectionType)),
                        ),
                        map(
                            ([, cart, filterPickup, currentPickup, locationMode, currentCollectionType]) =>
                                [cart, filterPickup, currentPickup, locationMode, currentCollectionType, locationDetails, orderTypeId] as const,
                        ),
                    );
                }),
                take(1),
            )
            .subscribe(([cart, filterPickup, currentPickup, locationMode, currentCollectionType, locationDetails, orderTypeId]) => {
                const availablePickups = Utils.LocationPickups.getAvailablePickupTimesWithFutureForLocation({
                    location: locationDetails,
                    orderTypeId,
                    futureOrders: this.config.onlineOrders.scheduledOrders === true,
                });
                if (!availablePickups) return;
                let matchedPickupTime: OLO.Ordering.PickupTime;

                /* Check filters */
                if (filterPickup) {
                    /* validate filter pickup if it matches current location pickups */
                    if (filterPickup.IsToday) {
                        const timeInfo = availablePickups.find((pickup) => pickup.Id === filterPickup.Id);
                        if (timeInfo) {
                            matchedPickupTime = filterPickup;
                        }
                    } else {
                        const timeInfo: OLO.DTO.LocationOrderingTimeInfoModel = locationDetails?.OrderingTimeInfo?.find(
                            Utils.Pickups.datesMatchByDayCallback(filterPickup.DateLocalISO),
                        );

                        if (timeInfo) {
                            const { FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead } = Utils.LocationFutureOrdering.getLocationFutureOrderingDetails({
                                location: locationDetails,
                                orderTypeId: cart.orderTypeId,
                                backupOrderTypeId: currentCollectionType.orderTypeId,
                            });

                            /* Is in range of found ordering time info */
                            const isInValidRange: boolean = Utils.Pickups.isFuturePickupTimeValid(filterPickup, timeInfo, FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead);
                            if (isInValidRange) {
                                matchedPickupTime = filterPickup;
                            }
                        }
                    }
                }

                /* set from cart */
                if (!matchedPickupTime && cart.pickupTime) {
                    const matchedCartPickupTime = availablePickups.find((pickup) => pickup.Id === cart.pickupTime.Id);
                    if (matchedCartPickupTime) {
                        matchedPickupTime = matchedCartPickupTime;
                    }
                }

                /* set from previously selected */
                if (locationMode === OLO.Enums.APP_LOCATION_MODE.VENUE && !matchedPickupTime && currentPickup) {
                    const matchedCurrentPickupTime = availablePickups.find((pickup) => pickup.Id === currentPickup.Id);

                    if (matchedCurrentPickupTime) {
                        matchedPickupTime = matchedCurrentPickupTime;
                    }
                }

                /* set first available */
                if (!matchedPickupTime && availablePickups) {
                    matchedPickupTime = availablePickups[0];
                }

                if (matchedPickupTime) {
                    this.store.dispatch(actions.CurrentLocationPickupTimeSet({ ...matchedPickupTime }));
                    if (cart.locationNo === locationNo) {
                        this.store.dispatch(actions.CartSetPickupTime({ ...matchedPickupTime }));
                    }
                }
            });

    }
}
