import { Strings } from './strings.utils';
import { Numbers } from './numbers.utils';
import * as Statics from '@shared/core/statics';
import { TextsStatic } from '@shared/core/statics';
import * as moment from 'moment';
import { Moment } from 'moment';

export class Dates {
    /**
     * Depending on a chunk, returns either part before or after T from isoDate string.
     *
     * @example
     * isoDate = "2021-08-21T12:00:00.000"
     * chunk === 'date' ? "2021-08-21" : "12:00:00.000";
     *
     * @param isoDate 2021-08-21T12:00:00.000
     * @param chunk which part you want to extract
     * @returns {Nullable<string>} string
     */
    public static getChunk(isoDate: string, chunk: 'date' | 'time' = 'date'): Nullable<string> {
        if (!isoDate || typeof isoDate !== 'string') {
            return null;
        }

        const splitted = isoDate.split('T');
        if (splitted.length !== 2) {
            return null;
        }

        return chunk === 'date' ? splitted[0] : splitted[1];
    }

    /**
     * Creates new date (copy) with resetted seconds and miliseconds
     *
     * @param {Date} date date to reset
     * @returns {Date} resetted date
     */
    public static resetSeconds(date: Date): Date {
        return new Date(new Date(date.getTime()).setSeconds(0, 0));
    }

    public static getWeekNumber(date: Date | string | number): number {
        const d = Dates.createDate(date);
        const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        const pastDaysOfYear = ((d as any) - (firstDayOfYear as any)) / 86400000;

        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    /**
     * Checks if provided date is today
     * @param {string | Date} date
     * @returns {boolean}
     */
    public static isToday(date: Date | string): boolean {
        return Dates.datesDiffInDays(new Date(), date) === 0;
    }

    /**
     * Get difference between two dates in minutes units
     * @param {Date} checkDate
     * @param {Date} refDate
     * @returns {number}
     */
    public static datesDiffInMinutes(checkDate: Date, refDate: Date): number {
        return Math.floor((checkDate.getTime() - refDate.getTime()) / (60 * 1000));
    }

    /**
     * Get differemce in days between two dates
     * @param {string | Date} a
     * @param {string | Date} b
     * @returns {number} absolute
     */
    public static datesDiffInDays(a: string | Date, b: string | Date): number {
        const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
        const d1 = Dates.createDate(a);
        const d2 = Dates.createDate(b);

        const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
        const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

        return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY));
    }

    /**
     * Returns date in as nice description (based on provided designs)
     * @example Wednesday, September 12
     */
    public static descriptionDate(date: string | Date, staticTexts: T.StaticTexts): string {
        const daysStaticTexts: string[] = Object.values(staticTexts.days);
        const monthsStaticTexts: string[] = Object.values(staticTexts.months);
        const d: Date = typeof date === 'string' ? new Date(date) : date;
        const month: string = Strings.capitalizeFirstChar(monthsStaticTexts[d.getMonth()]);
        const day: string = Strings.capitalizeFirstChar(daysStaticTexts[d.getDay()]);

        return `${day}, ${month} ${d.getDate()}`;
    }

    /**
     * Substract years from now and return new date
     * @param {number} yearsCount - how many years to subtract
     * @returns {Date}
     */
    public static subtractYearsFromToday(yearsCount: number): Date {
        return new Date(new Date().setFullYear(new Date().getFullYear() - yearsCount));
    }

    /**
     * Gets numbers of days in a month of a year
     * @param {number} month - range 1-12
     * @param {number} year
     * @returns {number}
     */
    public static getDaysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }

    /**
     * Get hours from date in human readable format
     * @example 13:00 or 01:00pm
     * @param {Date} date
     * @param {boolean} meridemi - default `true`
     * @returns {string}
     */
    public static minsToMiliseconds(minutes: number): number {
        return minutes * 60 * 1000;
    }

    public static millisecsToMins(millis): number {
        return Math.floor(millis / 60000);
    }

    public static toMilliseconds(date: string | number | Date): number {
        const newDate: Date = date instanceof Date ? (date as Date) : new Date(date as any);

        if (newDate.toString() === 'Invalid Date') {
            return null;
        }

        return newDate.getTime();
    }

    public static hoursFromDate(date: Date, meridiem: boolean = true, showSuffix: boolean = true): string {
        let text = new TextsStatic().current;
        let hours: number = date.getHours();
        let minutes: number = date.getMinutes();
        let timeString: string = '';

        if (meridiem) {
            let hoursConverted = hours > 12 ? hours - 12 : hours;
            timeString += hoursConverted < 10 ? '0' + hoursConverted : `${hoursConverted}`;
        } else {
            timeString += hours < 10 ? '0' + hours : `${hours}`;
        }

        return (timeString += `:${minutes < 10 ? '0' + minutes : minutes}${meridiem && showSuffix ? (hours < 12 ? text.pickup.am : text.pickup.pm) : ''}`);
    }

    /**
     * Gets name of a day in current week
     * @param {OLO.Dates.DAY_OF_WEEK} dayNo week day number 0-6
     * @returns {string}
     */
    public static getDayName(dayNo: OLO.Dates.DAY_OF_WEEK): string {
        const staticTexts = new Statics.TextsStatic().current;
        const daysStaticTexts: string[] = Object.values(staticTexts.days);

        return daysStaticTexts[dayNo];
    }

    public static addDays(date: Nullable<Date | string | number> = null, daysNo: number): Date {
        const d = Dates.createDate(date);
        const nextDate = Dates.createDate(d.getTime() + 1000 * 3600 * 24 * daysNo);

        return nextDate;
    }

    /**
     * Create normalized date based on different parameters. Made to be as universal as possible for this system
     * @param {Date | string | null | number} date default `null`
     * @returns {Date}
     */
    public static createDate(date: Date | string | null | number = null): Date {
        let d: Date;
        if (!date) {
            d = new Date();
        }

        if (typeof date === 'number') {
            d = new Date(date);
        }

        if (typeof date === 'string') {
            const a: number[] = (date as string).split(/[T\-:.]/gi).map((obj: string, index) => {
                obj = obj.replace('Z', '');
                if (index === 1) {
                    return +obj - 1;
                } else {
                    return +obj;
                }
            });

            if (a.length > 7) {
                a.length = 7;
            } else if (a.length < 7) {
                while (a.length < 7) {
                    a.push(0);
                }
            }

            d = new Date(a[0], a[1], a[2], a[3], a[4], a[5], a[6]);
        }

        if (date instanceof Date === true) {
            d = date as Date;
        }

        return d;
    }

    /**
     * Gets local date as iso format omitting timezone difference.
     * > This was made to handle different api endpoints that requested data in iso format but expected local time
     *
     * > TODO refactor - can be done in two-there lines
     * @param {Date} date default `new Date()`
     * @param {boolean} includeZ some endpoints require to add Z date string
     * @returns {string}
     */
    public static getLocalISOFormatDate(date: Date = new Date(), includeZ: boolean = false): string {
        if (date instanceof Date === false) return '';

        const year: number = date.getFullYear();
        const month: string = Numbers.leadingZero(date.getMonth() + 1);
        const day: string = Numbers.leadingZero(date.getDate());
        const hours: string = Numbers.leadingZero(date.getHours());
        const minutes: string = Numbers.leadingZero(date.getMinutes());
        const seconds: string = Numbers.leadingZero(date.getSeconds());
        let miliseconds: number | string = date.getMilliseconds();

        switch (true) {
            case miliseconds < 100 && miliseconds > 10:
                miliseconds = `0${miliseconds}`;
                break;
            case miliseconds < 10:
                miliseconds = `00${miliseconds}`;
                break;
            default:
                miliseconds = `${miliseconds}`;
        }

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${miliseconds}${includeZ ? 'Z' : ''}`;
    }

    /**
     * Converts hours mins and seconds to number - useful when comparing hours difference
     * @example '2020-08-21T12:30:59.123Z' -> 123059
     * @param {Date | string | number} date
     * @returns {number}
     */
    public static createHoursIntFromDate(date: Date | string | number): number {
        if (!date) {
            return null;
        }
        let stringDate: string = date as string;

        if (date instanceof Date) {
            stringDate = Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'number') {
            stringDate = Dates.getLocalISOFormatDate(new Date(date));
        }

        //     return Number(date.match(/(\d{2}:){2}\d{2}/g)[0].replace(/:/g, ''));
        // }

        // return Number(stringDate.match(/(\d{2}:){2}\d{2}/g)[0].replace(/:/g, ''));
        const long = stringDate.match(/(\d{2}:){2}\d{2}/g);
        const short = stringDate.match(/\d{2}:\d{2}/g);
        // const finalString = short ? `${short[0]}:00` : long[0];
        const finalString = long ? long[0] : `${short[0]}:00`;

        return Number(finalString.replace(/:/g, ''));
    }

    /**
     * Check if provided hour is in range of two other dates.
     * @param {Date | string | number} toCheck hours to check
     * @param {Date | string | number} from
     * @param {Date | string | number} to
     * @param {null | 'from' | 'to' | 'both'} equal edge case - default `both`.
     * @returns {boolean}
     */
    public static isHourInHoursRange(
        toCheck: Date | string | number,
        from: Date | string | number,
        to: Date | string | number,
        equal: null | 'from' | 'to' | 'both' = 'both',
    ): boolean {
        if (!toCheck || !from || !to) return false;

        const n: number = Dates.createHoursIntFromDate(toCheck);
        const f: number = Dates.createHoursIntFromDate(from);
        const t: number = Dates.createHoursIntFromDate(to);
        if (!equal) return n > f && n < t;

        if (equal === 'from') return n >= f && n < t;

        if (equal === 'to') return n > f && n <= t;

        return n >= f && n <= t;
    }

    /**
     * Returns rounded edge hout
     * @example `11:59:00` -> `12:00:00`
     * `23:59:00` -> `00:00:00`
     * @returns {string}
     */
    public static getClosedTimeFromHourString(hours: string): string {
        const strArr: string[] = hours.split(/:/g);
        if (strArr.length !== 3 || hours.length !== 8) return null;

        if (strArr[1] === '59') {
            strArr[1] = '00';

            strArr[0] = `${+strArr[0] + 1}`;
        }

        if (strArr[0] === '24') {
            strArr[0] = '00';
        }

        return strArr.join(':');
    }

    /**
     * These two methods can be replaced with isHourInHoursRange after testing pickup shared service
     */
    public static stringHourToNumber(hour: string): number {
        if (typeof hour !== 'string') return hour;

        return Number(hour.replace(/:/g, ''));
    }

    /**
     * Obsolte
     */
    public static isHourInTimeRange(pickupHour: string, startTime: string, endTime: string) {
        console.warn('OBSOLTE, replace with isHourInHoursRange method');
        if (!pickupHour) return false;

        const startTimeNo: number = Dates.stringHourToNumber(startTime || pickupHour);
        const endTimeNo: number = Dates.stringHourToNumber(endTime || pickupHour);
        const pickupHourNo: number = Dates.stringHourToNumber(pickupHour);

        return pickupHourNo <= endTimeNo && pickupHourNo >= startTimeNo;
    }

    public static roundDateToMinutes(date: Date, method: 'round' | 'ceil' = 'round'): Date {
        return new Date(Math[method](date.getTime() / 60000) * 60000);
    }

    public static createFormatDate(date: string, format: string): string {
        const formatedDate = moment(Dates.createDate(date)).format(format);
        if (moment(formatedDate, format, true).isValid()) return formatedDate;

        return date;
    }

    public static getISOFormatDate(date: Date | Moment): string {
        if (date instanceof Date === false && !moment.isMoment(date)) return '';

        return date.toISOString();
    }

    public static isSameDay(day1: string, day2: string) {
        return day1?.split('T')[0] === day2?.split('T')[0];
    }
}
