import { createSelector } from '@ngrx/store';
import { latestTransactionsState } from '../root';

export const getTransactionById = (transactionId: number) =>
    createSelector(
        latestTransactionsState,
        (latestTransactions) =>
            latestTransactions.reduce<OLO.DTO.LoyaltyAppTransactionModel>((acc, obj) => {
                if (acc || !obj.data) return acc;

                return obj.data.find((transaction) => transaction.TransactionId === transactionId) || acc;
            }, null),
    );
