import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    AlertMessageOpen,
    AlertMessageEdit,
    AlertMessageClose,
    AlertMessageSetBulk
} from '@shared/state/alertMessages';

@Injectable({
    providedIn: 'root'
})
export class AlertMessagesService {
    private _defaults: OLO.State.AlertMessages.AlertMessage = {
        type: 'error',
        animation: OLO.State.AlertMessages.ALERT_ANIMATION.IN,
        title: 'Error',
    };
    private _animationTimeout: number = 500;

    constructor(
        public store: Store<OLO.State.AlertMessages>,
    ) {
    }

    public show(options: OLO.State.AlertMessages.AlertMessage = this._defaults): Promise<boolean> {
        const id: number = options.id || new Date().getTime() + Math.floor(Math.random() * 100000);

        return new Promise(resolve => {
            this.store.dispatch(AlertMessageOpen({
                message: {
                    ...options,
                    id,
                }
            }));
            setTimeout(() => {
                this.store.dispatch(AlertMessageEdit(id, { animation: null }));
                resolve(true);
            }, this._animationTimeout);
        });
    }

    public close(id: number, animate: boolean = true): Promise<boolean> {
        return new Promise(resolve => {
            if (animate) {
                this.store.dispatch(AlertMessageEdit(id, { animation: OLO.State.AlertMessages.ALERT_ANIMATION.OUT }));
                setTimeout(() => {
                    this.store.dispatch(AlertMessageClose({ id }));
                    resolve(true);
                }, this._animationTimeout);

                return;
            }

            this.store.dispatch(AlertMessageClose({ id }));
            resolve(true);
        });
    }

    public edit(id: number, options: OLO.State.AlertMessages.AlertMessage = {}): void {
        this.store.dispatch(AlertMessageEdit(id, options));
    }

    public setBulk(messages: OLO.State.AlertMessages.AlertMessage[], commonOptions: OLO.State.AlertMessages.AlertMessage = {}): void {
        this.store.dispatch(AlertMessageSetBulk(
            messages.map(message => ({
                ...message,
                ...commonOptions,
            }))
        ));
    }
}
