import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartOnlineMenuItemById = (id: number, virtualLocationNo: number = null) => createSelector(
    cartState,
    cart => {
        if (!cart.onlineMenu || (virtualLocationNo && !cart.onlineMenuVirtualLocations)) return null;
        const relavantOnlineMenu = virtualLocationNo ? cart.onlineMenuVirtualLocations.find(virtualLocationOnlineMenu =>
            virtualLocationOnlineMenu._VirtualLocationNo === virtualLocationNo) : cart.onlineMenu;

        let product: OLO.DTO.OnlineMenuProductResponseModel = null;

        for (let i = 0, j = relavantOnlineMenu.Pages.length; i < j; i++) {
            if (product) break;

            const page = relavantOnlineMenu.Pages[i];

            for (let k = 0, m = page.Products.length; k < m; k++) {
                if (page.Products[k].ProductId === id || page.Products[k].MenuFlowId === id) {
                    product = page.Products[k];
                    break;
                }
            }
        }

        return product;
    }
);
