export class FavoriteOrdersMapper {
    public static mapCreateCombosPostRequest(createCombos: OLO.DTO.MemberFavouriteCombosModel): APIv3.MemberFavouriteCombosModel {
        return createCombos ? {
            Id: createCombos.Id,
            MemberId: createCombos.MemberId,
            MenuFlowId: createCombos.MenuFlowId,
            Value: createCombos.Value,
            MemberFavouriteComboItems: createCombos.MemberFavouriteComboItems ?
                createCombos.MemberFavouriteComboItems.map((item: OLO.DTO.MemberFavouriteComboItemsModel) => ({
                    Id: item.Id,
                    MemberFavouriteCombosId: item.MemberFavouriteCombosId,
                    Plu: item.Plu,
                    Qty: item.Qty,
                    Value: item.Value,
                    MenuFlowPageId: item.MenuFlowPageId,
                    State: item.State,
                    MemberFavouriteComboItemAdditions: item.MemberFavouriteComboItemAdditions ?
                        item.MemberFavouriteComboItemAdditions.map((favouriteItem: OLO.DTO.MemberFavouriteItemAdditionModel) => ({
                            Id: favouriteItem.Id,
                            ParentId: favouriteItem.ParentId,
                            IngredientPlu: favouriteItem.IngredientPlu,
                            ModifierId: favouriteItem.ModifierId,
                            ExtraPrice: favouriteItem.ExtraPrice,
                        })) : null,
                    MemberFavouriteComboItemModifications: item.MemberFavouriteComboItemModifications ?
                        item.MemberFavouriteComboItemAdditions.map((favouriteItem: OLO.DTO.MemberFavouriteItemModificationModel) => ({
                            Id: favouriteItem.Id,
                            ParentId: favouriteItem.ParentId,
                            IngredientPlu: favouriteItem.IngredientPlu,
                            ModifierId: favouriteItem.ModifierId,
                            ExtraPrice: favouriteItem.ExtraPrice,
                        })) : null,
                    MemberFavouriteComboItemRemovals: item.MemberFavouriteComboItemRemovals ?
                        item.MemberFavouriteComboItemAdditions.map((favouriteItem: OLO.DTO.MemberFavouriteItemRemovalModel) => ({
                            Id: favouriteItem.Id,
                            ParentId: favouriteItem.ParentId,
                            IngredientPlu: favouriteItem.IngredientPlu,
                        })) : null,
                    MemberFavouriteComboItemSwaps: item.MemberFavouriteComboItemSwaps ?
                        item.MemberFavouriteComboItemAdditions.map((favouriteItem: OLO.DTO.MemberFavouriteItemSwapModel) => ({
                            Id: favouriteItem.Id,
                            ParentId: favouriteItem.ParentId,
                            OldPlu: favouriteItem.OldPlu,
                            NewPlu: favouriteItem.NewPlu,
                            ModifierId: favouriteItem.ModifierId,
                            ExtraPrice: favouriteItem.ExtraPrice,
                        })) : null,
                })) : null,
            Name: createCombos.Name,
            State: createCombos.State,
        } : null;

    }

    public static mapCreateCombosPostResponse(createCombos: APIv3.FavouriteOrdersGenerateFavouriteCombos.Responses.$200): OLO.DTO.MemberFavouriteCombosModel[] {
        return createCombos ? createCombos.map((favouriteCombos: APIv3.MemberFavouriteCombosModel) => ({
            Id: favouriteCombos.Id,
            MemberId: favouriteCombos.MemberId,
            MenuFlowId: favouriteCombos.MenuFlowId,
            Value: favouriteCombos.Value,
            MemberFavouriteComboItems: favouriteCombos.MemberFavouriteComboItems ?
                favouriteCombos.MemberFavouriteComboItems.map((favouriteItem: APIv3.MemberFavouriteComboItemsModel) => ({
                    Id: favouriteItem.Id,
                    MemberFavouriteCombosId: favouriteItem.MemberFavouriteCombosId,
                    Plu: favouriteItem.Plu,
                    Qty: favouriteItem.Qty,
                    Value: favouriteItem.Value,
                    MenuFlowPageId: favouriteItem.MenuFlowPageId,
                    State: favouriteItem.State,
                    MemberFavouriteComboItemAdditions: favouriteItem.MemberFavouriteComboItemAdditions ?
                        favouriteItem.MemberFavouriteComboItemAdditions.map((item: APIv3.MemberFavouriteItemAdditionModel) => ({
                            Id: item.Id,
                            ParentId: item.ParentId,
                            IngredientPlu: item.IngredientPlu,
                            ModifierId: item.ModifierId,
                            ExtraPrice: item.ExtraPrice,
                        })) : null,
                    MemberFavouriteComboItemModifications: favouriteItem.MemberFavouriteComboItemModifications ?
                        favouriteItem.MemberFavouriteComboItemModifications.map((item: APIv3.MemberFavouriteItemModificationModel) => ({
                            Id: item.Id,
                            ParentId: item.ParentId,
                            IngredientPlu: item.IngredientPlu,
                            ModifierId: item.ModifierId,
                            ExtraPrice: item.ExtraPrice,
                        })) : null,
                    MemberFavouriteComboItemRemovals: favouriteItem.MemberFavouriteComboItemRemovals ?
                        favouriteItem.MemberFavouriteComboItemRemovals.map((item: APIv3.MemberFavouriteItemRemovalModel) => ({
                            Id: item.Id,
                            ParentId: item.ParentId,
                            IngredientPlu: item.IngredientPlu,
                        })) : null,
                    MemberFavouriteComboItemSwaps: favouriteItem.MemberFavouriteComboItemSwaps ?
                        favouriteItem.MemberFavouriteComboItemSwaps.map((item: APIv3.MemberFavouriteItemSwapModel) => ({
                            Id: item.Id,
                            ParentId: item.ParentId,
                            OldPlu: item.OldPlu,
                            NewPlu: item.NewPlu,
                            ModifierId: item.ModifierId,
                            ExtraPrice: item.ExtraPrice,
                        })) : null,
                })) : null,
            Name: favouriteCombos.Name,
            State: favouriteCombos.State,
        })) : null;
    }

    public static mapGetOrders(getOrders: APIv3.MemberFavouriteOrdersModel[]): OLO.DTO.MemberFavouriteOrdersModel[] {
        return getOrders ? getOrders?.map((order: APIv3.MemberFavouriteOrdersModel) => ({
            Id: order.Id,
            MemberId: order.MemberId,
            OnlineOrderId: order.OnlineOrderId,
            LastOrderedDate: order.LastOrderedDate,
            MemberFavouriteOrderCombos: order.MemberFavouriteOrderCombos ?
                order.MemberFavouriteOrderCombos.map((item: APIv3.MemberFavouriteOrderCombosModel) => ({
                    Id: item.Id,
                    MenuFlowId: item.MenuFlowId,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderComboItems: item.MemberFavouriteOrderComboItems ?
                        item.MemberFavouriteOrderComboItems.map((memberFavourite: APIv3.MemberFavouriteOrderComboItemModel) => ({
                            Id: memberFavourite.Id,
                            MemberFavouriteOrderCombosId: memberFavourite.MemberFavouriteOrderCombosId,
                            MenuFlowPageId: memberFavourite.MenuFlowPageId,
                            Plu: memberFavourite.Plu,
                            Qty: memberFavourite.Qty,
                            Value: memberFavourite.Value,
                            State: memberFavourite.State,
                            MemberFavouriteOrderItemAdditions: memberFavourite.MemberFavouriteOrderItemAdditions ?
                                memberFavourite.MemberFavouriteOrderItemAdditions.map((memberFavouriteItem: APIv3.MemberFavouriteItemAdditionModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemModifications: memberFavourite.MemberFavouriteOrderItemModifications ?
                                memberFavourite.MemberFavouriteOrderItemModifications.map((memberFavouriteItem: APIv3.MemberFavouriteItemModificationModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemRemovals: memberFavourite.MemberFavouriteOrderItemRemovals ?
                                memberFavourite.MemberFavouriteOrderItemRemovals.map((memberFavouriteItem: APIv3.MemberFavouriteItemRemovalModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                })) : null,
                            MemberFavouriteOrderItemSwaps: memberFavourite.MemberFavouriteOrderItemSwaps ?
                                memberFavourite.MemberFavouriteOrderItemSwaps.map((memberFavouriteItem: APIv3.MemberFavouriteItemSwapModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    OldPlu: memberFavouriteItem.OldPlu,
                                    NewPlu: memberFavouriteItem.NewPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                        })) : null,
                    State: item.State,
                })) : null,
            MemberFavouriteOrderItems: order.MemberFavouriteOrderItems ?
                order.MemberFavouriteOrderItems.map((item: APIv3.MemberFavouriteOrderItemsModel) => ({
                    Id: item.Id,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Plu: item.Plu,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderItemAdditions: item.MemberFavouriteOrderItemAdditions ?
                        item.MemberFavouriteOrderItemAdditions.map((memberFavourite: APIv3.MemberFavouriteItemAdditionModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemModifications: item.MemberFavouriteOrderItemModifications ?
                        item.MemberFavouriteOrderItemModifications.map((memberFavourite: APIv3.MemberFavouriteItemModificationModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemRemovals: item.MemberFavouriteOrderItemRemovals ?
                        item.MemberFavouriteOrderItemRemovals.map((memberFavourite: APIv3.MemberFavouriteItemRemovalModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                        })) : null,
                    MemberFavouriteOrderItemSwaps: item.MemberFavouriteOrderItemSwaps ?
                        item.MemberFavouriteOrderItemSwaps.map((memberFavourite: APIv3.MemberFavouriteItemSwapModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            OldPlu: memberFavourite.OldPlu,
                            NewPlu: memberFavourite.NewPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    State: item.State,
                })) : null,
        })) : null;
    }

    public static mapRemoveResponse(order: APIv3.MemberFavouriteOrdersModel): OLO.DTO.MemberFavouriteOrdersModel {
        return order ? {
            Id: order.Id,
            MemberId: order.MemberId,
            OnlineOrderId: order.OnlineOrderId,
            LastOrderedDate: order.LastOrderedDate,
            MemberFavouriteOrderCombos: order.MemberFavouriteOrderCombos ?
                order.MemberFavouriteOrderCombos.map((item: APIv3.MemberFavouriteOrderCombosModel) => ({
                    Id: item.Id,
                    MenuFlowId: item.MenuFlowId,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderComboItems: item.MemberFavouriteOrderComboItems ?
                        item.MemberFavouriteOrderComboItems.map((memberFavourite: APIv3.MemberFavouriteOrderComboItemModel) => ({
                            Id: memberFavourite.Id,
                            MemberFavouriteOrderCombosId: memberFavourite.MemberFavouriteOrderCombosId,
                            MenuFlowPageId: memberFavourite.MenuFlowPageId,
                            Plu: memberFavourite.Plu,
                            Qty: memberFavourite.Qty,
                            Value: memberFavourite.Value,
                            State: memberFavourite.State,
                            MemberFavouriteOrderItemAdditions: memberFavourite.MemberFavouriteOrderItemAdditions ?
                                memberFavourite.MemberFavouriteOrderItemAdditions.map((memberFavouriteItem: APIv3.MemberFavouriteItemAdditionModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemModifications: memberFavourite.MemberFavouriteOrderItemModifications ?
                                memberFavourite.MemberFavouriteOrderItemModifications.map((memberFavouriteItem: APIv3.MemberFavouriteItemModificationModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemRemovals: memberFavourite.MemberFavouriteOrderItemRemovals ?
                                memberFavourite.MemberFavouriteOrderItemRemovals.map((memberFavouriteItem: APIv3.MemberFavouriteItemRemovalModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                })) : null,
                            MemberFavouriteOrderItemSwaps: memberFavourite.MemberFavouriteOrderItemSwaps ?
                                memberFavourite.MemberFavouriteOrderItemSwaps.map((memberFavouriteItem: APIv3.MemberFavouriteItemSwapModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    OldPlu: memberFavouriteItem.OldPlu,
                                    NewPlu: memberFavouriteItem.NewPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                        })) : null,
                    State: item.State,
                })) : null,
            MemberFavouriteOrderItems: order.MemberFavouriteOrderItems ?
                order.MemberFavouriteOrderItems.map((item: APIv3.MemberFavouriteOrderItemsModel) => ({
                    Id: item.Id,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Plu: item.Plu,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderItemAdditions: item.MemberFavouriteOrderItemAdditions ?
                        item.MemberFavouriteOrderItemAdditions.map((memberFavourite: APIv3.MemberFavouriteItemAdditionModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemModifications: item.MemberFavouriteOrderItemModifications ?
                        item.MemberFavouriteOrderItemModifications.map((memberFavourite: APIv3.MemberFavouriteItemModificationModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemRemovals: item.MemberFavouriteOrderItemRemovals ?
                        item.MemberFavouriteOrderItemRemovals.map((memberFavourite: APIv3.MemberFavouriteItemRemovalModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                        })) : null,
                    MemberFavouriteOrderItemSwaps: item.MemberFavouriteOrderItemSwaps ?
                        item.MemberFavouriteOrderItemSwaps.map((memberFavourite: APIv3.MemberFavouriteItemSwapModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            OldPlu: memberFavourite.OldPlu,
                            NewPlu: memberFavourite.NewPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    State: item.State,
                })) : null,
        } : null;
    }

    public static mapAddRequest(order: OLO.DTO.OnlineOrderDetailedBusinessModel, MemberId: number): APIv3.MemberFavouriteOrdersModel {
        const orderId: number = order.hasOwnProperty('OnlineOrderId') ? (order as any).OnlineOrderId : order.Id;

        return {
            Id: null,
            MemberId,
            OnlineOrderId: orderId,
            MemberFavouriteOrderCombos: order.MenuFlowActivations.map(MenuFlow => ({
                Id: MenuFlow.Id,
                MenuFlowId: MenuFlow.MenuFlowId,
                MemberFavouriteOrdersId: MenuFlow.OnlineOrderId, // ???????????
                Qty: MenuFlow.Quantity,
                Value: MenuFlow.Value,
                MemberFavouriteOrderComboItems: MenuFlow.MenuFlowItems.map(Item => ({
                    Id: Item.Id,
                    MemberFavouriteOrderCombosId: Item.OnlineOrderMenuFlowActivationId,
                    MenuFlowPageId: Item.MenuFlowPageId,
                    Plu: Item.PLU,
                    Qty: Item.Quantity,
                    Value: Item.Value,
                    MemberFavouriteOrderItemAdditions: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsAdded ?
                        null :
                        Item.IngredientsChanges.IngredientsAdded.map(IAdded => ({
                            Id: IAdded.ID,
                            ParentId: IAdded.OrderItemId,
                            IngredientPlu: IAdded.IngredientPLU,
                            ModifierId: IAdded.ModifierID,
                            ExtraPrice: IAdded.ExtraPrice,
                        })),
                    MemberFavouriteOrderItemModifications: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsModified ?
                        null :
                        Item.IngredientsChanges.IngredientsModified.map(IMod => ({
                            Id: IMod.ID,
                            ParentId: IMod.OrderItemId,
                            IngredientPlu: IMod.IngredientPLU,
                            ModifierId: IMod.ModifierID,
                            ExtraPrice: IMod.ExtraPrice
                        })),
                    MemberFavouriteOrderItemRemovals: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsRemoved ?
                        null :
                        Item.IngredientsChanges.IngredientsRemoved.map(IRemoved => ({
                            Id: IRemoved.ID,
                            ParentId: IRemoved.OrderItemId,
                            IngredientPlu: IRemoved.IngredientPLU,
                        })),
                    MemberFavouriteOrderItemSwaps: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsSwapped ?
                        null :
                        Item.IngredientsChanges.IngredientsSwapped.map(ISwap => ({
                            Id: ISwap.ID,
                            ParentId: ISwap.OrderItemId,
                            OldPlu: ISwap.OldIngredientPLU,
                            NewPlu: ISwap.NewIngredientPLU,
                            ModifierId: ISwap.ModifierID,
                            ExtraPrice: ISwap.ExtraPrice,
                        }))
                }
                ))
            })),
            MemberFavouriteOrderItems: order.Items.map(Item => ({
                Id: Item.Id,
                MemberFavouriteOrdersId: Item.OrderId,
                Plu: Item.PLU,
                Qty: Item.Quantity,
                Value: Item.Value,
                MemberFavouriteOrderItemAdditions: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsAdded ?
                    null :
                    Item.IngredientsChanges.IngredientsAdded.map(IAdded => ({
                        Id: IAdded.ID,
                        ParentId: IAdded.OrderItemId,
                        IngredientPlu: IAdded.IngredientPLU,
                        ModifierId: IAdded.ModifierID,
                        ExtraPrice: IAdded.ExtraPrice
                    })),
                MemberFavouriteOrderItemModifications: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsModified ?
                    null :
                    Item.IngredientsChanges.IngredientsModified.map(IMod => ({
                        Id: IMod.ID,
                        ParentId: IMod.OrderItemId,
                        IngredientPlu: IMod.IngredientPLU,
                        ModifierId: IMod.ModifierID,
                        ExtraPrice: IMod.ExtraPrice
                    })),
                MemberFavouriteOrderItemRemovals: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsRemoved ?
                    null :
                    Item.IngredientsChanges.IngredientsRemoved.map(IRemoved => ({
                        Id: IRemoved.ID,
                        ParentId: IRemoved.OrderItemId,
                        IngredientPlu: IRemoved.IngredientPLU,
                    })),
                MemberFavouriteOrderItemSwaps: !Item.IngredientsChanges || !Item.IngredientsChanges.IngredientsSwapped ?
                    null :
                    Item.IngredientsChanges.IngredientsSwapped.map(ISwap => ({
                        Id: ISwap.ID,
                        ParentId: ISwap.OrderItemId,
                        OldPlu: ISwap.OldIngredientPLU,
                        NewPlu: ISwap.NewIngredientPLU,
                        ModifierId: ISwap.ModifierID,
                        ExtraPrice: ISwap.ExtraPrice
                    }))
            })),
        };
    }

    public static mapAddResponse(order: APIv3.MemberFavouriteOrdersModel): OLO.DTO.MemberFavouriteOrdersModel {
        return order ? {
            Id: order.Id,
            MemberId: order.MemberId,
            OnlineOrderId: order.OnlineOrderId,
            LastOrderedDate: order.LastOrderedDate,
            MemberFavouriteOrderCombos: order.MemberFavouriteOrderCombos ?
                order.MemberFavouriteOrderCombos.map((item: APIv3.MemberFavouriteOrderCombosModel) => ({
                    Id: item.Id,
                    MenuFlowId: item.MenuFlowId,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderComboItems: item.MemberFavouriteOrderComboItems ?
                        item.MemberFavouriteOrderComboItems.map((memberFavourite: APIv3.MemberFavouriteOrderComboItemModel) => ({
                            Id: memberFavourite.Id,
                            MemberFavouriteOrderCombosId: memberFavourite.MemberFavouriteOrderCombosId,
                            MenuFlowPageId: memberFavourite.MenuFlowPageId,
                            Plu: memberFavourite.Plu,
                            Qty: memberFavourite.Qty,
                            Value: memberFavourite.Value,
                            State: memberFavourite.State,
                            MemberFavouriteOrderItemAdditions: memberFavourite.MemberFavouriteOrderItemAdditions ?
                                memberFavourite.MemberFavouriteOrderItemAdditions.map((memberFavouriteItem: APIv3.MemberFavouriteItemAdditionModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemModifications: memberFavourite.MemberFavouriteOrderItemModifications ?
                                memberFavourite.MemberFavouriteOrderItemModifications.map((memberFavouriteItem: APIv3.MemberFavouriteItemModificationModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                            MemberFavouriteOrderItemRemovals: memberFavourite.MemberFavouriteOrderItemRemovals ?
                                memberFavourite.MemberFavouriteOrderItemRemovals.map((memberFavouriteItem: APIv3.MemberFavouriteItemRemovalModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    IngredientPlu: memberFavouriteItem.IngredientPlu,
                                })) : null,
                            MemberFavouriteOrderItemSwaps: memberFavourite.MemberFavouriteOrderItemSwaps ?
                                memberFavourite.MemberFavouriteOrderItemSwaps.map((memberFavouriteItem: APIv3.MemberFavouriteItemSwapModel) => ({
                                    Id: memberFavouriteItem.Id,
                                    ParentId: memberFavouriteItem.ParentId,
                                    OldPlu: memberFavouriteItem.OldPlu,
                                    NewPlu: memberFavouriteItem.NewPlu,
                                    ModifierId: memberFavouriteItem.ModifierId,
                                    ExtraPrice: memberFavouriteItem.ExtraPrice,
                                })) : null,
                        })) : null,
                    State: item.State,
                })) : null,
            MemberFavouriteOrderItems: order.MemberFavouriteOrderItems ?
                order.MemberFavouriteOrderItems.map((item: APIv3.MemberFavouriteOrderItemsModel) => ({
                    Id: item.Id,
                    MemberFavouriteOrdersId: item.MemberFavouriteOrdersId,
                    Plu: item.Plu,
                    Qty: item.Qty,
                    Value: item.Value,
                    MemberFavouriteOrderItemAdditions: item.MemberFavouriteOrderItemAdditions ?
                        item.MemberFavouriteOrderItemAdditions.map((memberFavourite: APIv3.MemberFavouriteItemAdditionModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemModifications: item.MemberFavouriteOrderItemModifications ?
                        item.MemberFavouriteOrderItemModifications.map((memberFavourite: APIv3.MemberFavouriteItemModificationModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    MemberFavouriteOrderItemRemovals: item.MemberFavouriteOrderItemRemovals ?
                        item.MemberFavouriteOrderItemRemovals.map((memberFavourite: APIv3.MemberFavouriteItemRemovalModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            IngredientPlu: memberFavourite.IngredientPlu,
                        })) : null,
                    MemberFavouriteOrderItemSwaps: item.MemberFavouriteOrderItemSwaps ?
                        item.MemberFavouriteOrderItemSwaps.map((memberFavourite: APIv3.MemberFavouriteItemSwapModel) => ({
                            Id: memberFavourite.Id,
                            ParentId: memberFavourite.ParentId,
                            OldPlu: memberFavourite.OldPlu,
                            NewPlu: memberFavourite.NewPlu,
                            ModifierId: memberFavourite.ModifierId,
                            ExtraPrice: memberFavourite.ExtraPrice,
                        })) : null,
                    State: item.State,
                })) : null,
        } : null;
    }
}
