export interface OrderPaymentDetails {
    paymentProvider: OLO.Enums.PAYMENT_PROVIDER;
    paymentMethod: OLO.Ordering.PaymentMethod;
    onlineOrder: OLO.DTO.OnlineOrderDetailedBusinessModel;
    cardState: OLO.State.CreditCards;
    member: OLO.DTO.MemberModel | APICommon.OnlineOrderPartialMember;
    currency: string;
    locationNo: number;
}

/**
 * Generates payment details based on logic provided in `generatePaymentDetails` method
 */
export abstract class OrderPaymentMethod {
    protected _next: OrderPaymentMethod = null;
    protected _executePaymentModel: OLO.DTO.ExecutePaymentModel = {
        SaveBillingAddress: true,
        BillingAddress: null,
        SetOrderAsValidatedOnSuccess: true,
        Amount: null,
    } as OLO.DTO.ExecutePaymentModel;

    /**
     * Sets required properties to final `_executePaymentModel`
     * @param {OrderPaymentDetails} details order and payment details object
     * @returns {void} void
     */
    protected _extendExecutePaymentModel(details: OrderPaymentDetails): void {
        const { onlineOrder, cardState, paymentProvider } = details;

        this._executePaymentModel.Amount = onlineOrder.TotalLeftToPay;
        this._executePaymentModel.PaymentAccountId = cardState.activeCardId;
        this._executePaymentModel.Token = cardState.activeCardToken;
        this._executePaymentModel.PaymentProvider = paymentProvider;

        /**
         * Update billing details for SAVED card that was missing this data.
         */
        const card = cardState.data.find(obj => obj.Id);
        const shouldUpdateBillingDetails = cardState.activeCardId && card?.SaveAwait === true && card.BillingDetails;
        if(shouldUpdateBillingDetails) {
            this._executePaymentModel.BillingAddress = {
                ...card.BillingDetails
            };
            this._executePaymentModel.SaveBillingAddress = true;
        }
    }

    /**
     * Extracts details about current active card from `cardState`
     * @param {OLO.State.CreditCards} cardState
     * @returns {OLO.Members.MemberCreditCardDetails} credit card
     */
    protected _getCardDetails(cardState: OLO.State.CreditCards): OLO.Members.MemberCreditCardDetails {
        return cardState.data?.find((obj) => (cardState.activeCardToken ? obj.Token === cardState.activeCardToken : cardState.activeCardId === obj.Id));
    }

    /**
     * Based on paymentMethod details, check if payment is set to vendor - google or apple
     * @param {OLO.Ordering.PaymentMethod} paymentMethod
     * @returns {boolean} boolean
     */
    protected _isVendorPayment(paymentMethod: OLO.Ordering.PaymentMethod): boolean {
        return (
            (paymentMethod?.vendorService === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY && Boolean(paymentMethod?.googlePaymentData?.PaymentData)) ||
            (paymentMethod?.vendorService === OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY && Boolean(paymentMethod?.applePaymentData?.PaymentData))
        );
    }

    /**
     * Sets next order payment details handler if the current does not match
     * @param {OrderPaymentMethod} next order payment details handler
     * @returns {void} void
     */
    public setNext(next: OrderPaymentMethod): void {
        this._next = next;
    }

    /**
     * Calculates order payment details object for payment
     * @param {OrderPaymentDetails} details order and payment details object
     * @returns {ExecutePaymentModels} payment details object used for payment process
     */
    public async generatePaymentDetails(details: OrderPaymentDetails): Promise<OLO.DTO.ExecutePaymentModel> {
        throw new Error('Method needs to be overwritten');
    }
}
