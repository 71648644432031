import { createSelector } from '@ngrx/store';
import { getOnlineMenuPagesFiltered } from '../getOnlineMenuPagesFiltered';
import { getOnlineMenuVirtualLocationsFiltered } from '@shared/state/commonSelectors/onlineMenuVirtualLocations';
import { getOnlineMenuFiltersLocationNo } from '@shared/state/onlineMenuFilters/selectors';

export const getOnlineMenuPagesFilteredForSelectedLocationNo = createSelector(
    getOnlineMenuPagesFiltered,
    getOnlineMenuVirtualLocationsFiltered,
    getOnlineMenuFiltersLocationNo,
    (onlineMenuPages, onlineMenuVirtualLocations, locationNoFilter) => {
        if (locationNoFilter) {
            const onlineMenuVirtualLocationObj = onlineMenuVirtualLocations?.find((onlineMenuVirtualLocation) => onlineMenuVirtualLocation.virtualLocationNo === locationNoFilter);

            return onlineMenuVirtualLocationObj?.data?.Pages ?? onlineMenuPages;
        }

        return onlineMenuPages;
    },
);
