import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './virtual-locations.actions';

const initialState: OLO.State.VirtualLocations = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    data: [],
};

export const virtualLocationsReducerFn = createReducer(
    initialState,
    on(actions.VirtualLocationsRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.VirtualLocationsSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        data: action.payload,
    })),
    on(actions.VirtualLocationsErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function virtualLocationsReducer(state: OLO.State.VirtualLocations | undefined, action: Action) {
    return virtualLocationsReducerFn(state, action);
}
