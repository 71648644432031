import { createSelector } from '@ngrx/store';
import { getLocationImage } from '@shared/state/locationsImages/selectors/getLocationImage';
import { SubNavBuilder } from '@shared/core/models';

export const getCurrentLocationSubNavPage = (currentLocation: OLO.DTO.OnlineOrderingLocationBusinessModel, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForLogo) =>
    createSelector(getLocationImage(imageType, currentLocation.LocationNo), (image) => {
        const { LocationNo, LocationDescription } = currentLocation;

        const model = new SubNavBuilder(currentLocation)
            .setId(LocationNo)
            .setName(LocationDescription)
            .setImgUrl(image?.data?.ImageUrl)
            .build();

        return model;
    });
