import { createSelector } from '@ngrx/store';
import { creditCardsState } from '../root';

export const isLoadingCards = createSelector(
    creditCardsState,
    (creditCards) =>
        creditCards.add.isAdding ||
        creditCards.token.isGettingToken ||
        creditCards.remove.isRemoving ||
        creditCards.download.isDownloading ||
        creditCards.validation.isValidating ||
        creditCards.activeCardRedirectUrl !== null ||
        creditCards.cardConnect.locationConfig.isDownloading,
);
