import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class DietaryTagsImagesEffects {
    @Effect() public onOnlineMenuSuccessRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineMenuPagesSuccessRequest, actions.LoadDietaryImagesForLocation),
        withLatestFrom(this._store.select('dietaryTagsImages')),
        switchMap(([action, images]) => {
            const dietaryTags: Set<number> = new Set();

            action.payload.Pages.forEach((page) => {
                page.Products.forEach((product) => {
                    if (product.DietaryTags) {
                        product.DietaryTags.forEach((tag) => dietaryTags.add(tag.Id));
                    }
                });
            });

            /* Filter existing */
            images.forEach((image) => dietaryTags.delete(image.Id));

            return of(actions.DietaryTagsImagesRequest({ params: { width: 150, height: 150 }, dietaryTagIds: Array.from(dietaryTags) }));
        }),
    );

    @Effect() public onRequestProductImages$: Observable<Action> = this._actions$.pipe(
        ofType(actions.DietaryTagsImagesRequest),
        mergeMap(({ params, dietaryTagIds }) => {
            if (dietaryTagIds.length === 0) return [actions.DietaryTagsImagesSuccessRequest({ payload: [], dietaryTagIds: [] })];

            return this._imagesService.getDietaryTagsImages(params, ...dietaryTagIds).pipe(
                map((images: OLO.DTO.ImageUrlModel[]) => actions.DietaryTagsImagesSuccessRequest({ payload: images, dietaryTagIds })),
                catchError((ex) => of(actions.DietaryTagsImagesErrorRequest({ ex, dietaryTagIds }))),
            );
        }),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
