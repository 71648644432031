import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { getAppSettingsTags } from '@shared/state/appSettings/selectors/getAppSettingsTags';
import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getOnlineMenuFiltersSearchInput } from '@shared/state/onlineMenuFilters/selectors';
import { getOnlineMenuVirtualLocationsProductsFiltered } from '../../onlineMenuVirtualLocations/getOnlineMenuVirtualLocationsProductsFiltered';

export const getOnlineMenuPagesFiltered = createSelector(getOnlineMenu, getOnlineMenuFiltersSearchInput, getOnlineMenuVirtualLocationsProductsFiltered, getAppSettingsTags,
    (onlineMenu, searchInput, onlineMenuVirtualLocationsProductsFiltered, tagSettings) => {
        if (onlineMenu?.isDownloading) {
            return null;
        }
        let pages = onlineMenu?.data?.Pages || null;
        const onlineMenuHasFeaturedProductPage = onlineMenu?.data?.Pages.some(page => page.PageType === OLO.Enums.ONLINE_MENU_PAGE_TYPE.FEATURED_PRODUCT_PAGE);

        if (onlineMenuHasFeaturedProductPage && onlineMenuVirtualLocationsProductsFiltered) {
            pages = pages.map(page => {
                if (page.PageType === OLO.Enums.ONLINE_MENU_PAGE_TYPE.FEATURED_PRODUCT_PAGE) {
                    const featuredProducts = onlineMenuVirtualLocationsProductsFiltered.filter(product => product.Tags?.some(tag => tag.Id === tagSettings?.FeaturedMenuItemTagId));

                    return {
                        ...page,
                        Products: featuredProducts
                    };
                }

                return page;
            });
        }

        if (!searchInput || !pages) {
            return pages;
        }

        return pages.reduce((pagesAcc, page) => {
            if (page.Products.length === 0) return pagesAcc;

            /* Page check */
            const pageMatches = Utils.Strings.searchValueStringInObject(searchInput, page, 'Name', 'Description');
            if (pageMatches) {
                if (page.Products.length) {
                    pagesAcc.push(page);
                }

                return pagesAcc;
            }

            /* Check products */
            const productsFiltered = page.Products.filter((product) => {
                const productMatches = Utils.Strings.searchValueStringInObject(searchInput, product, 'PosDisplay', 'PosDescription');
                if (productMatches) return true;

                /* Check tags if no match in prop values */
                return !product.Tags ? false : product.Tags.some((t) => Utils.Strings.searchValueStringInObject(searchInput, t, 'Name'));
            });

            if (productsFiltered.length) {
                pagesAcc.push({
                    ...page,
                    Products: productsFiltered,
                });
            }

            return pagesAcc;
        }, [] as OLO.DTO.OnlineMenuPageResponseModel[]);
    });
