import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';
import { getActiveCardDetails } from '../getActiveCardDetails';
import { getCardConnectLocationConfig } from '../getCardConnectLocationConfig';

export const isCardBillingDetailsValid = createSelector(getActiveCardDetails, getCardConnectLocationConfig, (activeCard, cardConnectLocationConfig) => {
    const config = new Statics.ConfigStatic().current;
    const isBillingDetailsSupported = Utils.BillingDetails.isBillingDetailsSupported();
    if (!isBillingDetailsSupported) {
        return true;
    }

    const cardBillingDetails = activeCard?.BillingDetails;
    if (config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT) {
        const requiresBillingDetails = cardConnectLocationConfig.data?.IsAvsEnabled;
        if (requiresBillingDetails == null) {
            return false;
        }

        if (requiresBillingDetails) {
            return Boolean(cardBillingDetails);
        }

        return true;
    }

    return Boolean(cardBillingDetails);
});
