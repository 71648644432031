import { createSelector } from '@ngrx/store';
import * as Models from '@shared/core/models';
import { getLocationImagesForIds } from '@shared/state/locationsImages/selectors';

export const getVirtualLocationsLogoImages = (virtualLocations: OLO.DTO.VirtualLocationBusinessModel[], imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForLogo) => {
    const virtualLocationsIds = [...new Set<number>(virtualLocations.map(virtualLocation => virtualLocation.LocationNo).flat())];

    return createSelector(getLocationImagesForIds(imageType, virtualLocationsIds), (images) => images.map(img => new Models.LabelImage(
        img.data?.ImageId,
        img.data?.ImageUrl,
        img.isDownloading
    )));
};
