import { Injectable, Inject } from '@angular/core';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

@Injectable({
    providedIn: 'root',
})
export class PriceService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
    ) {
    }

    public getPagesDetails(menuFlow: OLO.DTO.MenuFlowDetailsModel): OLO.Ordering.PageSpecials {
        const PagesSpecials: OLO.Ordering.PageSpecials = {};

        menuFlow.Pages.forEach(page => PagesSpecials[`PageIdentifier-${page.PageIdentifier}`] = {
            PageIdentifier: page.PageIdentifier,
            SpecialPrice: page.SpecialPrice,
            SpecialPriceQuantity: page.SpecialPriceQuantity,
            SpecialPriceSelection: page.SpecialPriceSelection,
            PriceChangeMode: page.PriceChangeMode,
            PriceChangeValue: page.PriceChangeValue,
            PageProductIds: page.Products.map(product => product.PageProductIdentifier),
        });

        return PagesSpecials;
    }

    public extraSumHandler(extraSum?: number | { [key: string]: number; } | number[]): number {
        const convert = (value: any): number => {
            if (Number.isNaN(value) || typeof value === 'symbol') {
                return 0;
            }

            let val: number = 0;
            val = +value;

            if (Number.isNaN(val)) {
                val = 0;
            }

            return val;
        };

        switch (Utils.Objects.getType(extraSum)) {
            case 'object':
                return Object.keys(extraSum).reduce((prev, key) => prev + convert(extraSum[key]), 0);

            case 'array':
                return (extraSum as number[]).reduce((prev, curr) => prev + convert(curr), 0);

            default:
                return convert(extraSum);
        }
    }

    public inDepthUnitPriceCalcForProducts(menuFlow: OLO.DTO.MenuFlowDetailsModel, products: OLO.DTO.MenuFlowProduct[]): OLO.Ordering.ItemPriceObj[] {
        const PagesSpecials: OLO.Ordering.PageSpecials = this.getPagesDetails(menuFlow);

        let productsPriceObjects: OLO.Ordering.ItemPriceObj[] = [];

        for (const prop in PagesSpecials) {
            const pageDetails = PagesSpecials[prop];
            const productItemsForPage: OLO.DTO.MenuFlowProduct[] = products.filter(product => pageDetails.PageProductIds.includes(product.PageProductIdentifier));

            productsPriceObjects = productsPriceObjects.concat(Utils.Products.generateCalculatedPriceObjects(pageDetails, productItemsForPage));
        }

        return productsPriceObjects;
    }

    public inDepthCalc(menuFlow: OLO.DTO.MenuFlowDetailsModel, products: OLO.DTO.MenuFlowProduct[], extraSum?: number | { [key: string]: number; } | number[]): number {
        return this.inDepthUnitPriceCalcForProducts(menuFlow, products)
            .reduce((prev, next) =>
                prev + next.TotalValue,
            menuFlow.OverridePrice + this.extraSumHandler(extraSum)
            );
    }
}
