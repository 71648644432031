import { NgModule, Type } from '@angular/core';

import { AlertMessagesStateModule } from './alertMessages/alert-messages.state.module';
import { AppSettingsStateModule } from './appSettings/app-settings.state.module';
import { AvailablePickupsStateModule } from './availablePickups/available-pickups.state.module';
import { CartStateModule } from './cart/cart.state.module';
import { CartPopupStateModule } from './cartPopup/cart-popup.state.module';
import { CollectionTypeStateModule } from './collectionType/collection-type.state.module';
import { CreditCardsStateModule } from './creditCards/credit-cards.state.module';
import { CurrentLocationStateModule } from './currentLocation/current-location.state.module';
import { DietaryTagsImagesStateModule } from './dietaryTagsImages/dietary-tags-images.state.module';
import { FreeProductsImagesStateModule } from './freeProductsImages/free-products-images.state.module';
import { GeolocationStateModule } from './geolocation/geolocation.state.module';
import { GoogleAnalyticsStateModule } from './googleAnalytics/google-analytics.state.module';
import { GoogleStateModule } from './google/google.state.module';
import { HistoryOrdersStateModule } from './historyOrders/history-orders.state.module';
import { IngredientsStateModule } from './ingredients/ingredients.state.module';
import { LatestTransactionsStateModule } from './latestTransactions/latest-transactions.state.module';
import { LoaderStateModule } from './loader/loader.state.module';
import { LocationsStateModule } from './locations/locations.state.module';
import { LocationsFiltersStateModule } from './locationsFilters/locations-filters.state.module';
import { LocationsImagesStateModule } from './locationsImages/locations-images.state.module';
import { LoyaltyIntroductionPagesStateModule } from './loyaltyIntroductionPages/loyalty-introduction-pages.state.module';
import { LoyaltyIntroductionPagesImagesStateModule } from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.state.module';
import { LoyaltyMessagesStateModule } from './loyaltyMessages/loyalty-messages.state.module';
import { LoyaltyProductProgramsStateModule } from './loyaltyProductPrograms/loyalty-product-programs.state.module';
import { LoyaltyProgramsStateModule } from './loyaltyPrograms/loyalty-programs.state.module';
import { MemberCardBarcodesImagesStateModule } from './memberCardBarcodesImages/member-card-barcodes-images.state.module';
import { MembersStateModule } from './members/members.state.module';
import { MenuFlowDefaultActivationsStateModule } from './menuFlowDefaultActivations/menu-flow-default-activations.state.module';
import { MenuFlowImagesStateModule } from './menuFlowImages/menu-flow-images.state.module';
import { MenuFlowsStateModule } from './menuFlows/menu-flows.state.module';
import { ModalsStateModule } from './modals/modals.state.module';
import { OnlineMenuStateModule } from './onlineMenu/online-menu.state.module';
import { OnlineMenuVirtualLocationsStateModule } from './onlineMenuVirtualLocations/online-menu-virtual-locations.state.module';
import { OnlineMenuFiltersStateModule } from './onlineMenuFilters/online-menu-filters.state.module';
import { OnlineMenuPagesImagesStateModule } from './onlineMenuPagesImages/online-menu-pages-images.state.module';
import { OnlineMenuProductsImagesStateModule } from './onlineMenuProductsImages/online-menu-products-images.state.module';
import { OnlineOrderStateModule } from './onlineOrder/online-order.state.module';
import { OrderTypesStateModule } from './orderTypes/order-types.state.module';
import { OrderTypesImagesStateModule } from './orderTypesImages/order-types-images.state.module';
import { PaymentStateModule } from './payment/payment.state.module';
import { PickupsStateModule } from './pickups/pickups.state.module';
import { ProductImagesStateModule } from './productImages/product-images.state.module';
import { ProductRestrictionsStateModule } from './productRestrictions/product-restrictions.state.module';
import { ReorderStateModule } from './reorder/reorder.state.module';
import { RouterStateModule } from './router/router.state.module';
import { AppStateStateModule } from './state/state.state.module';
import { TopBarStateModule } from './topBar/top-bar.state.module';
import { VenuesImagesStateModule } from './venuesImages/venues-images.state.module';
import { WizzardStateModule } from './wizzard/wizzard.state.module';
import { VirtualLocationsStateModule } from './virutalLocations';

const stateModules: Type<any>[] = [
    AlertMessagesStateModule,
    AppSettingsStateModule,
    AvailablePickupsStateModule,
    CartStateModule,
    CartPopupStateModule,
    CollectionTypeStateModule,
    CreditCardsStateModule,
    CurrentLocationStateModule,
    DietaryTagsImagesStateModule,
    FreeProductsImagesStateModule,
    GeolocationStateModule,
    GoogleAnalyticsStateModule,
    GoogleStateModule,
    HistoryOrdersStateModule,
    IngredientsStateModule,
    LatestTransactionsStateModule,
    LoaderStateModule,
    LocationsStateModule,
    LocationsFiltersStateModule,
    LocationsImagesStateModule,
    LoyaltyIntroductionPagesStateModule,
    LoyaltyIntroductionPagesImagesStateModule,
    LoyaltyMessagesStateModule,
    LoyaltyProductProgramsStateModule,
    LoyaltyProgramsStateModule,
    MemberCardBarcodesImagesStateModule,
    MembersStateModule,
    MenuFlowDefaultActivationsStateModule,
    MenuFlowImagesStateModule,
    MenuFlowsStateModule,
    ModalsStateModule,
    OnlineMenuStateModule,
    OnlineMenuFiltersStateModule,
    OnlineMenuPagesImagesStateModule,
    OnlineMenuProductsImagesStateModule,
    OnlineMenuVirtualLocationsStateModule,
    OnlineOrderStateModule,
    OrderTypesStateModule,
    OrderTypesImagesStateModule,
    PaymentStateModule,
    PickupsStateModule,
    ProductImagesStateModule,
    ProductRestrictionsStateModule,
    ReorderStateModule,
    RouterStateModule,
    AppStateStateModule,
    TopBarStateModule,
    VenuesImagesStateModule,
    VirtualLocationsStateModule,
    WizzardStateModule,
];

@NgModule({
    imports: [...stateModules],
    exports: [...stateModules],
})
export class StateSharedModule {}
