import { createSelector } from '@ngrx/store';

import { isOnlineMapped } from '@shared/state/appSettings/selectors';
import { getPaymentErrorsMapped } from '@shared/state/payment/selectors';
import { hasRecalculateFailedErrorMapped } from '@shared/state/onlineOrder/selectors';
import { getCardsErrorsMapped } from '@shared/state/creditCards/selectors';

export const getOrderErrorsMapped = () =>
    createSelector(
        isOnlineMapped(),
        getPaymentErrorsMapped,
        hasRecalculateFailedErrorMapped,
        getCardsErrorsMapped,
        (offlineErrors, paymentErrors, onlineOrders, creditCardsErrors) => offlineErrors || paymentErrors || onlineOrders || creditCardsErrors,
    );
