import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of, never, combineLatest } from 'rxjs';
import { catchError, switchMap, map, filter, take, auditTime, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OnlineMenuEffects {
    @Effect() public onPickupTimeSetGetOnlineMenu$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CurrentLocationSet, actions.SetCollectionType, actions.PatchOrderTypeIdCollectionTypeValue, actions.CurrentLocationPickupTimeSet),
        auditTime(100),
        switchMap(() =>
            combineLatest(
                this._store.pipe(
                    select(selectors.getOrderTypeId),
                    filter((orderTypeId) => !!orderTypeId),
                ),
                this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
                this._store.pipe(select(selectors.getCurrentLocationNo)),
                this._store.pipe(
                    select(selectors.getCurrentPickupTime),
                    filter((pickupTime) => !!pickupTime),
                ),
                this._store.pipe(
                    select(selectors.getOnlineMenu),
                    filter((onlineMenu) => !onlineMenu.isDownloading),
                ),
                this._store.pipe(select(selectors.getCartOrderTypeId)),
                this._store.pipe(select(selectors.getCartLocationNo)),
            ).pipe(take(1)),
        ),
        switchMap(([orderTypeId, isOnLocationDetailsPage, isCurrentRouteCheckoutPage, locationNo, pickupTime, onlineMenu, cartOrderTypeId, cartLocationNo]) => {
            const pickupTimeIsSet: boolean = !!pickupTime;
            const pickupTimeMatchesOnlineMenuRange: boolean =
                onlineMenu.data !== null && Utils.Dates.isHourInHoursRange(pickupTime?.Hour, onlineMenu.data.StartTime, onlineMenu.data.EndTime);
            const isSameDay: boolean = pickupTime?.DateLocalISO.split('T')[0] === onlineMenu.pickupDate?.split('T')[0];
            const locationHasChanged: boolean = locationNo !== onlineMenu.locationNo;
            const menuHasDownloaded: boolean =
                onlineMenu.data !== null && cartLocationNo === locationNo && locationHasChanged
                    ? onlineMenu.orderTypeId === cartOrderTypeId
                    : onlineMenu.orderTypeId === orderTypeId;
            const shouldRequestOnlineMenu: boolean =
                (isOnLocationDetailsPage || isCurrentRouteCheckoutPage) &&
                (locationHasChanged || !menuHasDownloaded || !pickupTimeMatchesOnlineMenuRange || !pickupTimeIsSet || !isSameDay);
            if (!shouldRequestOnlineMenu) return never();

            return of(actions.OnlineMenuPagesRequest(locationNo, orderTypeId, Utils.Dates.getLocalISOFormatDate(pickupTime.Date, true)));
        }),
    );

    @Effect() public requestOnlineMenu$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineMenuPagesRequest),
        switchMap(({ locationNo, orderTypeId, pickupDate }) =>
            this._onlineMenuService
                .getMenuPages({
                    locationNo,
                    orderTypeId,
                    menuDate: pickupDate,
                })
                .pipe(
                    withLatestFrom(this._store.select(selectors.getLocationDetails(locationNo))),
                    map(([response, locationDetails]) => {
                        const onlineMenuHasFeaturedProductPage = response.Pages.some((page) => page.PageType === OLO.Enums.ONLINE_MENU_PAGE_TYPE.FEATURED_PRODUCT_PAGE);
                        const hasOnlineLocationClassification = locationDetails?.VirtualLocations?.some(
                            (virtualLocation) => virtualLocation?.LocationClassification === OLO.Enums.LOCATION_CLASSIFICATION.ONLINE_LOCATION,
                        );

                        if (!onlineMenuHasFeaturedProductPage && hasOnlineLocationClassification) {
                            console.warn(
                                `WARNING! The physcial location no ${locationDetails.LocationNo} has virtual locations with ONLINE LOCATION classification.` +
                                    'The physical location can not have online menu assigned in that case, the physical location should have only featured products page.',
                            );
                        }

                        /* Sort first by DisplayIndex */
                        response.Pages = response.Pages.sort((a, b) => {
                            switch (true) {
                                case a.DisplayIndex < b.DisplayIndex:
                                    return -1;
                                case a.DisplayIndex > b.DisplayIndex:
                                    return 1;
                                default:
                                    return 0;
                            }
                        });

                        return actions.OnlineMenuPagesSuccessRequest(locationNo, orderTypeId, pickupDate, response);
                    }),
                    catchError((ex) => {
                        console.error(ex);

                        return [actions.OnlineMenuPageErrorRequest(locationNo, orderTypeId, pickupDate, ex)];
                    }),
                ),
        ),
    );

    constructor(private _actions$: Actions, private _onlineMenuService: Services.OnlineMenuService, private _store: Store<OLO.State>) {}
}
