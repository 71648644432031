import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CreditCardEffects } from './credit-cards.effects';
import { CardConnectLocationConfigEffects, MemberBillingAddressModalEffects } from './effects';
import { creditCardReducer } from './credit-cards.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('creditCards', creditCardReducer),
        EffectsModule.forFeature([CreditCardEffects, CardConnectLocationConfigEffects, MemberBillingAddressModalEffects]),
    ],
})
export class CreditCardsStateModule {}
