import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './locations-images.actions';
import * as Utils from '@shared/core/utils';

const initialState: OLO.State.LocationsImages = {
    default: [],
    forList: [],
    forDetails: [],
    forWeb: [],
    forWebThumb: [],
    forMobile: [],
    forPushNotifications: [],
    forLogo: []
};

export const locationImagesReducerFn = createReducer(
    initialState,

    on(actions.LocationImagesRequest, (state, action) => {
        let imagesType: string = Utils.Images.toImageTypeString(action.imageType);
        const newImages: number[] = action.ids.filter((id) => !state[imagesType].find((img: OLO.State.LocationsImages.ImageStateObj) => img.Id === id));

        return {
            ...state,
            [imagesType]: [
                ...state[imagesType].map((image) => {
                    if (action.ids.includes(image.Id)) {
                        return {
                            ...image,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return image;
                }),

                ...newImages.map((id) => ({
                    Id: id,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: false,
                    data: null,
                })),
            ],
        };
    }),

    on(actions.LocationImagesSuccessRequest, (state, action) => {
        let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

        return {
            ...state,
            [imagesType]: state[imagesType].map((image) => {
                const imageIsUpdating = action.ids.includes(image.Id);
                if (imageIsUpdating) {
                    const newImage = action.payload.find((obj) => obj.ParentId === image.Id);
                    const previousImage = state[imagesType].find((obj) => obj.Id === image.Id);

                    return {
                        ...image,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        data: newImage ? newImage : previousImage && previousImage.data ? previousImage.data : null,
                    };
                }

                return image;
            }),
        };
    }),

    on(actions.LocationImagesErrorRequest, (state, action) => {
        let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

        return {
            ...state,
            [imagesType]: state[imagesType].map((image) => {
                if (action.ids.includes(image.Id)) {
                    return {
                        ...image,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }

                return image;
            }),
        };
    }),
);

export function locationImagesReducer(state: OLO.State.LocationsImages | undefined, action: Action) {
    return locationImagesReducerFn(state, action);
}
