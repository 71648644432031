import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../../../actions';
import * as selectors from '../../../selectors';

import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, filter, take } from 'rxjs/operators';

@Injectable()
export class MemberBillingAddressModalEffects {
    public checkCardBillingDetailsForModal$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.SelectActiveCreditCardId),
            filter(({ cardId }) => Boolean(cardId)),
            withLatestFrom(
                this._store.pipe(select(selectors.isMemberAuthorizedJWT)),
                this._store.pipe(select(selectors.isBillingDetailsForCardRequired)),
                this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
                this._store.pipe(select(selectors.getActiveCardDetails)),
            ),
            filter(([, , isBillingAddressRequired, isCheckoutPage, activeCard]) => isCheckoutPage && isBillingAddressRequired !== null && Boolean(activeCard)),
            switchMap(([{ cardId }, isAuthorized, isBillingAddressRequired]) =>
                this._store.pipe(
                    select(selectors.isOrderTypeValid),
                    filter((isValid) => isValid),
                    filter(() => isAuthorized && isBillingAddressRequired),
                    take(1),
                    switchMap(() => [actions.CreditCardsForceAskBillingDetailsForCard({ cardId })]),
                ),
            ),
        ),
    );

    public showBillingDetailsModal$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CreditCardsForceAskBillingDetailsForCard),
            withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
            switchMap(([, modals]) => {
                const billingModalExists = modals.find((modal) => modal.type === 'billing-details');
                if (billingModalExists) {
                    return [];
                }

                const modalParams: OLO.State.Modals.Modal = {
                    type: 'billing-details',
                    animate: OLO.Components.Modals.MODAL_ANIMATION.IN,
                };
                this._modalsService.show(modalParams);

                return [];
            }),
        ),
    );

    constructor(private _store: Store<OLO.State>, private _actions$: Actions, private _modalsService: Services.ModalsService) {}
}
