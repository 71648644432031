import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import { Observable, from } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GoogleGeocoderService {
    private _googleGeocoder!: google.maps.Geocoder;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        protected _httpClient: HttpClient,
    ) { }

    private _initGoogleServices(): void {
        if (!this._googleGeocoder && google) {
            this._googleGeocoder = new google.maps.Geocoder();
        }
    }

    public geocodeLatLng(latlng: google.maps.LatLng): Observable<google.maps.GeocoderResult[]> {
        this._initGoogleServices();

        return from(
            new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
                this._googleGeocoder.geocode({
                    location: latlng,
                    // componentRestrictions: this._componentRestrictions
                }, (result, status) => {
                    if (status !== google.maps.GeocoderStatus.OK) return reject(status);

                    resolve(result);
                });
            })).pipe(
            take(1)
        );
    }

    private get _componentRestrictions(): APICommon.GoogleComponentRestrictions {
        return this._config.google?.maps?.componentRestrictions || (this._config.localization?.country ? { country: this._config.localization?.country } : null);
    }
}
