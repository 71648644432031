/**
 * Hello, welcome to the kingdome of validation setup props.
 * This is not direct model validation - only relation betweene provided
 * VALUES should be checked.
 * Do not perform model validation here!
 */
import { Validators } from './validators';

type LiveViewRegistry = {
    statuses: number[];
    _context: string;
};

export class OrderConfirmationPageValidators extends Validators {
    public static readonly ERROR_TYPES = {
        liveViewStatusOrder: 'Invalid statuses sort order in order confirmation live view section. Next object cannot contain lower or same statuses as previous ones',
    };

    /**
     * Checks if order of the statuses in live view section is correct.
     * Not used right now due to fact, that order status 2 is being set later than 3 & 4
     * @param config
     * @returns errors
     */
    public static validateLiveViewStatusesSortOrderStatuses(config: OLO.Config): typeof OrderConfirmationPageValidators._errors {
        Validators._registerErrorTypes(OrderConfirmationPageValidators.ERROR_TYPES);

        const pages = config.orderConfirmationPage;

        OrderConfirmationPageValidators._errors = [];

        const liveViewPageStatuses = pages.reduce((acc, page, pageIndex) => {
            if(!acc[`${pageIndex}`]) {
                acc[`${pageIndex}`] = null;
            }
            const arrayOfStatuses = page.orderStatusSection.liveViewSection.map((liveViewObj, liveViewIndex) => ({
                statuses: [...liveViewObj.statusFilter],
                _context: `orderConfirmationPage[${pageIndex}].orderStatusSection.liveViewSection[${liveViewIndex}]`,
            }));

            acc[`${pageIndex}`] = arrayOfStatuses;

            return acc;
        }, {} as Record<string, LiveViewRegistry[]>);

        Object.entries(liveViewPageStatuses).map(([key, value]) => {
            const prevStatuses = [];
            const prevContext = [];

            value.forEach((liveViewItem, liveViewIndex) => {
                liveViewItem.statuses.forEach(statusId => {
                    const prevBiggerOrEqual = prevStatuses.find(prevStatusId => prevStatusId >= statusId);
                    const currentContext = pages[+key].orderStatusSection.liveViewSection[liveViewIndex];
                    if(!prevContext.includes(currentContext)) {
                        prevContext.push(currentContext);
                    }

                    if(prevBiggerOrEqual) {
                        OrderConfirmationPageValidators._errors.push({
                            liveViewStatusOrder: {
                                context: [
                                    ...prevContext,
                                ],
                                path: liveViewItem._context,
                                foundIn: `[${liveViewIndex}]`,
                                value: statusId,
                            }
                        });
                    }

                    prevStatuses.push(statusId);
                });
            });
        });

        return OrderConfirmationPageValidators._errors;
    }

}
