import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';
import * as Utils from '@shared/core/utils';

export class OrderPaymentWithCardConnect extends OrderPaymentMethod {
    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentProvider, cardState, paymentMethod } = details;
        const cardDetails = this._getCardDetails(cardState);

        if (!this._isVendorPayment(paymentMethod) && paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT && cardDetails) {
            this._extendExecutePaymentModel(details);

            this._executePaymentModel.ExpirationDate = Utils.CreditCards.dateToISOString(cardDetails.ExpirationDate);
            this._executePaymentModel.CardData = {
                ...this._executePaymentModel.CardData,
                /** According to TOLO-1936, cvv should be encrypted */
                EncryptedSecurityCode: cardDetails.Cvv ? window.btoa(cardDetails.Cvv) : null,
            };

            this._executePaymentModel.BillingAddress = cardDetails.BillingDetails ?? null;

            return this._executePaymentModel;
        }

        if (this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
