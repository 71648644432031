import { createSelector } from '@ngrx/store';
import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getCurrentLocationValidationState } from '@shared/state/currentLocation/selectors';
import { getCurrentLocationDetails } from '../../locations/getCurrentLocationDetails';

export const canShowOnlineMenuForCurrentLocation = createSelector(
    getCurrentLocationDetails,
    getCurrentLocationValidationState,
    getOnlineMenu,
    (currentLocationDetails, validation, onlineMenu) => {
        const locationHasVirtualLocations = currentLocationDetails?.VirtualLocations?.length > 0;

        return currentLocationDetails?.LocationNo != null &&
        onlineMenu?.locationNo === currentLocationDetails?.LocationNo &&
        (!!onlineMenu?.data || (!onlineMenu?.data && !!locationHasVirtualLocations)) &&
        validation?.hasSucceeded === true &&
        validation?.hasFailed !== true &&
        validation?.isValidating !== true;
    }
);
