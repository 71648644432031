// https://www.windcave.com/developer-ecommerce-google-pay#merchant_hosted_payment_page
// https://developers.google.com/pay/api/web/guides/tutorial
import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as Mappers from '@shared/core/mappers/paymentProviders';

@Injectable({
    providedIn: 'root',
})
export class GooglePayPaymentProviderService {
    private _googlePayConfig!: OLO.Config.Payments.GooglePay;
    private _scriptElem!: HTMLScriptElement;
    private _instance!: google.payments.api.PaymentsClient;
    private _payButton!: HTMLElement;
    private _payButtonContainer!: HTMLElement;
    private _baseRequest!: google.payments.api.baseRequest;
    private _basePaymentMethod!: google.payments.api.IsReadyToPayPaymentMethodSpecification;
    private _readyToPayRequest!: google.payments.api.IsReadyToPayRequest;

    constructor(@Inject(Tokens.ENV_TOKEN) public env: Environment, @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient) {
        this._googlePayConfig = this.config.payments.googlePay;
        this._baseRequest = {
            apiVersion: this._googlePayConfig.apiVersion,
            apiVersionMinor: this._googlePayConfig.apiVersionMinor,
        };
        this._basePaymentMethod = {
            type: 'CARD',
            parameters: {
                allowedAuthMethods: this._googlePayConfig.allowedCardAuthMethods,
                allowedCardNetworks: this._googlePayConfig.allowedCardNetworks,
            },
            tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                    gateway: this._googlePayConfig.gateway,
                    gatewayMerchantId: this._googlePayConfig.gatewayMerchantId,
                },
            },
        };

        this._readyToPayRequest = {
            ...this._baseRequest,
            allowedPaymentMethods: [{ ...this._basePaymentMethod }],
        };
    }

    public get isConfigured(): boolean {
        return Utils.VendorPayments.isAppConfiguredForGooglePay(this.config);
    }

    public async setup(
        config: Partial<google.payments.api.PaymentOptions>,
        payButtonContainer: HTMLElement,
        onPayButtonClickCallback: () => any,
    ): Promise<google.payments.api.PaymentsClient> {
        this.destroy();

        if (!this.isConfigured) return;
        this._instance = new google.payments.api.PaymentsClient({
            environment: this.config.payments.testMode ? 'TEST' : 'PRODUCTION',
            merchantInfo: {
                merchantName: this._googlePayConfig.merchantName ?? null,
                merchantId: this._googlePayConfig.gatewayMerchantId,
            },
            ...config,
        });

        if ((await this.isReadyToPay()) && payButtonContainer) {
            this._payButtonContainer = payButtonContainer;
            this._payButton = this._instance.createButton({
                buttonSizeMode: 'fill',
                buttonType: 'plain',
                onClick: onPayButtonClickCallback,
            });

            this._payButtonContainer.appendChild(this._payButton);
        }

        return this._instance;
    }

    public async isReadyToPay(): Promise<boolean> {
        if (!this.isConfigured || !this._instance) return false;

        return new Promise((resolve) => {
            this._instance
                .isReadyToPay({
                    ...this._readyToPayRequest,
                })
                .then((response) => {
                    if (response.result) {
                        return resolve(true);
                    }

                    throw new Error(`Not ready to pay ${response?.result}`);
                })
                .catch((err) => {
                    console.error('Google Pay, isReadyToPay error:', err);

                    resolve(false);
                });
        });
    }

    private _generatePaymentDataRequestObj(totalPrice: string): google.payments.api.PaymentDataRequest {
        return {
            ...(this._readyToPayRequest as google.payments.api.PaymentDataRequest),
            transactionInfo: {
                totalPrice,
                totalPriceStatus: 'FINAL',
                countryCode: this.config.localization.country,
                currencyCode: this.config.localization.currency || 'AUD',
            },
            merchantInfo: {
                merchantId: this._googlePayConfig.gatewayMerchantId,
                merchantName: this._googlePayConfig.merchantName ?? null,
            },
        };
    }

    public async pay(totalPrice: string): Promise<OLO.DTO.GooglePayPaymentData> {
        return new Promise((resolve) => {
            this._instance
                .loadPaymentData(this._generatePaymentDataRequestObj(totalPrice))
                .then((response) => {
                    resolve(Mappers.GooglePayPaymentProviderMapper.mapDirectResponseToPayData(response?.paymentMethodData));
                })
                .catch((ex) => {
                    console.error('Google pay payment error:', ex);
                    resolve(null);
                });
        });
    }

    public destroy(): void {
        this._instance = null;
        if (this._payButton && this._payButtonContainer) {
            this._payButtonContainer.removeChild(this._payButton);
        }
        this._payButton = null;
        this._payButtonContainer = null;
    }

    public async addHtmlElementsToDOM(): Promise<boolean> {
        if (this._scriptElem) return null;

        if (!this.isConfigured) return false;

        return new Promise((resolve, reject) => {
            this._scriptElem = document.createElement('script');
            this._scriptElem.src = '//pay.google.com/gp/p/js/pay.js';

            this._scriptElem.onload = () => resolve(true);
            this._scriptElem.onerror = () => reject('Unable to load google-pay script element');

            document.body.appendChild(this._scriptElem);
        });
    }
}
