import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, filter } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
    public iosScrollBetweenRoutesFix$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.RouterNavigationStart),
                filter(Utils.Devices.isMobile),
                switchMap(() => {
                    Utils.Scroll.smoothScrollTo(0, 100);

                    return [];
                }),
            ),
        {
            dispatch: false,
        },
    );

    public redirectToHomeOnSuccessfulSignIn$: Observable<Action> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.MemberJwtDataResponseSuccess),
                withLatestFrom(
                    this._store.pipe(select(selectors.isMemberAuthorized)),
                    this._store.pipe(select(selectors.isCurrentRouteEmailConfirmationPage)),
                    this._store.pipe(select(selectors.isCurrentRouteResetPasswordPage)),
                    this._store.pipe(select(selectors.isCurrentRouteError404Page)),
                    this._store.pipe(select(selectors.isCurrentRouteLoyalty)),
                ),
                switchMap(([, isMemberAuthorized, isEmailConfrimationPage, isResetPasswordPage, isError404Page, isLoyaltyPage]) => {
                    const isLoyaltyModeOnly = this._config.appMode === OLO.Enums.APP_MODE.LOYALTY_ONLY;

                    if (isLoyaltyPage && this._config.appMode !== OLO.Enums.APP_MODE.ORDERING_ONLY) {
                        this._routeService.navigateToProfileLoyaltyHome();

                        return [];
                    }

                    /**  The condition is required for case when unauthorized user is on reset password page, confirm email etc. and he performs login,
                     * after that he will be redirected to home page. We check isMemberAuthorized flag for case when user data comes from active
                     * session storage and redirect is not undesirable. */
                    if (isResetPasswordPage || (!isMemberAuthorized && (isLoyaltyModeOnly || isEmailConfrimationPage || isError404Page))) {
                        this._routeService.navigateToHomeView();
                    }

                    return [];
                }),
            ),
        { dispatch: false },
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _routeService: Services.RouteService,
    ) {}
}
