import { createSelector } from '@ngrx/store';

import { getCart, getCartSimpleItemQuantity } from '@shared/state/cart/selectors';
import { getCurrentLocationNo } from '@shared/state/currentLocation/selectors';

export const getCartSimpleProductTotalQuantityForCurrentLocation = (Plu: number, virtualLocationNo?: number) =>
    createSelector(getCart, getCartSimpleItemQuantity(Plu, virtualLocationNo), getCurrentLocationNo, (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;

        return total;
    });
