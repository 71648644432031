import { createSelector } from '@ngrx/store';
import { getCardsState } from '../../../creditCards/selectors/getCardsState';
import { isLoadingCards } from '../../../creditCards/selectors/isLoadingCards';
import { isActiveIdCardlessType } from '../../../creditCards/selectors/isActiveIdCardlessType';
import { isCardBillingDetailsValid } from '../../../creditCards/selectors/isCardBillingDetailsValid';

export const isPaymentDisabledForCards = createSelector(
    getCardsState,
    isLoadingCards,
    isCardBillingDetailsValid,
    isActiveIdCardlessType,
    (cardsState, cardsLoading, isBillingDetailsValid, isCardlessType) => {
        const isDataEmpty = Boolean(cardsState.data?.length) === false;

        return (!isCardlessType && (!isBillingDetailsValid || cardsState.showAddCardForm === true || isDataEmpty))
        || cardsState.data == null
        || cardsLoading;
    },
);
