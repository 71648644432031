import { Injectable, Inject } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, combineLatest, take, filter, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AvailablePickupsEffects {
    @Effect() public onLocationsSuccessRequestDataCalculate$: Observable<Action> = this._actions$.pipe(
        ofType(actions.LocationsSuccessRequest, actions.LocationSuccessRequest),
        withLatestFrom(this._store.pipe(select(selectors.getOrderTypeId))),
        switchMap(([{ payload }, orderTypeId]) => {
            // TOLO | It is not a final solution. In the end all pickup calculation will be move to API
            if (this._config?.venue?.id) {
                const collectionType = new Utils.CollectionTypeGroupDetector(orderTypeId, this._config);

                return payload.map((obj) => actions.AvailablePickupsCalculateRequest({ locationNo: obj.LocationNo, collectionTypeId: collectionType.getCollectionType() }));
            }

            return [];
        }),
    );

    @Effect() public onCollectionTypeChangeRequestRecalculations$: Observable<Action> = this._actions$.pipe(
        ofType(actions.SetCollectionType),
        withLatestFrom(
            this._store.pipe(select(selectors.getOrderTypeId)),
            this._store.pipe(select(selectors.getCurrentLocationNo)),
            this._store.pipe(select(selectors.getAvailablePickupTimesForAllLocations)),
            this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
        ),
        switchMap(([, orderTypeId, locationNo, availablePickups, isLocationDetailsPage]) => {
            const collectionTypeId = new Utils.CollectionTypeGroupDetector(orderTypeId, this._config).getCollectionType();

            const foundAvailablePickups = availablePickups?.find((obj) => obj.collectionTypeId === collectionTypeId && locationNo === obj.locationNo);
            if (foundAvailablePickups) {
                return [];
            }

            if (locationNo && isLocationDetailsPage) {
                return [actions.AvailablePickupsCalculateRequest({ locationNo, collectionTypeId })];
            }

            return [];
        }),
    );

    /* This effect is triggered from orderingTimeInfo effects when data is downloaded after successful locations request  */
    @Effect() public calculateAvailablePickupOptionsForLocation: Observable<Action> = this._actions$.pipe(
        ofType(actions.AvailablePickupsCalculateRequest),
        switchMap((action) =>
            of(action.locationNo).pipe(
                combineLatest(
                    this._store.pipe(
                        select(selectors.getOrderingTimeInfoByLocationNo(action.locationNo)),
                        filter((obj) => obj != null),
                        take(1),
                    ),
                    this._store.pipe(
                        select(selectors.getLocationDetails(action.locationNo)),
                        filter((location) => location != null),
                        take(1),
                    ),
                    this._store.pipe(select(selectors.getSelectedOrderTypeId), take(1)),
                ),
                switchMap(([locationNo, openingHours, location, orderTypeId]) => {
                    const collectionTypeConfig = new Utils.CollectionTypeHelper(this._config.collectionTypes).getCollectionTypeConfig(action.collectionTypeId);

                    if (!collectionTypeConfig) {
                        const ex = `Pickup config not configured for location ${locationNo} (${location.LocationFriendlyName})`;

                        return [
                            actions.AvailablePickupsCalculateErrorRequest({
                                locationNo,
                                collectionTypeId: action.collectionTypeId,
                                ex,
                            }),
                        ];
                    }

                    const orderInfo = Utils.LocationPickups.getCompleteOrderInfoByDate({ location, date: null, orderTypeId });

                    const params: OLO.Ordering.GeneratePickupsParams = {
                        orderTypeId,
                        location,
                        asapPickupMins: orderInfo.minimumPickupTime,
                        openingHours,
                        schedule: this._config.onlineOrders.scheduledOrders === true,
                    };

                    if ('orderTimeoutBufferMins' in collectionTypeConfig) {
                        params.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
                        params.startBufferMins = collectionTypeConfig.startBufferMins;
                        params.nextTick = collectionTypeConfig.nextTick;
                        params.endBufferMins = collectionTypeConfig.endBufferMins;
                    }

                    if ('displayAsTimespans' in collectionTypeConfig) {
                        params.displayAsTimespans = collectionTypeConfig.displayAsTimespans;
                    }

                    const payload = Utils.Pickups.generatePickupTimesList(params);

                    return [
                        actions.AvailablePickupsCalculateSuccessRequest({
                            locationNo,
                            collectionTypeId: collectionTypeConfig.collectionTypeId,
                            payload,
                        }),
                    ];
                }),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
    ) {}
}
