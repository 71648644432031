import { SubNav } from './sub-nav.model';

export class SubNavBuilder<RCtx = any, TCtx = any> {
    private _id!: OLO.Components.SubNavId;
    private _name!: Nullable<string>;
    private _url: Nullable<string> = null;
    private _counter: Nullable<number>;
    private _imgUrl: Nullable<string> = null;
    private _imgBgColor: Nullable<string> = null;
    private _accentColor: Nullable<string> = null;
    private _parentId: Nullable<OLO.Components.SubNavId> = null;
    private _isVirtualLocation: boolean = false;
    private _items: Nullable<OLO.Components.SubNavItem<RCtx>[]> = null;

    constructor(private _ctx?: Nullable<TCtx>) {}

    public setId(id: OLO.Components.SubNavId) {
        this._id = id;

        return this;
    }

    public setName(name: string) {
        this._name = name;

        return this;
    }

    public setUrl(url: string) {
        this._url = url;

        return this;
    }

    public setCounter(counter?: Nullable<number>) {
        this._counter = counter ?? null;

        return this;
    }

    public setImgUrl(imgUrl?: Nullable<string>) {
        this._imgUrl = imgUrl ?? null;

        return this;
    }

    public setImgBgColor(imgBgColor?: Nullable<string>) {
        this._imgBgColor = imgBgColor ?? null;

        return this;
    }

    public setAccentColour(accentColor?: Nullable<string>) {
        this._accentColor = accentColor ?? null;

        return this;
    }

    public setParentId(parentId?: Nullable<OLO.Components.SubNavId>) {
        this._parentId = parentId ?? null;

        return this;
    }

    public setIsVirtualLocation(isVirtualLocation?: Nullable<boolean>) {
        this._isVirtualLocation = isVirtualLocation ?? false;

        return this;
    }

    public setItems(items?: Nullable<OLO.Components.SubNavItem<RCtx>[]>) {
        this._items = items ?? null;

        return this;
    }

    public build() {
        return new SubNav<TCtx, RCtx>({
            id: this._id,
            name: this._name,
            url: this._url,
            counter: this._counter,
            imgUrl: this._imgUrl,
            imgBgColor: this._imgBgColor,
            accentColor: this._accentColor,
            parentId: this._parentId,
            isVirtualLocation: this._isVirtualLocation,
            items: this._items,
            ctx: this._ctx,
        });
    }
}
