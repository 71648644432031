import { createAction, props } from '@ngrx/store';

/* For reset */
export const OnlineOrderStateReset = createAction('[Online order] RESET STATE');

export const OnlineOrderClearPostOrderRequestFlags = createAction('[Online order] Clear flags for create order');

/* For post */
export const OnlineOrderCreateRequest = createAction('[Online order] Request create');

export const OnlineOrderCreateSuccessRequest = createAction(
    '[Online order] Success request create',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderCreateErrorRequest = createAction(
    '[Online order] Error request create',
    props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel; ex?: any; }>(),
);

/* For update */
export const OnlineOrderUpdateRequest = createAction(
    '[Online order] Request update',
    props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderUpdateSuccessRequest = createAction(
    '[Online order] Success request update',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderUpdateErrorRequest = createAction(
    '[Online order] Error request update',
    props<{ orderId: number; ex?: any; }>(),
);

export const OnlineOrderUpdateRequestReset = createAction(
    '[Online order] Request update -reset',
);

/* For cancel */
export const OnlineOrderCancelRequest = createAction(
    '[Online order] Request cancel',
    props<{ orderId: number; updateHistoryOrder: boolean; }>(),
);

export const OnlineOrderCancelSuccessRequest = createAction(
    '[Online order] Success request cancel',
    props<{ payload: boolean; orderId: number; updateHistoryOrder: boolean; }>(),
);

export const OnlineOrderCancelErrorRequest = createAction(
    '[Online order] Error request cancel',
    props<{ orderId: number; ex?: any; }>(),
);

export const OnlineOrderCancelRequestReset = createAction(
    '[Online order] Request cancel - reset',
);

/* For get */
export const OnlineOrderRequest = createAction(
    '[Online order] Request get',
    props<{ orderId: number; }>(),
);

export const OnlineOrderSuccessRequest = createAction(
    '[Online order] Success request get',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderErrorRequest = createAction(
    '[Online order] Error request get',
    props<{ orderId: number; ex?: any; }>(),
);

/* for recalculate */
export const OnlineOrderRecalculateRequest = createAction('[Online order] Request recalculate');

export const OnlineOrderRecalculateSuccessRequest = createAction(
    '[Online order] Success request recalculate',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderRecalculateErrorRequest = createAction(
    '[Online order] Error request recalculate',
    props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel; ex?: any; }>(),
);

/* for Voucher */
export const OnlineOrderAddVoucherRequest = createAction(
    '[Online order] Request add voucher',
    props<{ code: string; id: number;}>(),
);

export const OnlineOrderAddVoucherSuccessRequest = createAction(
    '[Online order] Success request add voucher',
    props<{ code: string; payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderAddVoucherErrorRequest = createAction(
    '[Online order] Error request add voucher',
    props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel; ex?: any; staticText: T.StaticTexts; }>(),
);

export const OnlineOrderVoucherErrorReset = createAction(
    '[Online order] Voucher error reset'
);

export const OnlineOrderRemoveVoucherRequest = createAction(
    '[Online order] Request remove voucher'
);

export const OnlineOrderRemoveVoucherSuccessRequest = createAction(
    '[Online order] Success request remove voucher',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const OnlineOrderRemoveVoucherErrorRequest = createAction(
    '[Online order] Error request remove voucher',
    props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel; ex?: any; }>(),
);

export const OnlineOrderClearVoucherRequest = createAction(
    '[Online order] Reset request voucher'
);

/* for sending email confirmation */
export const OnlineOrderSendConfrimationEmailRequest = createAction(
    '[Online order] send email confirmation for online order',
    props<{ orderId: number; }>(),
);

export const OnlineOrderSendConfrimationEmailSuccessRequest = createAction(
    '[Online order] send email confirmation for online order - success',
    props<{ result: boolean; }>(),
);

export const OnlineOrderSendConfrimationEmailErrorRequest = createAction(
    '[Online order] send email confirmation for online order - error',
    props<{ ex?: any; }>(),
);

/* Order type select */
export const OnlineOrderTypeSelect = createAction(
    '[Online order] Select order type',
    props<{ orderType: APICommon.OrderTypeExtended; }>(),
);

export const OnlineOrderTypeUpdateValues = createAction(
    '[Online order] Update order type values',
    props<{ details: APICommon.OrderTypeDetailDefinitionExtended[]; disclaimers?: APICommon.OrderTypeDisclaimerDefinitionExtended[]; }>()
);

export const OnlineOrderResetOrderTypeValues = createAction(
    '[Online order] Reset order type values'
);

/* Send email receipt */
export const OnlineOrderSendEmailReceiptRequest = createAction(
    '[Online order] Request send email receipt',
    props<{ orderId: number; }>()
);

export const OnlineOrderSendEmailReceiptSuccessRequest = createAction(
    '[Online order] Success Request send email receipt',
    props<{ orderId: number; }>()
);

export const OnlineOrderSendEmailReceiptSuccessReset = createAction(
    '[Online order] Reset after email sent successfuly'
);

export const OnlineOrderSendEmailReceiptErrorRequest = createAction(
    '[Online order] Error Request send email receipt',
    props<{ orderId: number; ex?: any; }>()
);

export const OnlineOrderSendEmailReceiptReset = createAction(
    '[Online order] Reset send email receipt'
);

export const OnlineOrderSaveConfirmationUrlRequest = createAction(
    '[Online order] Request save request url',
    props<{ orderId: number; url: string; }>()
);

export const OnlineOrderSaveConfirmationUrlSuccessRequest = createAction(
    '[Online order] Success Request save request url',
    props<{ orderId: number; url: string; payload: OLO.DTO.OnlineOrderUrlBusinessModel; }>()
);

export const OnlineOrderSaveConfirmationUrlErrorRequest = createAction(
    '[Online order] Error Request save request url',
    props<{ orderId: number; url: string; ex?: any; }>()
);

export const OnlineDeliveryAddressSet = createAction(
    '[Online order] Delivery Address Set',
    props<{ address: Nullable<OLO.DTO.OnlineOrderDeliveryDetailsBusinessModel>; }>()
);

export const OnlineOrderCreateDuplicationCheckForCard = createAction(
    '[Online order] Duplication check for card',
    props<{
        model: OLO.DTO.OnlineOrderDetailedBusinessModel;
        previousOrderInfo: OLO.DTO.OnlineOrdersPreviousOrder;
    }>(),
);

export const OnlineOrderCreateDuplicationCheckForPayWithAccountCharge = createAction(
    '[Online order] Duplication check for pay with account charge',
    props<{
        model: OLO.DTO.OnlineOrderDetailedBusinessModel;
        previousOrderInfo: OLO.DTO.OnlineOrdersPreviousOrder;
    }>(),
);
