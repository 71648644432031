import { createSelector } from '@ngrx/store';
import { getOrderSummary } from '../../../onlineOrder/selectors';
import { getAccountBalance } from '../../../members/selectors';
import { isAccountChargeSelected } from '../../../creditCards/selectors';

export const isPaymentDisabledForAccountCharge = createSelector(
    getAccountBalance,
    getOrderSummary,
    isAccountChargeSelected,
    (accountBalance, orderSummary, accountChargeSelected) => {
        const total = orderSummary?.Total;
        const availableBalance = accountBalance.data?.AvailableBalance;

        return accountChargeSelected && total != null && availableBalance != null && availableBalance < total;
    },
);
