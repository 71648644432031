import { createAction } from '@ngrx/store';

export const OnlineMenuFiltersReset = createAction('[OnlineMenu filters] Reset');

export const OnlineMenuFiltersSetSearch = createAction(
    '[Online Menu filter] Set search string',
    (search: string = null) => ({ search }),
);

export const OnlineMenuFiltersSetLocationNo = createAction(
    '[Online Menu filter] Set location number',
    (locationNo: number = null) => ({ locationNo }),
);
