import { createSelector } from '@ngrx/store';
import { locationsState } from '../root';

import * as Models from '@shared/core/models';

/**
 * Gets location order type labels
 * @param {number} locationNo
 * @returns {Models.Label[]} array of orderType labels
 */
export const getLocationOrderTypeLabels = (locationNo: number) =>
    createSelector(
        locationsState,
        (locations) => {
            const location = locations?.data?.find((obj) => obj.LocationNo === locationNo);
            if (!location) return null;

            return location.OrderTypes?.map((obj) => new Models.Label(obj.Id, obj.TypeDescription, true)) || null;
        }
    );
