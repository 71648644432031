import { Model } from '../model';

type Props<TCtx, RCtx = any> = {
    id: OLO.Components.SubNavId;
    name: string;
    url: Nullable<string>;
    counter: Nullable<number>;
    imgUrl: Nullable<string>;
    imgBgColor: Nullable<string>;
    accentColor: Nullable<string>;
    parentId: Nullable<OLO.Components.SubNavId>;
    isVirtualLocation: boolean;
    items: OLO.Components.SubNavItem<RCtx>[];
    ctx: Nullable<TCtx>;
};

export class SubNav<TCtx, RCtx = any> extends Model<OLO.Components.SubNavItem<TCtx, RCtx>> {
    private _ctx: Nullable<TCtx> = null;

    constructor(private _input: Props<TCtx>) {
        super();
        const { id, name, url, counter, imgUrl, imgBgColor, accentColor, parentId, isVirtualLocation, items, ctx } = this._input;

        this._model.Id = id;
        this._model.Name = name;
        this._model.Url = url;
        this._model.Counter = counter;
        this._model.ImgUrl = imgUrl;
        this._model.ImgBgColor = imgBgColor;
        this._model.AccentColor = accentColor;
        this._model.ParentId = parentId;
        this._model.IsVirtualLocation = isVirtualLocation;
        this._model.items = items as Props<TCtx, RCtx>['items'];
        this._ctx = ctx ?? null;
    }

    public get ctx() {
        return this._ctx;
    }

    public get Id() {
        return this._model.Id;
    }

    public get Name() {
        return this._model.Name;
    }

    public get Url() {
        return this._model.Url;
    }

    public get Counter() {
        return this._model.Counter;
    }

    public get ImgUrl() {
        return this._model.ImgUrl;
    }

    public get ImgBgColor() {
        return this._model.ImgBgColor;
    }

    public get AccentColor() {
        return this._model.AccentColor;
    }

    public get ParentId() {
        return this._model.ParentId;
    }

    public get IsVirtualLocation() {
        return this._model.IsVirtualLocation;
    }

    public get items() {
        return this._model.items;
    }
}
