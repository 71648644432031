import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';


import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    private readonly _resetOnlineMenuSearchTermFilterListener: Subject<boolean> = new Subject();
    private readonly _resetOnlineMenuLocationNoFilterListener: Subject<boolean> = new Subject();

    constructor(
        public store: Store<OLO.State>
    ) {
    }

    public resetOnlineMenuSearchTermFilter(): void {
        this.store.dispatch(actions.OnlineMenuFiltersSetSearch(null));

        this._resetOnlineMenuSearchTermFilterListener.next(true);
    }

    public get resetOnlineMenuSearchFilter$(): Subject<boolean> {
        return this._resetOnlineMenuSearchTermFilterListener;
    }

    public resetOnlineMenuLocationNoFilter(): void {
        this.store.dispatch(actions.OnlineMenuFiltersSetLocationNo(null));

        this._resetOnlineMenuLocationNoFilterListener.next(true);
    }

    public get resetOnlineMenuLocationNoFilter$(): Subject<boolean> {
        return this._resetOnlineMenuLocationNoFilterListener;
    }
}
