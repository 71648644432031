import { Injectable, Inject } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, never, of, forkJoin } from 'rxjs';
import { switchMap, tap, mergeMap, withLatestFrom, take, map, filter, catchError } from 'rxjs/operators';

@Injectable()
export class ReorderEffects {
    private _activeModalsWithReordersRegistry: Map<number, number> = new Map();

    @Effect() public setupModalAndRequestDataForReorder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ReorderSetup
            ),
            tap(action => this._onlineOrdersService.reorderInitCalculations(action.orderId)),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCurrentPickupTime)
                    )
            ),
            switchMap(([action, pickupTime]) => {
                const modalId = action.modalId || new Date().getTime();
                this._modalsService.show({
                    type: 'loading',
                    id: modalId,
                    orderId: action.orderId,
                    locationNo: action.locationNo,
                });

                this._activeModalsWithReordersRegistry.set(modalId, action.orderId);

                return this._store
                    .pipe(
                        select(selectors.getReorder(action.orderId, action.locationNo, pickupTime)),
                        filter(reorder => reorder !== null && reorder !== undefined && reorder.isDownloading === false),
                        take(1),
                        switchMap(reorder => {
                            if (reorder.hasFailed || !reorder.data) {
                                console.error('There was an error during reorder calculations', reorder);

                                return never();
                            }

                            this._modalsService.swap(modalId, {
                                type: 'reorder'
                            });

                            return never();
                        })
                    );
            }),
        );

    @Effect() public requestDataForReorder$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.ReorderCalculateRequest),
            switchMap(action => this._store.select(selectors.getReorder(action.orderId, action.locationNo, action.pickupTime))
                .pipe(
                    take(1),
                    withLatestFrom(
                        this._store.select(selectors.getCurrentMember),
                        this._store.select(selectors.getCurrentPickupTime),
                        this._store.select(selectors.getHistoryOrderByOrderId(action.orderId)),
                        this._store.select(selectors.getOrderTypeId),
                    ),
                    switchMap(([, member, currentPickupTime, order, currentOrderTypeId]) => {
                        if (!member || !currentPickupTime) {
                            console.error(`Error in reorder recalc request. Member: ${member}, CurrentPickupTime ${currentPickupTime} - one of these params is not set`);

                            return of(actions.ReorderCalculateErrorRequest({
                                orderId: action.orderId,
                                locationNo: action.locationNo,
                                pickupTime: action.pickupTime,
                                ex: new Error(`Error in reorder recalc request. Member: ${member}, CurrentPickupTime ${currentPickupTime} - one of these params is not set`)
                            }));
                        }

                        const onlineMenuRequestParams: APICommon.OnlineMenuGetParams = {
                            orderTypeId: currentOrderTypeId,
                            locationNo: action.locationNo,
                            includePrices: true,
                            memberId: member.MemberId,
                            menuDate: currentPickupTime.DateLocalISO + 'Z',
                        };
                        const orderVirtualLocationNo = order.data?.VirtualLocations?.[0]?.LocationNo;
                        const orderAllProducts = [...order.data.MenuFlowActivations, ...order.data.Items];

                        const virtualLocationsNosFromOrder: number[] = orderVirtualLocationNo
                            ? [orderVirtualLocationNo]
                            : orderAllProducts.reduce((acc, item) => {
                                const itemVirtualLocationNo = item.VirtualLocations?.[0]?.LocationNo;
                                if (item.VirtualLocations?.length > 0 && !acc.includes(itemVirtualLocationNo)) {
                                    return [...acc, itemVirtualLocationNo];
                                }

                                return acc;
                            }, []);

                        return this._store.pipe(
                            select(selectors.getVirtualLocationsForCurrentLocation),
                            switchMap(virtualLocations => {
                                const physicalLocationOnlineMenuRequest = this._onlineMenuService.getMenuPages(onlineMenuRequestParams).pipe(
                                    catchError(() => of(null))
                                );

                                const virtualLocationsFiltered = virtualLocations?.filter(virtualLocation => virtualLocationsNosFromOrder.includes(virtualLocation.LocationNo)
                                    && virtualLocation.OrderTypes.some(orderType => orderType.Id === currentOrderTypeId)) || [];
                                const virtualLocationOnlineMenuRequests = virtualLocationsFiltered
                                    .map(virtualLocation => this._onlineMenuService.getMenuPages({
                                        ...onlineMenuRequestParams,
                                        virtualLocationNo: virtualLocation.LocationNo,
                                    }));

                                return forkJoin([...virtualLocationOnlineMenuRequests, physicalLocationOnlineMenuRequest]).pipe(
                                    map(onlineMenus => onlineMenus.filter(onlineMenu => !!onlineMenu))
                                );
                            }),
                            take(1),
                            switchMap(allOnlineMenus => {
                                const summary: OLO.State.Reorder.ReorderDetails = {
                                    onlineMenu: allOnlineMenus.find(onlineMenu => !onlineMenu?._VirtualLocationNo) ?? null,
                                    virtualLocationOnlineMenus: allOnlineMenus.filter(onlineMenu => !!onlineMenu?._VirtualLocationNo) ?? null,
                                    menuFlowsDetails: [],
                                    ingredients: [],

                                    errors: null,
                                    cart: null,
                                };

                                /* Get details about each menuFlow */
                                let relevantMenuFlows: Observable<OLO.DTO.MenuFlowDetailsModel>[] = [];

                                allOnlineMenus.forEach(onlineMenu => {
                                    const orderHasAssignedVirtualLocation = onlineMenu?._VirtualLocationNo === order.data.VirtualLocations?.[0]?.LocationNo;
                                    const menuFlowsFilteredByVirtualLocationNo = order.data.MenuFlowActivations.filter(menuFlowActivation => {
                                        const menuFlowHasNotAssignedVirtualLocation = onlineMenu?._VirtualLocationNo == null &&
                                            menuFlowActivation.VirtualLocations === null;
                                        const menuFlowHasAssignedVirtualLocation = menuFlowActivation.VirtualLocations &&
                                        menuFlowActivation.VirtualLocations?.[0]?.LocationNo === onlineMenu?._VirtualLocationNo;

                                        return menuFlowHasNotAssignedVirtualLocation || menuFlowHasAssignedVirtualLocation || orderHasAssignedVirtualLocation;
                                    });

                                    const currentRelevantMenuFlows: Observable<OLO.DTO.MenuFlowDetailsModel>[] = menuFlowsFilteredByVirtualLocationNo
                                        .map(menuFlowActivation => onlineMenu?.Pages.reduce((request, Page) => {
                                            const requestValue = Page.Products.reduce((menuFlowDetailsRequest, Product) => {
                                                if (Product.MenuFlowId && menuFlowActivation.MenuFlowId === Product.MenuFlowId) {
                                                    return this._menuFlowsService.getMenuFlowDetailsForLocation(
                                                        Product.MenuFlowId, action.locationNo
                                                    );
                                                }

                                                return menuFlowDetailsRequest;
                                            }, null as Observable<OLO.DTO.MenuFlowDetailsModel>);

                                            if (requestValue) {
                                                request = requestValue;
                                            }

                                            return request;
                                        }, null as Observable<OLO.DTO.MenuFlowDetailsModel>))
                                        .filter(relevantMenuFlow => !!relevantMenuFlow);
                                    relevantMenuFlows = [...relevantMenuFlows, ...currentRelevantMenuFlows];
                                });

                                if (!relevantMenuFlows.length) {
                                    if (this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false) {
                                        summary.ingredients = [];
                                    }

                                    const finalPayload = Utils.OnlineOrders
                                        .covertOrderToCart(order.data, allOnlineMenus, [], [], currentPickupTime);

                                    return [actions.ReorderCalculateSuccessRequest({
                                        orderId: action.orderId,
                                        locationNo: action.locationNo,
                                        pickupTime: action.pickupTime,
                                        payload: {
                                            ...summary,
                                            cart: finalPayload.cart,
                                            errors: finalPayload.errors,
                                        }
                                    })];
                                }

                                return forkJoin(relevantMenuFlows).pipe(
                                    mergeMap((menuFlowsDetails) => {
                                        summary.menuFlowsDetails = [...summary.menuFlowsDetails, ...menuFlowsDetails];
                                        /* Get details about upsells */
                                        const upsellMenuflowsRequests = [];

                                        menuFlowsDetails.forEach((MenuFlow: OLO.DTO.MenuFlowDetailsModel) => {
                                            if (MenuFlow.UpsellMenuFlowId) {
                                                upsellMenuflowsRequests.push(
                                                    this._menuFlowsService.getMenuFlowDetailsForLocation(
                                                        MenuFlow.UpsellMenuFlowId,
                                                        action.locationNo
                                                    )
                                                );
                                            }
                                        });

                                        return (upsellMenuflowsRequests.length ? forkJoin(upsellMenuflowsRequests) : of([]))
                                            .pipe(
                                                mergeMap(upsellsMenuFlowDetails => {
                                                    summary.menuFlowsDetails = [
                                                        ...summary.menuFlowsDetails,
                                                        ...upsellsMenuFlowDetails as OLO.DTO.MenuFlowDetailsModel[],
                                                    ];

                                                    // Get ingredients for all products in menuflows
                                                    let productIds = [];

                                                    summary.menuFlowsDetails.forEach(MenuFlow => {
                                                        const currentMenuFlowProductIds = this._menuFlowsService
                                                            .getFlatProductsFromMenuFlow(MenuFlow).map(Product => Product.ProductId);
                                                        productIds = [...productIds, ...currentMenuFlowProductIds];
                                                    });
                                                    const unduplicatedProductIds = Array.from(new Set(productIds));

                                                    // Warning - when there are too many IDS, API endpoint will return 404 ERROR. This might need some refactor

                                                    return this._productsService.getIngredientsForLocationExtended(
                                                        action.locationNo,
                                                        ...unduplicatedProductIds
                                                    )
                                                        .pipe(
                                                            tap(ingredients => {
                                                                summary.ingredients = [
                                                                    ...summary.ingredients,
                                                                    ...ingredients
                                                                ];

                                                                if (this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false) {
                                                                    summary.ingredients = [];
                                                                }
                                                            })
                                                        );

                                                })
                                            );
                                    }),
                                    map(() => {
                                        const finalPayload: OLO.Ordering.OnlineOrder2CartConverter = Utils.OnlineOrders.covertOrderToCart(
                                            order.data,
                                            allOnlineMenus,
                                            summary.menuFlowsDetails,
                                            summary.ingredients,
                                            currentPickupTime
                                        );

                                        return actions.ReorderCalculateSuccessRequest({
                                            orderId: action.orderId,
                                            locationNo: action.locationNo,
                                            pickupTime: action.pickupTime,
                                            payload: {
                                                ...summary,
                                                cart: finalPayload.cart,
                                                errors: finalPayload.errors,
                                            }
                                        });
                                    })
                                );
                            })
                        );
                    })
                )
            )
        );

    @Effect() public cleanupAfterReorderModalsIsClosed$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.ModalClose,
                actions.ModalCloseAll,
            ),
            switchMap(action => {
                if (action.type === actions.ModalClose.type) {
                    const orderId = this._activeModalsWithReordersRegistry.get(action.id);

                    if (orderId) {
                        return of(actions.ReorderUnmount({ orderId }));
                    }

                    return never();
                }

                this._activeModalsWithReordersRegistry.clear();

                return of(actions.ReorderUnmount({}));
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _onlineMenuService: Services.OnlineMenuService,
        private _menuFlowsService: Services.MenuFlowsService,
        private _productsService: Services.ProductsService,
        private _modalsService: Services.ModalsService,
        private _onlineOrdersService: Services.OnlineOrdersService,
    ) {
    }
}
