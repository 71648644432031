import { createSelector } from '@ngrx/store';

import { isGuestModeEnabled } from '../isGuestModeEnabled';
import { isMemberAuthorizedJWT } from '../isMemberAuthorizedJWT';

export const isGuestMember = createSelector(
    isGuestModeEnabled,
    isMemberAuthorizedJWT,
    (guestModeEnabled, isAuthorized) => guestModeEnabled && !isAuthorized
);
