import { createSelector } from '@ngrx/store';
import { creditCardsState } from '../root';

export const shouldShowCreditCardForm = createSelector(
    creditCardsState,
    creditCards => {
        const activeId: OLO.CreditCards.CreditCardId = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;
        const cards: OLO.Members.MemberCreditCardDetails[] = creditCards.data;

        if(
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.CARD_TOKENIZATION_UPON_ORDER
        ) return false;

        /* Don't reset form manualy */
        if (cards?.length !== 0 && creditCards.validation.hasFailed === true) return false;

        if (!activeId && !activeToken || !cards || cards.length === 0) return true;

        if (activeId) {
            const foundCard = cards.find(obj => obj.Id === activeId);

            return foundCard ? false : true;
        }

        if (!activeToken) {
            return true;
        }

        const card = cards.find(obj => {
            if('Token' in obj && obj.Token != null) {
                return obj.Token === activeToken;
            }

            return false;
        });

        return card ? false : true;

    }
);
