import { CreditCard } from './credit-card.model';

export class CreditCardBuilder {
    public paymentProvider: OLO.Enums.PAYMENT_PROVIDER = null;
    public number: string = null;
    public type: OLO.Enums.CREDIT_CARD_TYPES = null;
    public expiryDate: string = null;
    public token: string = null;
    public isDefault: boolean = false;
    public saveCard: boolean = false;
    public id: number | string = null;
    public cvv: Nullable<string> = null;
    public cardHolderName: Nullable<string> = null;
    public validationStatus: OLO.Types.VALIDATION_STATUS = null;
    public locationNo: number = null;
    public fatZebraToken: OLO.DTO.NullableFatZebraTokenVerification = null;
    public adyenPaymentData: Adyen.PaymentData = null;
    public stripePaymentData: OLO.DTO.StripeResponsePaymentMethod = null;
    public billingDetails: Nullable<OLO.CreditCards.CreditCardBillingDetails> = null;

    public setBillingDetails(billingDetails: Nullable<OLO.CreditCards.CreditCardBillingDetails>) {
        if (!billingDetails) {
            this.billingDetails = null;
        } else {
            this.billingDetails = {
                Street: billingDetails.Street,
                HouseNumber: billingDetails.HouseNumber,
                City: billingDetails.City,
                PostalCode: billingDetails.PostalCode,
                StateOrProvince: billingDetails.StateOrProvince,
                CountryIso2Code: billingDetails.CountryIso2Code,
            };
        }

        return this;
    }

    public setCvv(cvv: string) {
        this.cvv = cvv;

        return this;
    }

    public setCardHolderName(cardHolderName: string) {
        this.cardHolderName = cardHolderName;

        return this;
    }

    public setPaymentProvider(paymentProvider: OLO.Enums.PAYMENT_PROVIDER) {
        this.paymentProvider = paymentProvider;

        return this;
    }

    public setNumber(number: string) {
        this.number = number;

        return this;
    }

    public setType(type: OLO.Enums.CREDIT_CARD_TYPES) {
        if (this.type === undefined) {
            return this;
        }

        this.type = type;

        return this;
    }

    public setExpiryDate(expiryDate: string) {
        this.expiryDate = expiryDate;

        return this;
    }

    public setToken(token: string) {
        this.token = token;

        return this;
    }

    public setIsDefault(isDefault: boolean) {
        this.isDefault = isDefault;

        return this;
    }

    public setSaveCard(saveCard: boolean) {
        this.saveCard = saveCard;

        return this;
    }

    public setId(id: number | string) {
        this.id = id;

        return this;
    }

    public setValidationStatus(validationStatus: OLO.Types.VALIDATION_STATUS) {
        this.validationStatus = validationStatus;

        return this;
    }

    public setLocationNo(locationNo: number) {
        this.locationNo = locationNo;

        return this;
    }

    public setFatZebraToken(r: number, v: string) {
        this.fatZebraToken = {
            r,
            v,
        };

        return this;
    }

    public setAdyenPaymentData(adyenPaymentData: Adyen.PaymentData = null) {
        this.adyenPaymentData = adyenPaymentData;

        return this;
    }

    public setStripePaymentData(stripePaymentData: OLO.DTO.StripeResponsePaymentMethod = null) {
        this.stripePaymentData = stripePaymentData;

        return this;
    }

    public build(): CreditCard {
        return new CreditCard(
            this.cardHolderName,
            this.paymentProvider,
            this.number,
            this.cvv,
            this.type,
            this.expiryDate,
            this.token,
            this.isDefault,
            this.saveCard,
            this.validationStatus,
            this.locationNo,
            this.id,
            this.fatZebraToken,
            this.adyenPaymentData,
            this.stripePaymentData,
            this.billingDetails,
        );
    }
}
