import * as Utils from '@shared/core/utils';

export class OnlineOrdersMapper {
    public static mapCreateNewOnlineOrderPOSTRequest(data: OLO.DTO.OnlineOrderDetailedBusinessModel): APIv3.OnlineOrderDetailedBusinessModel {
        return data
            ? {
                MemberFirstName: data.MemberFirstName,
                MemberLastName: data.MemberLastName,
                MemberMobilePhone: data.MemberMobilePhone,
                MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
                MemberHomePhone: data.MemberHomePhone,
                MemberEmail: data.MemberEmail,
                ExtraInstructions: data.ExtraInstructions,
                Barcode: data.Barcode,
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax: APIv3.TaxValue) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: APIv3.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: APIv3.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                        VirtualLocations: item.VirtualLocations ? item.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias: APIv3.OnlineOrderMediaModel) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: APIv3.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        VirtualLocations: menuflow.VirtualLocations ? menuflow.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null,
                        TotalValue: menuflow.TotalValue,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item: APIv3.OnlineOrderMenuFlowItem) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                                TotalValueInit: item.TotalValueInit,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: APIv3.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: APIv3.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: APIv3.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: APIv3.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: APIv3.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: APIv3.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
                VirtualLocations: data.VirtualLocations ? data.VirtualLocations.map(virtualLocation => ({
                    LocationNo: virtualLocation.LocationNo,
                    LocationClassification: virtualLocation.LocationClassification,
                    LocationFriendlyName: virtualLocation.LocationFriendlyName
                })) : null
            }
            : null;
    }

    public static mapCreateNewOnlineOrderPOSTResponse(data: APIv3.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        return data
            ? {
                MemberFirstName: data.MemberFirstName,
                MemberLastName: data.MemberLastName,
                MemberMobilePhone: data.MemberMobilePhone,
                MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
                MemberHomePhone: data.MemberHomePhone,
                MemberEmail: data.MemberEmail,
                ExtraInstructions: data.ExtraInstructions,
                Barcode: data.Barcode,
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                        VirtualLocations: item.VirtualLocations ? item.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        TotalValue: menuflow.TotalValue,
                        TotalValueInitial: menuflow.TotalValueInitial,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
                VirtualLocations: data.VirtualLocations ? data.VirtualLocations.map(virtualLocation => ({
                    LocationNo: virtualLocation.LocationNo,
                    LocationClassification: virtualLocation.LocationClassification,
                    LocationFriendlyName : virtualLocation.LocationFriendlyName
                })) : null
            }
            : null;
    }

    public static mapSendEmailWithOrderConfirmationPOSTRequest(data: { LoyaltyMobileAppId: null; OnlineOrderId: number; }): APIv3.OnlineOrderEmailConfirmationRequestModel {
        return data
            ? {
                OnlineOrderId: data.OnlineOrderId,
                LoyaltyMobileAppId: null,
            }
            : null;
    }

    public static mapSendEmailWithOrderConfirmationPOSTResponse(data: APIv3.OnlineOrdersSendOnlineOrderConfirmationEmail.Responses.$200): boolean {
        return data;
    }

    public static mapAddVoucherOnlineOrderPOSTRequest(data: OLO.DTO.ActivateVoucherRequest): APIv3.ActivateVoucherRequest {
        return data
            ? {
                Order: data.Order
                    ? {
                        Id: data.Order.Id,
                        Source: data.Order.Source,
                        SaleName: data.Order.SaleName,
                        PickupLocation: data.Order.PickupLocation,
                        PickUpDate: data.Order.PickUpDate,
                        OrderedDate: data.Order.OrderedDate,
                        TableNumber: data.Order.TableNumber,
                        IsDelivery: data.Order.IsDelivery,
                        Status: data.Order.Status,
                        MemberId: data.Order.MemberId,
                        Covers: data.Order.Covers,
                        AddedDate: data.Order.AddedDate,
                        ExtraInstructions: data.Order.ExtraInstructions,
                        DisplayNumber: data.Order.DisplayNumber,
                        PartialMember: data.Order.PartialMember
                            ? {
                                FirstName: data.Order.PartialMember.FirstName,
                                LastName: data.Order.PartialMember.LastName,
                                Email: data.Order.PartialMember.Email,
                                MobileNumber: data.Order.PartialMember.MobileNumber,
                                MobileNumberCountryID: data.Order.PartialMember.MobileNumberCountryID,
                            }
                            : null,
                        ClerkId: data.Order.ClerkId,
                        OnlineOrderType: data.Order.OnlineOrderType,
                        MasterOrderID: data.Order.MasterOrderID,
                        SendToKMS: data.Order.SendToKMS,
                        SendToPrinters: data.Order.SendToPrinters,
                        TotalNettValue: data.Order.TotalNettValue,
                        TotalNettValueBeforeSaleDiscount: data.Order.TotalNettValueBeforeSaleDiscount,
                        TotalGrossValue: data.Order.TotalGrossValue,
                        TotalSaleValueInitial: data.Order.TotalSaleValueInitial,
                        TotalSaleValueFinal: data.Order.TotalSaleValueFinal,
                        TotalTaxes: data.Order.TotalTaxes
                            ? data.Order.TotalTaxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        TotalLeftToPay: data.Order.TotalLeftToPay,
                        TotalSaleDiscountAppliedAmount: data.Order.TotalSaleDiscountAppliedAmount,
                        TotalPaid: data.Order.TotalPaid,
                        OrderTypeId: data.Order.OrderTypeId,
                        TransactionId: data.Order.TransactionId,
                        IsRated: data.Order.IsRated,
                        SendReceiptOnEmail: data.Order.SendReceiptOnEmail,
                        LoyaltyAppId: data.Order.LoyaltyAppId,
                        ReceiptNotificationEmailAdresses: data.Order.ReceiptNotificationEmailAdresses,
                        FavouriteId: data.Order.FavouriteId,
                        POSPrepaid: data.Order.POSPrepaid,
                        Items: data.Order.Items
                            ? data.Order.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                                FreeProductID: item.FreeProductID,
                                Id: item.Id,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                OrderId: item.OrderId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                InitialQuantity: item.InitialQuantity,
                                ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                                Type: item.Type,
                                Value: item.Value,
                                InitialUnitPrice: item.InitialUnitPrice,
                                UnitPrice: item.UnitPrice,
                                RedeemedProductId: item.RedeemedProductId,
                                IsRedeemedByPoints: item.IsRedeemedByPoints,
                                PointsValue: item.PointsValue,
                                Modifiers: item.Modifiers
                                    ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                        Id: modifier.Id,
                                        OnlineOrderId: modifier.OnlineOrderId,
                                        OnlineOrderItemId: modifier.OnlineOrderItemId,
                                        RecipeId: modifier.RecipeId,
                                        NewPlu: modifier.NewPlu,
                                        OldPlu: modifier.OldPlu,
                                        NewQty: modifier.NewQty,
                                        OldQty: modifier.OldQty,
                                    }))
                                    : null,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((tax) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((tax) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                SpecialInstructions: item.SpecialInstructions,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                Discount: item.Discount
                                    ? {
                                        OnlineOrderId: item.Discount.OnlineOrderId,
                                        OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                        Value: item.Discount.Value,
                                        DiscountType: item.Discount.DiscountType,
                                        AmountApplied: item.Discount.AmountApplied,
                                        DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                                    }
                                    : null,
                                VoucherId: item.VoucherId,
                                VoucherCode: item.VoucherCode,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        Medias: data.Order.Medias
                            ? data.Order.Medias.map((medias) => ({
                                Id: medias.Id,
                                CustomerFriendlyName: medias.CustomerFriendlyName,
                                MediaDescription: medias.MediaDescription,
                                MediaId: medias.MediaId,
                                OrderId: medias.OrderId,
                                Value: medias.Value,
                                PaymentType: medias.PaymentType,
                                PaymentTransactionId: medias.PaymentTransactionId,
                                IsTax: medias.IsTax,
                            }))
                            : null,
                        MenuFlowActivations: data.Order.MenuFlowActivations
                            ? data.Order.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                                Id: menuflow.Id,
                                OnlineOrderId: menuflow.OnlineOrderId,
                                MenuFlowId: menuflow.MenuFlowId,
                                ActivationGuid: menuflow.ActivationGuid, // uuid
                                Value: menuflow.Value,
                                SaleValueInitial: menuflow.SaleValueInitial,
                                InitialUnitPrice: menuflow.InitialUnitPrice,
                                TotalValue: menuflow.TotalValue,
                                TotalValueInitial: menuflow.TotalValueInitial,
                                MenuFlowItems: menuflow.MenuFlowItems
                                    ? menuflow.MenuFlowItems.map((item) => ({
                                        Id: item.Id,
                                        OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                        OnlineOrderItemId: item.OnlineOrderItemId,
                                        MenuFlowPageId: item.MenuFlowPageId,
                                        PLU: item.PLU,
                                        Quantity: item.Quantity,
                                        Type: item.Type,
                                        Value: item.Value,
                                        DisplayName: item.DisplayName,
                                        DisplayDescription: item.DisplayDescription,
                                        UnitPrice: item.UnitPrice,
                                        SpecialInstructions: item.SpecialInstructions,
                                        IngredientsChanges: item.IngredientsChanges
                                            ? {
                                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                        ID: ingredientsModified.ID,
                                                        OrderItemId: ingredientsModified.OrderItemId,
                                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                                        ModifierID: ingredientsModified.ModifierID,
                                                        ModifierName: ingredientsModified.ModifierName,
                                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                                        TaxBase: ingredientsModified.TaxBase,
                                                        Taxes: ingredientsModified.Taxes
                                                            ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                                TaxID: ingredientsTax.TaxID,
                                                                Value: ingredientsTax.Value,
                                                                IsInclusive: ingredientsTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsModified.GrossValue,
                                                        NettValue: ingredientsModified.NettValue,
                                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                                    }))
                                                    : null,
                                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                        ID: ingredientsRemoved.ID,
                                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                                    }))
                                                    : null,
                                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                        ID: ingredientsAdded.ID,
                                                        OrderItemId: ingredientsAdded.OrderItemId,
                                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                                        ModifierID: ingredientsAdded.ModifierID,
                                                        ModifierName: ingredientsAdded.ModifierName,
                                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                                        TaxBase: ingredientsAdded.TaxBase,
                                                        Taxes: ingredientsAdded.Taxes
                                                            ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                                TaxID: ingredientsAddedTax.TaxID,
                                                                Value: ingredientsAddedTax.Value,
                                                                IsInclusive: ingredientsAddedTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsAdded.GrossValue,
                                                        NettValue: ingredientsAdded.NettValue,
                                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                                    }))
                                                    : null,
                                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                        ID: ingredientsSwapped.ID,
                                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                        ModifierID: ingredientsSwapped.ModifierID,
                                                        ModifierName: ingredientsSwapped.ModifierName,
                                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                        TaxBase: ingredientsSwapped.TaxBase,
                                                        Taxes: ingredientsSwapped.Taxes
                                                            ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                                TaxID: ingredientsSwappedTax.TaxID,
                                                                Value: ingredientsSwappedTax.Value,
                                                                IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsSwapped.GrossValue,
                                                        NettValue: ingredientsSwapped.NettValue,
                                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                                    }))
                                                    : null,
                                            }
                                            : null,
                                        IsHiddenFromUser: item.IsHiddenFromUser,
                                        TaxBase: item.TaxBase,
                                        Taxes: item.Taxes
                                            ? item.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: item.GrossValue,
                                        NettValue: item.NettValue,
                                        SaleValueInitial: item.SaleValueInitial,
                                        SaleValueFinal: item.SaleValueFinal,
                                    }))
                                    : null,
                                DisplayName: menuflow.DisplayName,
                                DisplayDescription: menuflow.DisplayDescription,
                                Quantity: menuflow.Quantity,
                                InitialQuantity: menuflow.InitialQuantity,
                                UnitPrice: menuflow.UnitPrice,
                                SpecialInstructions: menuflow.SpecialInstructions,
                                IsUpsell: menuflow.IsUpsell,
                                Discount: menuflow.Discount
                                    ? {
                                        OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                        OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                        Value: menuflow.Discount.Value,
                                        DiscountType: menuflow.Discount.DiscountType,
                                        AmountApplied: menuflow.Discount.AmountApplied,
                                    }
                                    : null,
                            }))
                            : null,
                        OnlineDiscounts: data.Order.OnlineDiscounts
                            ? data.Order.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                                Id: item.Id,
                                OnlineOrderId: item.OnlineOrderId,
                                Value: item.Value,
                                DiscountType: item.DiscountType,
                                AmountApplied: item.AmountApplied,
                                VoucherCode: item.VoucherCode,
                            }))
                            : null,
                        ExternalPartners: data.Order.ExternalPartners
                            ? data.Order.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                                CustomerFacingMessage: item.CustomerFacingMessage,
                                OrderID: item.OrderID,
                                Slug: item.Slug,
                                MemberID: item.MemberID,
                                PriceLevelID: item.PriceLevelID,
                            }))
                            : null,
                        Surcharges: data.Order.Surcharges
                            ? data.Order.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                                OnlineOrderId: item.OnlineOrderId,
                                OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                                CustomerFriendlyName: item.CustomerFriendlyName,
                                CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                                DisplayIndex: item.DisplayIndex,
                                IsVisibleForCustomer: item.IsVisibleForCustomer,
                                ProductId: item.ProductId,
                                PLU: item.PLU,
                                Value: item.Value,
                                SurchargeType: item.SurchargeType,
                                SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                                MediaNo: item.MediaNo,
                                AmountApplied: item.AmountApplied
                            }))
                            : null,
                        OrderTypeDetails: data.Order.OrderTypeDetails
                            ? data.Order.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                                OnlineOrderId: item.OnlineOrderId,
                                DetailsDefinitionId: item.DetailsDefinitionId,
                                CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                                ValueProvided: item.ValueProvided,
                            }))
                            : null,
                        OrderTypeDisclaimers: data.Order.OrderTypeDisclaimers
                            ? data.Order.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                                Id: item.Id,
                                OrderId: item.OrderId,
                                OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                                CustomerFriendlyName: item.CustomerFriendlyName,
                                CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                                DisplayIndex: item.DisplayIndex,
                                IsAccepted: item.IsAccepted,
                            }))
                            : null,
                        DeliveryDetails: data.Order.DeliveryDetails
                            ? {
                                Street: data.Order.DeliveryDetails.Street,
                                Building: data.Order.DeliveryDetails.Building,
                                Unit: data.Order.DeliveryDetails.Unit,
                                City: data.Order.DeliveryDetails.City,
                                PostCode: data.Order.DeliveryDetails.PostCode,
                                Latitude: data.Order.DeliveryDetails.Latitude,
                                Longitude: data.Order.DeliveryDetails.Longitude,
                                ExtraInstructions: data.Order.DeliveryDetails.ExtraInstructions,
                                DesiredDeliveryTime: data.Order.DeliveryDetails.DesiredDeliveryTime, // date-time
                                EstimatedDeliveryTime: data.Order.DeliveryDetails.EstimatedDeliveryTime,
                            }
                            : null,
                        ActivatedVouchers: data.Order.ActivatedVouchers
                            ? data.Order.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                                VoucherName: item.VoucherName,
                                VoucherCode: item.VoucherCode,
                                VoucherId: item.VoucherId,
                                VoucherIssueId: item.VoucherIssueId,
                                Mode: item.Mode,
                                AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                                ActivatedMenuFlow: item.ActivatedMenuFlow,
                                ActivatedOrderDiscount: item.ActivatedOrderDiscount
                                    ? {
                                        Id: item.ActivatedOrderDiscount.Id,
                                        OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                        Value: item.ActivatedOrderDiscount.Value,
                                        DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                        AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                        VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                                    }
                                    : null,
                                ActivatedItemDiscount: item.ActivatedItemDiscount
                                    ? {
                                        OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                        OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                        Value: item.ActivatedItemDiscount.Value,
                                        DiscountType: item.ActivatedItemDiscount.DiscountType,
                                        AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                        DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                                    }
                                    : null,
                                VoucherType: item.VoucherType,
                            }))
                            : null,
                    }
                    : null,
                VoucherCode: data.VoucherCode,
                ActivatedEntityId: data.ActivatedEntityId,
            }
            : null;
    }

    public static mapAddVoucherOnlineOrderPOSTResponse(data: APIv3.OnlineOrderBusinessModel): OLO.DTO.OnlineOrderBusinessModel {
        return data
            ? {
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                ExtraInstructions: data.ExtraInstructions,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        TotalValue: menuflow.TotalValue,
                        TotalValueInitial: menuflow.TotalValueInitial,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
            }
            : null;
    }

    public static mapRemoveVoucherOnlineOrderPOSTRequest(data: OLO.DTO.DeactivateVoucherRequest): APIv3.DeactivateVoucherRequest {
        return data
            ? {
                Order: data.Order
                    ? {
                        Id: data.Order.Id,
                        Source: data.Order.Source,
                        SaleName: data.Order.SaleName,
                        PickupLocation: data.Order.PickupLocation,
                        PickUpDate: data.Order.PickUpDate,
                        OrderedDate: data.Order.OrderedDate,
                        TableNumber: data.Order.TableNumber,
                        IsDelivery: data.Order.IsDelivery,
                        Status: data.Order.Status,
                        MemberId: data.Order.MemberId,
                        Covers: data.Order.Covers,
                        AddedDate: data.Order.AddedDate,
                        ExtraInstructions: data.Order.ExtraInstructions,
                        DisplayNumber: data.Order.DisplayNumber,
                        PartialMember: data.Order.PartialMember
                            ? {
                                FirstName: data.Order.PartialMember.FirstName,
                                LastName: data.Order.PartialMember.LastName,
                                Email: data.Order.PartialMember.Email,
                                MobileNumber: data.Order.PartialMember.MobileNumber,
                                MobileNumberCountryID: data.Order.PartialMember.MobileNumberCountryID,
                            }
                            : null,
                        ClerkId: data.Order.ClerkId,
                        OnlineOrderType: data.Order.OnlineOrderType,
                        MasterOrderID: data.Order.MasterOrderID,
                        SendToKMS: data.Order.SendToKMS,
                        SendToPrinters: data.Order.SendToPrinters,
                        TotalNettValue: data.Order.TotalNettValue,
                        TotalNettValueBeforeSaleDiscount: data.Order.TotalNettValueBeforeSaleDiscount,
                        TotalGrossValue: data.Order.TotalGrossValue,
                        TotalSaleValueInitial: data.Order.TotalSaleValueInitial,
                        TotalSaleValueFinal: data.Order.TotalSaleValueFinal,
                        TotalTaxes: data.Order.TotalTaxes
                            ? data.Order.TotalTaxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        TotalLeftToPay: data.Order.TotalLeftToPay,
                        TotalSaleDiscountAppliedAmount: data.Order.TotalSaleDiscountAppliedAmount,
                        TotalPaid: data.Order.TotalPaid,
                        OrderTypeId: data.Order.OrderTypeId,
                        TransactionId: data.Order.TransactionId,
                        IsRated: data.Order.IsRated,
                        SendReceiptOnEmail: data.Order.SendReceiptOnEmail,
                        LoyaltyAppId: data.Order.LoyaltyAppId,
                        ReceiptNotificationEmailAdresses: data.Order.ReceiptNotificationEmailAdresses,
                        FavouriteId: data.Order.FavouriteId,
                        POSPrepaid: data.Order.POSPrepaid,
                        Items: data.Order.Items
                            ? data.Order.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                                FreeProductID: item.FreeProductID,
                                Id: item.Id,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                OrderId: item.OrderId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                InitialQuantity: item.InitialQuantity,
                                ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                                Type: item.Type,
                                Value: item.Value,
                                InitialUnitPrice: item.InitialUnitPrice,
                                UnitPrice: item.UnitPrice,
                                RedeemedProductId: item.RedeemedProductId,
                                IsRedeemedByPoints: item.IsRedeemedByPoints,
                                PointsValue: item.PointsValue,
                                Modifiers: item.Modifiers
                                    ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                        Id: modifier.Id,
                                        OnlineOrderId: modifier.OnlineOrderId,
                                        OnlineOrderItemId: modifier.OnlineOrderItemId,
                                        RecipeId: modifier.RecipeId,
                                        NewPlu: modifier.NewPlu,
                                        OldPlu: modifier.OldPlu,
                                        NewQty: modifier.NewQty,
                                        OldQty: modifier.OldQty,
                                    }))
                                    : null,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((tax) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((tax) => ({
                                                        TaxID: tax.TaxID,
                                                        Value: tax.Value,
                                                        IsInclusive: tax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                SpecialInstructions: item.SpecialInstructions,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                Discount: item.Discount
                                    ? {
                                        OnlineOrderId: item.Discount.OnlineOrderId,
                                        OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                        Value: item.Discount.Value,
                                        DiscountType: item.Discount.DiscountType,
                                        AmountApplied: item.Discount.AmountApplied,
                                        DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                                    }
                                    : null,
                                VoucherId: item.VoucherId,
                                VoucherCode: item.VoucherCode,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        Medias: data.Order.Medias
                            ? data.Order.Medias.map((medias) => ({
                                Id: medias.Id,
                                CustomerFriendlyName: medias.CustomerFriendlyName,
                                MediaDescription: medias.MediaDescription,
                                MediaId: medias.MediaId,
                                OrderId: medias.OrderId,
                                Value: medias.Value,
                                PaymentType: medias.PaymentType,
                                PaymentTransactionId: medias.PaymentTransactionId,
                                IsTax: medias.IsTax,
                            }))
                            : null,
                        MenuFlowActivations: data.Order.MenuFlowActivations
                            ? data.Order.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                                Id: menuflow.Id,
                                OnlineOrderId: menuflow.OnlineOrderId,
                                MenuFlowId: menuflow.MenuFlowId,
                                ActivationGuid: menuflow.ActivationGuid, // uuid
                                Value: menuflow.Value,
                                SaleValueInitial: menuflow.SaleValueInitial,
                                InitialUnitPrice: menuflow.InitialUnitPrice,
                                TotalValue: menuflow.TotalValue,
                                TotalValueInitial: menuflow.TotalValueInitial,
                                MenuFlowItems: menuflow.MenuFlowItems
                                    ? menuflow.MenuFlowItems.map((item) => ({
                                        Id: item.Id,
                                        OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                        OnlineOrderItemId: item.OnlineOrderItemId,
                                        MenuFlowPageId: item.MenuFlowPageId,
                                        PLU: item.PLU,
                                        Quantity: item.Quantity,
                                        Type: item.Type,
                                        Value: item.Value,
                                        DisplayName: item.DisplayName,
                                        DisplayDescription: item.DisplayDescription,
                                        UnitPrice: item.UnitPrice,
                                        SpecialInstructions: item.SpecialInstructions,
                                        IngredientsChanges: item.IngredientsChanges
                                            ? {
                                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                        ID: ingredientsModified.ID,
                                                        OrderItemId: ingredientsModified.OrderItemId,
                                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                                        ModifierID: ingredientsModified.ModifierID,
                                                        ModifierName: ingredientsModified.ModifierName,
                                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                                        TaxBase: ingredientsModified.TaxBase,
                                                        Taxes: ingredientsModified.Taxes
                                                            ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                                TaxID: ingredientsTax.TaxID,
                                                                Value: ingredientsTax.Value,
                                                                IsInclusive: ingredientsTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsModified.GrossValue,
                                                        NettValue: ingredientsModified.NettValue,
                                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                                    }))
                                                    : null,
                                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                        ID: ingredientsRemoved.ID,
                                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                                    }))
                                                    : null,
                                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                        ID: ingredientsAdded.ID,
                                                        OrderItemId: ingredientsAdded.OrderItemId,
                                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                                        ModifierID: ingredientsAdded.ModifierID,
                                                        ModifierName: ingredientsAdded.ModifierName,
                                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                                        TaxBase: ingredientsAdded.TaxBase,
                                                        Taxes: ingredientsAdded.Taxes
                                                            ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                                TaxID: ingredientsAddedTax.TaxID,
                                                                Value: ingredientsAddedTax.Value,
                                                                IsInclusive: ingredientsAddedTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsAdded.GrossValue,
                                                        NettValue: ingredientsAdded.NettValue,
                                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                                    }))
                                                    : null,
                                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                        ID: ingredientsSwapped.ID,
                                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                        ModifierID: ingredientsSwapped.ModifierID,
                                                        ModifierName: ingredientsSwapped.ModifierName,
                                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                        TaxBase: ingredientsSwapped.TaxBase,
                                                        Taxes: ingredientsSwapped.Taxes
                                                            ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                                TaxID: ingredientsSwappedTax.TaxID,
                                                                Value: ingredientsSwappedTax.Value,
                                                                IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                            }))
                                                            : null,
                                                        GrossValue: ingredientsSwapped.GrossValue,
                                                        NettValue: ingredientsSwapped.NettValue,
                                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                                    }))
                                                    : null,
                                            }
                                            : null,
                                        IsHiddenFromUser: item.IsHiddenFromUser,
                                        TaxBase: item.TaxBase,
                                        Taxes: item.Taxes
                                            ? item.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: item.GrossValue,
                                        NettValue: item.NettValue,
                                        SaleValueInitial: item.SaleValueInitial,
                                        SaleValueFinal: item.SaleValueFinal,
                                    }))
                                    : null,
                                DisplayName: menuflow.DisplayName,
                                DisplayDescription: menuflow.DisplayDescription,
                                Quantity: menuflow.Quantity,
                                InitialQuantity: menuflow.InitialQuantity,
                                UnitPrice: menuflow.UnitPrice,
                                SpecialInstructions: menuflow.SpecialInstructions,
                                IsUpsell: menuflow.IsUpsell,
                                Discount: menuflow.Discount
                                    ? {
                                        OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                        OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                        Value: menuflow.Discount.Value,
                                        DiscountType: menuflow.Discount.DiscountType,
                                        AmountApplied: menuflow.Discount.AmountApplied,
                                    }
                                    : null,
                            }))
                            : null,
                        OnlineDiscounts: data.Order.OnlineDiscounts
                            ? data.Order.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                                Id: item.Id,
                                OnlineOrderId: item.OnlineOrderId,
                                Value: item.Value,
                                DiscountType: item.DiscountType,
                                AmountApplied: item.AmountApplied,
                                VoucherCode: item.VoucherCode,
                            }))
                            : null,
                        ExternalPartners: data.Order.ExternalPartners
                            ? data.Order.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                                CustomerFacingMessage: item.CustomerFacingMessage,
                                OrderID: item.OrderID,
                                Slug: item.Slug,
                                MemberID: item.MemberID,
                                PriceLevelID: item.PriceLevelID,
                            }))
                            : null,
                        Surcharges: data.Order.Surcharges
                            ? data.Order.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                                OnlineOrderId: item.OnlineOrderId,
                                OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                                CustomerFriendlyName: item.CustomerFriendlyName,
                                CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                                DisplayIndex: item.DisplayIndex,
                                IsVisibleForCustomer: item.IsVisibleForCustomer,
                                ProductId: item.ProductId,
                                PLU: item.PLU,
                                Value: item.Value,
                                SurchargeType: item.SurchargeType,
                                SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                                MediaNo: item.MediaNo,
                                AmountApplied: item.AmountApplied
                            }))
                            : null,
                        OrderTypeDetails: data.Order.OrderTypeDetails
                            ? data.Order.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                                OnlineOrderId: item.OnlineOrderId,
                                DetailsDefinitionId: item.DetailsDefinitionId,
                                CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                                ValueProvided: item.ValueProvided,
                            }))
                            : null,
                        OrderTypeDisclaimers: data.Order.OrderTypeDisclaimers
                            ? data.Order.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                                Id: item.Id,
                                OrderId: item.OrderId,
                                OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                                CustomerFriendlyName: item.CustomerFriendlyName,
                                CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                                DisplayIndex: item.DisplayIndex,
                                IsAccepted: item.IsAccepted,
                            }))
                            : null,
                        DeliveryDetails: data.Order.DeliveryDetails
                            ? {
                                Street: data.Order.DeliveryDetails.Street,
                                Building: data.Order.DeliveryDetails.Building,
                                Unit: data.Order.DeliveryDetails.Unit,
                                City: data.Order.DeliveryDetails.City,
                                PostCode: data.Order.DeliveryDetails.PostCode,
                                Latitude: data.Order.DeliveryDetails.Latitude,
                                Longitude: data.Order.DeliveryDetails.Longitude,
                                ExtraInstructions: data.Order.DeliveryDetails.ExtraInstructions,
                                DesiredDeliveryTime: data.Order.DeliveryDetails.DesiredDeliveryTime, // date-time
                                EstimatedDeliveryTime: data.Order.DeliveryDetails.EstimatedDeliveryTime,
                            }
                            : null,
                        ActivatedVouchers: data.Order.ActivatedVouchers
                            ? data.Order.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                                VoucherName: item.VoucherName,
                                VoucherCode: item.VoucherCode,
                                VoucherId: item.VoucherId,
                                VoucherIssueId: item.VoucherIssueId,
                                Mode: item.Mode,
                                AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                                ActivatedMenuFlow: item.ActivatedMenuFlow,
                                ActivatedOrderDiscount: item.ActivatedOrderDiscount
                                    ? {
                                        Id: item.ActivatedOrderDiscount.Id,
                                        OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                        Value: item.ActivatedOrderDiscount.Value,
                                        DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                        AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                        VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                                    }
                                    : null,
                                ActivatedItemDiscount: item.ActivatedItemDiscount
                                    ? {
                                        OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                        OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                        Value: item.ActivatedItemDiscount.Value,
                                        DiscountType: item.ActivatedItemDiscount.DiscountType,
                                        AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                        DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                                    }
                                    : null,
                                VoucherType: item.VoucherType,
                            }))
                            : null,
                    }
                    : null,
                VoucherCode: data.VoucherCode,
            }
            : null;
    }

    public static mapRemoveVoucherOnlineOrderPOSTResponse(data: APIv3.OnlineOrderBusinessModel): OLO.DTO.OnlineOrderBusinessModel {
        return data
            ? {
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                ExtraInstructions: data.ExtraInstructions,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        TotalValue: menuflow.TotalValue,
                        TotalValueInitial: menuflow.TotalValueInitial,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
            }
            : null;
    }

    public static mapRecalculateOnlineOrderPOSTRequest(data: APIv3.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        return data
            ? {
                MemberFirstName: data.MemberFirstName,
                MemberLastName: data.MemberLastName,
                MemberMobilePhone: data.MemberMobilePhone,
                MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
                MemberHomePhone: data.MemberHomePhone,
                MemberEmail: data.MemberEmail,
                ExtraInstructions: data.ExtraInstructions,
                Barcode: data.Barcode,
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax: APIv3.TaxValue) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: APIv3.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: APIv3.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                        VirtualLocations: item.VirtualLocations ? item.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName: virtualLocation.LocationFriendlyName
                        })) : null
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias: APIv3.OnlineOrderMediaModel) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: APIv3.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        TotalValue: menuflow.TotalValue,
                        VirtualLocations: menuflow.VirtualLocations ? menuflow.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item: APIv3.OnlineOrderMenuFlowItem) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax: APIv3.TaxValue) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: APIv3.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: APIv3.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: APIv3.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: APIv3.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: APIv3.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
                CancelledDate: data.CanceledDate,
                VirtualLocations: data.VirtualLocations ? data.VirtualLocations.map(virtualLocation => ({
                    LocationNo: virtualLocation.LocationNo,
                    LocationClassification: virtualLocation.LocationClassification,
                    LocationFriendlyName: virtualLocation.LocationFriendlyName
                })) : null
            }
            : null;
    }

    public static mapRecalculateOnlineOrderPOSTResponse(data: APIv3.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        return data
            ? {
                MemberFirstName: data.MemberFirstName,
                MemberLastName: data.MemberLastName,
                MemberMobilePhone: data.MemberMobilePhone,
                MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
                MemberHomePhone: data.MemberHomePhone,
                MemberEmail: data.MemberEmail,
                ExtraInstructions: data.ExtraInstructions,
                Barcode: data.Barcode,
                Id: data.Id,
                Source: data.Source,
                SaleName: data.SaleName,
                PickupLocation: data.PickupLocation,
                PickUpDate: data.PickUpDate,
                OrderedDate: data.OrderedDate,
                TableNumber: data.TableNumber,
                IsDelivery: data.IsDelivery,
                Status: data.Status,
                MemberId: data.MemberId,
                Covers: data.Covers,
                AddedDate: data.AddedDate,
                DisplayNumber: data.DisplayNumber,
                PartialMember: data.PartialMember
                    ? {
                        FirstName: data.PartialMember.FirstName,
                        LastName: data.PartialMember.LastName,
                        Email: data.PartialMember.Email,
                        MobileNumber: data.PartialMember.MobileNumber,
                        MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                    }
                    : null,
                ClerkId: data.ClerkId,
                OnlineOrderType: data.OnlineOrderType,
                MasterOrderID: data.MasterOrderID,
                SendToKMS: data.SendToKMS,
                SendToPrinters: data.SendToPrinters,
                TotalNettValue: data.TotalNettValue,
                TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                TotalGrossValue: data.TotalGrossValue,
                TotalSaleValueInitial: data.TotalSaleValueInitial,
                TotalSaleValueFinal: data.TotalSaleValueFinal,
                TotalTaxes: data.TotalTaxes
                    ? data.TotalTaxes.map((tax) => ({
                        TaxID: tax.TaxID,
                        Value: tax.Value,
                        IsInclusive: tax.IsInclusive,
                    }))
                    : null,
                TotalLeftToPay: data.TotalLeftToPay,
                TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                TotalPaid: data.TotalPaid,
                OrderTypeId: data.OrderTypeId,
                TransactionId: data.TransactionId,
                IsRated: data.IsRated,
                SendReceiptOnEmail: data.SendReceiptOnEmail,
                LoyaltyAppId: data.LoyaltyAppId,
                ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                FavouriteId: data.FavouriteId,
                POSPrepaid: data.POSPrepaid,
                Items: data.Items
                    ? data.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                        FreeProductID: item.FreeProductID,
                        Id: item.Id,
                        DisplayName: item.DisplayName,
                        DisplayDescription: item.DisplayDescription,
                        OrderId: item.OrderId,
                        PLU: item.PLU,
                        Quantity: item.Quantity,
                        InitialQuantity: item.InitialQuantity,
                        ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                        Type: item.Type,
                        Value: item.Value,
                        InitialUnitPrice: item.InitialUnitPrice,
                        UnitPrice: item.UnitPrice,
                        RedeemedProductId: item.RedeemedProductId,
                        IsRedeemedByPoints: item.IsRedeemedByPoints,
                        PointsValue: item.PointsValue,
                        Modifiers: item.Modifiers
                            ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                                Id: modifier.Id,
                                OnlineOrderId: modifier.OnlineOrderId,
                                OnlineOrderItemId: modifier.OnlineOrderItemId,
                                RecipeId: modifier.RecipeId,
                                NewPlu: modifier.NewPlu,
                                OldPlu: modifier.OldPlu,
                                NewQty: modifier.NewQty,
                                OldQty: modifier.OldQty,
                            }))
                            : null,
                        IngredientsChanges: item.IngredientsChanges
                            ? {
                                IngredientsModified: item.IngredientsChanges.IngredientsModified
                                    ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                        ID: ingredientsModified.ID,
                                        OrderItemId: ingredientsModified.OrderItemId,
                                        IngredientPLU: ingredientsModified.IngredientPLU,
                                        ModifierID: ingredientsModified.ModifierID,
                                        ModifierName: ingredientsModified.ModifierName,
                                        ExtraPrice: ingredientsModified.ExtraPrice,
                                        TaxBase: ingredientsModified.TaxBase,
                                        Taxes: ingredientsModified.Taxes
                                            ? ingredientsModified.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsModified.GrossValue,
                                        NettValue: ingredientsModified.NettValue,
                                        SaleValueInitial: ingredientsModified.SaleValueInitial,
                                        SaleValueFinal: ingredientsModified.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                    ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                        ID: ingredientsRemoved.ID,
                                        OrderItemId: ingredientsRemoved.OrderItemId,
                                        IngredientPLU: ingredientsRemoved.IngredientPLU,
                                    }))
                                    : null,
                                IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                    ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                        ID: ingredientsAdded.ID,
                                        OrderItemId: ingredientsAdded.OrderItemId,
                                        IngredientPLU: ingredientsAdded.IngredientPLU,
                                        ModifierID: ingredientsAdded.ModifierID,
                                        ModifierName: ingredientsAdded.ModifierName,
                                        ExtraPrice: ingredientsAdded.ExtraPrice,
                                        TaxBase: ingredientsAdded.TaxBase,
                                        Taxes: ingredientsAdded.Taxes
                                            ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsAdded.GrossValue,
                                        NettValue: ingredientsAdded.NettValue,
                                        SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                        SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                    }))
                                    : null,
                                IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                    ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                        ID: ingredientsSwapped.ID,
                                        OrderItemId: ingredientsSwapped.OrderItemId,
                                        OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                        NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                        ModifierID: ingredientsSwapped.ModifierID,
                                        ModifierName: ingredientsSwapped.ModifierName,
                                        ExtraPrice: ingredientsSwapped.ExtraPrice,
                                        TaxBase: ingredientsSwapped.TaxBase,
                                        Taxes: ingredientsSwapped.Taxes
                                            ? ingredientsSwapped.Taxes.map((tax) => ({
                                                TaxID: tax.TaxID,
                                                Value: tax.Value,
                                                IsInclusive: tax.IsInclusive,
                                            }))
                                            : null,
                                        GrossValue: ingredientsSwapped.GrossValue,
                                        NettValue: ingredientsSwapped.NettValue,
                                        SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                        SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                    }))
                                    : null,
                            }
                            : null,
                        SpecialInstructions: item.SpecialInstructions,
                        IsHiddenFromUser: item.IsHiddenFromUser,
                        Discount: item.Discount
                            ? {
                                OnlineOrderId: item.Discount.OnlineOrderId,
                                OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                Value: item.Discount.Value,
                                DiscountType: item.Discount.DiscountType,
                                AmountApplied: item.Discount.AmountApplied,
                                DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherId: item.VoucherId,
                        VoucherCode: item.VoucherCode,
                        TaxBase: item.TaxBase,
                        Taxes: item.Taxes
                            ? item.Taxes.map((tax) => ({
                                TaxID: tax.TaxID,
                                Value: tax.Value,
                                IsInclusive: tax.IsInclusive,
                            }))
                            : null,
                        GrossValue: item.GrossValue,
                        NettValue: item.NettValue,
                        SaleValueInitial: item.SaleValueInitial,
                        SaleValueFinal: item.SaleValueFinal,
                        VirtualLocations: item.VirtualLocations ? item.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null
                    }))
                    : null,
                Medias: data.Medias
                    ? data.Medias.map((medias) => ({
                        Id: medias.Id,
                        CustomerFriendlyName: medias.CustomerFriendlyName,
                        MediaDescription: medias.MediaDescription,
                        MediaId: medias.MediaId,
                        OrderId: medias.OrderId,
                        Value: medias.Value,
                        PaymentType: medias.PaymentType,
                        PaymentTransactionId: medias.PaymentTransactionId,
                        IsTax: medias.IsTax,
                    }))
                    : null,
                MenuFlowActivations: data.MenuFlowActivations
                    ? data.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                        Id: menuflow.Id,
                        OnlineOrderId: menuflow.OnlineOrderId,
                        MenuFlowId: menuflow.MenuFlowId,
                        ActivationGuid: menuflow.ActivationGuid, // uuid
                        Value: menuflow.Value,
                        SaleValueInitial: menuflow.SaleValueInitial,
                        InitialUnitPrice: menuflow.InitialUnitPrice,
                        TotalValue: menuflow.TotalValue,
                        TotalValueInitial: menuflow.TotalValueInitial,
                        VirtualLocations: menuflow.VirtualLocations ? menuflow.VirtualLocations.map(virtualLocation => ({
                            LocationNo: virtualLocation.LocationNo,
                            LocationClassification: virtualLocation.LocationClassification,
                            LocationFriendlyName : virtualLocation.LocationFriendlyName
                        })) : null,
                        MenuFlowItems: menuflow.MenuFlowItems
                            ? menuflow.MenuFlowItems.map((item) => ({
                                Id: item.Id,
                                OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                OnlineOrderItemId: item.OnlineOrderItemId,
                                MenuFlowPageId: item.MenuFlowPageId,
                                PLU: item.PLU,
                                Quantity: item.Quantity,
                                Type: item.Type,
                                Value: item.Value,
                                DisplayName: item.DisplayName,
                                DisplayDescription: item.DisplayDescription,
                                UnitPrice: item.UnitPrice,
                                SpecialInstructions: item.SpecialInstructions,
                                IngredientsChanges: item.IngredientsChanges
                                    ? {
                                        IngredientsModified: item.IngredientsChanges.IngredientsModified
                                            ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                                ID: ingredientsModified.ID,
                                                OrderItemId: ingredientsModified.OrderItemId,
                                                IngredientPLU: ingredientsModified.IngredientPLU,
                                                ModifierID: ingredientsModified.ModifierID,
                                                ModifierName: ingredientsModified.ModifierName,
                                                ExtraPrice: ingredientsModified.ExtraPrice,
                                                TaxBase: ingredientsModified.TaxBase,
                                                Taxes: ingredientsModified.Taxes
                                                    ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                        TaxID: ingredientsTax.TaxID,
                                                        Value: ingredientsTax.Value,
                                                        IsInclusive: ingredientsTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsModified.GrossValue,
                                                NettValue: ingredientsModified.NettValue,
                                                SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                SaleValueFinal: ingredientsModified.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                            ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                                ID: ingredientsRemoved.ID,
                                                OrderItemId: ingredientsRemoved.OrderItemId,
                                                IngredientPLU: ingredientsRemoved.IngredientPLU,
                                            }))
                                            : null,
                                        IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                            ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                                ID: ingredientsAdded.ID,
                                                OrderItemId: ingredientsAdded.OrderItemId,
                                                IngredientPLU: ingredientsAdded.IngredientPLU,
                                                ModifierID: ingredientsAdded.ModifierID,
                                                ModifierName: ingredientsAdded.ModifierName,
                                                ExtraPrice: ingredientsAdded.ExtraPrice,
                                                TaxBase: ingredientsAdded.TaxBase,
                                                Taxes: ingredientsAdded.Taxes
                                                    ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                        TaxID: ingredientsAddedTax.TaxID,
                                                        Value: ingredientsAddedTax.Value,
                                                        IsInclusive: ingredientsAddedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsAdded.GrossValue,
                                                NettValue: ingredientsAdded.NettValue,
                                                SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                            }))
                                            : null,
                                        IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                            ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                                ID: ingredientsSwapped.ID,
                                                OrderItemId: ingredientsSwapped.OrderItemId,
                                                OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                ModifierID: ingredientsSwapped.ModifierID,
                                                ModifierName: ingredientsSwapped.ModifierName,
                                                ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                TaxBase: ingredientsSwapped.TaxBase,
                                                Taxes: ingredientsSwapped.Taxes
                                                    ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                        TaxID: ingredientsSwappedTax.TaxID,
                                                        Value: ingredientsSwappedTax.Value,
                                                        IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                    }))
                                                    : null,
                                                GrossValue: ingredientsSwapped.GrossValue,
                                                NettValue: ingredientsSwapped.NettValue,
                                                SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                            }))
                                            : null,
                                    }
                                    : null,
                                IsHiddenFromUser: item.IsHiddenFromUser,
                                TaxBase: item.TaxBase,
                                Taxes: item.Taxes
                                    ? item.Taxes.map((tax) => ({
                                        TaxID: tax.TaxID,
                                        Value: tax.Value,
                                        IsInclusive: tax.IsInclusive,
                                    }))
                                    : null,
                                GrossValue: item.GrossValue,
                                NettValue: item.NettValue,
                                SaleValueInitial: item.SaleValueInitial,
                                SaleValueFinal: item.SaleValueFinal,
                            }))
                            : null,
                        DisplayName: menuflow.DisplayName,
                        DisplayDescription: menuflow.DisplayDescription,
                        Quantity: menuflow.Quantity,
                        InitialQuantity: menuflow.InitialQuantity,
                        UnitPrice: menuflow.UnitPrice,
                        SpecialInstructions: menuflow.SpecialInstructions,
                        IsUpsell: menuflow.IsUpsell,
                        Discount: menuflow.Discount
                            ? {
                                OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                Value: menuflow.Discount.Value,
                                DiscountType: menuflow.Discount.DiscountType,
                                AmountApplied: menuflow.Discount.AmountApplied,
                            }
                            : null,
                    }))
                    : null,
                OnlineDiscounts: data.OnlineDiscounts
                    ? data.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                        Id: item.Id,
                        OnlineOrderId: item.OnlineOrderId,
                        Value: item.Value,
                        DiscountType: item.DiscountType,
                        AmountApplied: item.AmountApplied,
                        VoucherCode: item.VoucherCode,
                    }))
                    : null,
                ExternalPartners: data.ExternalPartners
                    ? data.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                        CustomerFacingMessage: item.CustomerFacingMessage,
                        OrderID: item.OrderID,
                        Slug: item.Slug,
                        MemberID: item.MemberID,
                        PriceLevelID: item.PriceLevelID,
                    }))
                    : null,
                Surcharges: data.Surcharges
                    ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsVisibleForCustomer: item.IsVisibleForCustomer,
                        ProductId: item.ProductId,
                        PLU: item.PLU,
                        Value: item.Value,
                        SurchargeType: item.SurchargeType,
                        SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                        MediaNo: item.MediaNo,
                        AmountApplied: item.AmountApplied
                    }))
                    : null,
                OrderTypeDetails: data.OrderTypeDetails
                    ? data.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                        OnlineOrderId: item.OnlineOrderId,
                        DetailsDefinitionId: item.DetailsDefinitionId,
                        CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                        ValueProvided: item.ValueProvided,
                    }))
                    : null,
                OrderTypeDisclaimers: data.OrderTypeDisclaimers
                    ? data.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                        Id: item.Id,
                        OrderId: item.OrderId,
                        OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                        CustomerFriendlyName: item.CustomerFriendlyName,
                        CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                        DisplayIndex: item.DisplayIndex,
                        IsAccepted: item.IsAccepted,
                    }))
                    : null,
                DeliveryDetails: data.DeliveryDetails
                    ? {
                        Street: data.DeliveryDetails.Street,
                        Building: data.DeliveryDetails.Building,
                        Unit: data.DeliveryDetails.Unit,
                        City: data.DeliveryDetails.City,
                        PostCode: data.DeliveryDetails.PostCode,
                        Latitude: data.DeliveryDetails.Latitude,
                        Longitude: data.DeliveryDetails.Longitude,
                        ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                        DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                        EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                    }
                    : null,
                ActivatedVouchers: data.ActivatedVouchers
                    ? data.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                        VoucherName: item.VoucherName,
                        VoucherCode: item.VoucherCode,
                        VoucherId: item.VoucherId,
                        VoucherIssueId: item.VoucherIssueId,
                        Mode: item.Mode,
                        AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                        ActivatedMenuFlow: item.ActivatedMenuFlow,
                        ActivatedOrderDiscount: item.ActivatedOrderDiscount
                            ? {
                                Id: item.ActivatedOrderDiscount.Id,
                                OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                Value: item.ActivatedOrderDiscount.Value,
                                DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                            }
                            : null,
                        ActivatedItemDiscount: item.ActivatedItemDiscount
                            ? {
                                OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                Value: item.ActivatedItemDiscount.Value,
                                DiscountType: item.ActivatedItemDiscount.DiscountType,
                                AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                            }
                            : null,
                        VoucherType: item.VoucherType,
                    }))
                    : null,
                VirtualLocations: data.VirtualLocations ? data.VirtualLocations.map(virtualLocation => ({
                    LocationNo: virtualLocation.LocationNo,
                    LocationClassification: virtualLocation.LocationClassification,
                    LocationFriendlyName: virtualLocation.LocationFriendlyName
                })) : null
            }
            : null;
    }

    public static mapSendOnlineOrderReceiptPOSTRequest(data: { LoyaltyMobileAppId: null; OnlineOrderId: number; }): APIv3.OnlineOrderEmailReceiptRequestModel {
        return data
            ? {
                OnlineOrderId: data.OnlineOrderId,
                LoyaltyMobileAppId: null,
            }
            : null;
    }

    public static mapSendOnlineOrderReceiptPOSTResponse(data: APIv3.OnlineOrdersSendOnlineOrderReceiptEmail.Responses.$200): boolean {
        return data;
    }

    public static mapInsertOnlineOrderUrlPOSTRequest(data: OLO.DTO.OnlineOrderUrlBusinessModel): APIv3.OnlineOrderUrlBusinessModel {
        return data
            ? {
                Id: data.Id,
                OrderId: data.OrderId,
                Url: data.Url,
                UrlType: data.UrlType,
            }
            : null;
    }

    public static mapInsertOnlineOrderUrlPOSTResponse(data: APIv3.OnlineOrderUrlBusinessModel): OLO.DTO.OnlineOrderUrlBusinessModel {
        return data
            ? {
                Id: data.Id,
                OrderId: data.OrderId,
                Url: data.Url,
                UrlType: data.UrlType,
            }
            : null;
    }

    public static mapOnlineOrdersGETResponse(response: APIv3.PaginatedListOnlineOrderDetailedBusinessModel): OLO.DTO.PaginatedListOnlineOrderDetailedBusinessModel {
        if (!response) return null;
        const mappedData: OLO.DTO.PaginatedListOnlineOrderDetailedBusinessModel = {
            PageNo: response.PageNo,
            PageSize: response.PageSize,
            Items: response.Items
                ? response.Items.map((data: APIv3.OnlineOrderDetailedBusinessModel) => ({
                    MemberFirstName: data.MemberFirstName,
                    MemberLastName: data.MemberLastName,
                    MemberMobilePhone: data.MemberMobilePhone,
                    MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
                    MemberHomePhone: data.MemberHomePhone,
                    MemberEmail: data.MemberEmail,
                    ExtraInstructions: data.ExtraInstructions,
                    Barcode: data.Barcode,
                    Id: data.Id,
                    Source: data.Source,
                    SaleName: data.SaleName,
                    PickupLocation: data.PickupLocation,
                    PickUpDate: data.PickUpDate,
                    OrderedDate: data.OrderedDate,
                    TableNumber: data.TableNumber,
                    IsDelivery: data.IsDelivery,
                    Status: data.Status,
                    MemberId: data.MemberId,
                    Covers: data.Covers,
                    AddedDate: data.AddedDate,
                    DisplayNumber: data.DisplayNumber,
                    PartialMember: data.PartialMember
                        ? {
                            FirstName: data.PartialMember.FirstName,
                            LastName: data.PartialMember.LastName,
                            Email: data.PartialMember.Email,
                            MobileNumber: data.PartialMember.MobileNumber,
                            MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                        }
                        : null,
                    ClerkId: data.ClerkId,
                    OnlineOrderType: data.OnlineOrderType,
                    MasterOrderID: data.MasterOrderID,
                    SendToKMS: data.SendToKMS,
                    SendToPrinters: data.SendToPrinters,
                    TotalNettValue: data.TotalNettValue,
                    TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
                    TotalGrossValue: data.TotalGrossValue,
                    TotalSaleValueInitial: data.TotalSaleValueInitial,
                    TotalSaleValueFinal: data.TotalSaleValueFinal,
                    TotalTaxes: data.TotalTaxes
                        ? data.TotalTaxes.map((tax: APIv3.TaxValue) => ({
                            TaxID: tax.TaxID,
                            Value: tax.Value,
                            IsInclusive: tax.IsInclusive,
                        }))
                        : null,
                    TotalLeftToPay: data.TotalLeftToPay,
                    TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
                    TotalPaid: data.TotalPaid,
                    OrderTypeId: data.OrderTypeId,
                    TransactionId: data.TransactionId,
                    IsRated: data.IsRated,
                    SendReceiptOnEmail: data.SendReceiptOnEmail,
                    LoyaltyAppId: data.LoyaltyAppId,
                    ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
                    FavouriteId: data.FavouriteId,
                    POSPrepaid: data.POSPrepaid,
                    Items: data.Items
                        ? data.Items.map((item: APIv3.OnlineOrderItemModel) => ({
                            FreeProductID: item.FreeProductID,
                            Id: item.Id,
                            DisplayName: item.DisplayName,
                            DisplayDescription: item.DisplayDescription,
                            OrderId: item.OrderId,
                            PLU: item.PLU,
                            Quantity: item.Quantity,
                            InitialQuantity: item.InitialQuantity,
                            ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                            Type: item.Type,
                            Value: item.Value,
                            InitialUnitPrice: item.InitialUnitPrice,
                            UnitPrice: item.UnitPrice,
                            RedeemedProductId: item.RedeemedProductId,
                            IsRedeemedByPoints: item.IsRedeemedByPoints,
                            PointsValue: item.PointsValue,
                            Modifiers: item.Modifiers
                                ? item.Modifiers.map((modifier: APIv3.OnlineOrderItemModifier) => ({
                                    Id: modifier.Id,
                                    OnlineOrderId: modifier.OnlineOrderId,
                                    OnlineOrderItemId: modifier.OnlineOrderItemId,
                                    RecipeId: modifier.RecipeId,
                                    NewPlu: modifier.NewPlu,
                                    OldPlu: modifier.OldPlu,
                                    NewQty: modifier.NewQty,
                                    OldQty: modifier.OldQty,
                                }))
                                : null,
                            IngredientsChanges: item.IngredientsChanges
                                ? {
                                    IngredientsModified: item.IngredientsChanges.IngredientsModified
                                        ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                            ID: ingredientsModified.ID,
                                            OrderItemId: ingredientsModified.OrderItemId,
                                            IngredientPLU: ingredientsModified.IngredientPLU,
                                            ModifierID: ingredientsModified.ModifierID,
                                            ModifierName: ingredientsModified.ModifierName,
                                            ExtraPrice: ingredientsModified.ExtraPrice,
                                            TaxBase: ingredientsModified.TaxBase,
                                            Taxes: ingredientsModified.Taxes
                                                ? ingredientsModified.Taxes.map((tax: APIv3.TaxValue) => ({
                                                    TaxID: tax.TaxID,
                                                    Value: tax.Value,
                                                    IsInclusive: tax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsModified.GrossValue,
                                            NettValue: ingredientsModified.NettValue,
                                            SaleValueInitial: ingredientsModified.SaleValueInitial,
                                            SaleValueFinal: ingredientsModified.SaleValueFinal,
                                        }))
                                        : null,
                                    IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                        ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                            ID: ingredientsRemoved.ID,
                                            OrderItemId: ingredientsRemoved.OrderItemId,
                                            IngredientPLU: ingredientsRemoved.IngredientPLU,
                                        }))
                                        : null,
                                    IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                        ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                            ID: ingredientsAdded.ID,
                                            OrderItemId: ingredientsAdded.OrderItemId,
                                            IngredientPLU: ingredientsAdded.IngredientPLU,
                                            ModifierID: ingredientsAdded.ModifierID,
                                            ModifierName: ingredientsAdded.ModifierName,
                                            ExtraPrice: ingredientsAdded.ExtraPrice,
                                            TaxBase: ingredientsAdded.TaxBase,
                                            Taxes: ingredientsAdded.Taxes
                                                ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                                    TaxID: tax.TaxID,
                                                    Value: tax.Value,
                                                    IsInclusive: tax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsAdded.GrossValue,
                                            NettValue: ingredientsAdded.NettValue,
                                            SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                            SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                        }))
                                        : null,
                                    IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                        ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                            ID: ingredientsSwapped.ID,
                                            OrderItemId: ingredientsSwapped.OrderItemId,
                                            OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                            NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                            ModifierID: ingredientsSwapped.ModifierID,
                                            ModifierName: ingredientsSwapped.ModifierName,
                                            ExtraPrice: ingredientsSwapped.ExtraPrice,
                                            TaxBase: ingredientsSwapped.TaxBase,
                                            Taxes: ingredientsSwapped.Taxes
                                                ? ingredientsSwapped.Taxes.map((tax: APIv3.TaxValue) => ({
                                                    TaxID: tax.TaxID,
                                                    Value: tax.Value,
                                                    IsInclusive: tax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsSwapped.GrossValue,
                                            NettValue: ingredientsSwapped.NettValue,
                                            SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                            SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                        }))
                                        : null,
                                }
                                : null,
                            SpecialInstructions: item.SpecialInstructions,
                            IsHiddenFromUser: item.IsHiddenFromUser,
                            Discount: item.Discount
                                ? {
                                    OnlineOrderId: item.Discount.OnlineOrderId,
                                    OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                                    Value: item.Discount.Value,
                                    DiscountType: item.Discount.DiscountType,
                                    AmountApplied: item.Discount.AmountApplied,
                                    DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                                }
                                : null,
                            VoucherId: item.VoucherId,
                            VoucherCode: item.VoucherCode,
                            TaxBase: item.TaxBase,
                            Taxes: item.Taxes
                                ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                    TaxID: tax.TaxID,
                                    Value: tax.Value,
                                    IsInclusive: tax.IsInclusive,
                                }))
                                : null,
                            GrossValue: item.GrossValue,
                            NettValue: item.NettValue,
                            SaleValueInitial: item.SaleValueInitial,
                            SaleValueFinal: item.SaleValueFinal,
                            VirtualLocations: item.VirtualLocations
                                ? item.VirtualLocations.map((location: APIv3.OnlineOrderVirtualLocationModel) => ({
                                    LocationNo: location.LocationNo,
                                    LocationClassification: location.LocationClassification,
                                    LocationFriendlyName : location.LocationFriendlyName
                                }))
                                : null
                        }))
                        : null,
                    Medias: data.Medias
                        ? data.Medias.map((medias: APIv3.OnlineOrderMediaModel) => ({
                            Id: medias.Id,
                            CustomerFriendlyName: medias.CustomerFriendlyName,
                            MediaDescription: medias.MediaDescription,
                            MediaId: medias.MediaId,
                            OrderId: medias.OrderId,
                            Value: medias.Value,
                            PaymentType: medias.PaymentType,
                            PaymentTransactionId: medias.PaymentTransactionId,
                            IsTax: medias.IsTax,
                        }))
                        : null,
                    MenuFlowActivations: data.MenuFlowActivations
                        ? data.MenuFlowActivations.map((menuflow: APIv3.OnlineOrderMenuFlowActivation) => ({
                            Id: menuflow.Id,
                            OnlineOrderId: menuflow.OnlineOrderId,
                            MenuFlowId: menuflow.MenuFlowId,
                            ActivationGuid: menuflow.ActivationGuid, // uuid
                            Value: menuflow.Value,
                            SaleValueInitial: menuflow.SaleValueInitial,
                            InitialUnitPrice: menuflow.InitialUnitPrice,
                            TotalValue: menuflow.TotalValue,
                            VirtualLocations: menuflow.VirtualLocations
                                ? menuflow.VirtualLocations.map(location => ({
                                    LocationNo: location.LocationNo,
                                    LocationClassification: location.LocationClassification,
                                    LocationFriendlyName : location.LocationFriendlyName
                                }))
                                : null
                            ,
                            MenuFlowItems: menuflow.MenuFlowItems
                                ? menuflow.MenuFlowItems.map((item: APIv3.OnlineOrderMenuFlowItem) => ({
                                    Id: item.Id,
                                    OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                                    OnlineOrderItemId: item.OnlineOrderItemId,
                                    MenuFlowPageId: item.MenuFlowPageId,
                                    PLU: item.PLU,
                                    Quantity: item.Quantity,
                                    Type: item.Type,
                                    Value: item.Value,
                                    DisplayName: item.DisplayName,
                                    DisplayDescription: item.DisplayDescription,
                                    UnitPrice: item.UnitPrice,
                                    SpecialInstructions: item.SpecialInstructions,
                                    IngredientsChanges: item.IngredientsChanges
                                        ? {
                                            IngredientsModified: item.IngredientsChanges.IngredientsModified
                                                ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified: APIv3.OnlineOrderItemIngredientModification) => ({
                                                    ID: ingredientsModified.ID,
                                                    OrderItemId: ingredientsModified.OrderItemId,
                                                    IngredientPLU: ingredientsModified.IngredientPLU,
                                                    ModifierID: ingredientsModified.ModifierID,
                                                    ModifierName: ingredientsModified.ModifierName,
                                                    ExtraPrice: ingredientsModified.ExtraPrice,
                                                    TaxBase: ingredientsModified.TaxBase,
                                                    Taxes: ingredientsModified.Taxes
                                                        ? ingredientsModified.Taxes.map((ingredientsTax: APIv3.TaxValue) => ({
                                                            TaxID: ingredientsTax.TaxID,
                                                            Value: ingredientsTax.Value,
                                                            IsInclusive: ingredientsTax.IsInclusive,
                                                        }))
                                                        : null,
                                                    GrossValue: ingredientsModified.GrossValue,
                                                    NettValue: ingredientsModified.NettValue,
                                                    SaleValueInitial: ingredientsModified.SaleValueInitial,
                                                    SaleValueFinal: ingredientsModified.SaleValueFinal,
                                                }))
                                                : null,
                                            IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                                ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved: APIv3.OnlineOrderItemIngredientRemoval) => ({
                                                    ID: ingredientsRemoved.ID,
                                                    OrderItemId: ingredientsRemoved.OrderItemId,
                                                    IngredientPLU: ingredientsRemoved.IngredientPLU,
                                                }))
                                                : null,
                                            IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                                ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded: APIv3.OnlineOrderItemIngredientAddition) => ({
                                                    ID: ingredientsAdded.ID,
                                                    OrderItemId: ingredientsAdded.OrderItemId,
                                                    IngredientPLU: ingredientsAdded.IngredientPLU,
                                                    ModifierID: ingredientsAdded.ModifierID,
                                                    ModifierName: ingredientsAdded.ModifierName,
                                                    ExtraPrice: ingredientsAdded.ExtraPrice,
                                                    TaxBase: ingredientsAdded.TaxBase,
                                                    Taxes: ingredientsAdded.Taxes
                                                        ? ingredientsAdded.Taxes.map((ingredientsAddedTax: APIv3.TaxValue) => ({
                                                            TaxID: ingredientsAddedTax.TaxID,
                                                            Value: ingredientsAddedTax.Value,
                                                            IsInclusive: ingredientsAddedTax.IsInclusive,
                                                        }))
                                                        : null,
                                                    GrossValue: ingredientsAdded.GrossValue,
                                                    NettValue: ingredientsAdded.NettValue,
                                                    SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                                    SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                                }))
                                                : null,
                                            IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                                ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped: APIv3.OnlineOrderItemIngredientSwap) => ({
                                                    ID: ingredientsSwapped.ID,
                                                    OrderItemId: ingredientsSwapped.OrderItemId,
                                                    OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                                    NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                                    ModifierID: ingredientsSwapped.ModifierID,
                                                    ModifierName: ingredientsSwapped.ModifierName,
                                                    ExtraPrice: ingredientsSwapped.ExtraPrice,
                                                    TaxBase: ingredientsSwapped.TaxBase,
                                                    Taxes: ingredientsSwapped.Taxes
                                                        ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax: APIv3.TaxValue) => ({
                                                            TaxID: ingredientsSwappedTax.TaxID,
                                                            Value: ingredientsSwappedTax.Value,
                                                            IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                        }))
                                                        : null,
                                                    GrossValue: ingredientsSwapped.GrossValue,
                                                    NettValue: ingredientsSwapped.NettValue,
                                                    SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                                    SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                                }))
                                                : null,
                                        }
                                        : null,
                                    IsHiddenFromUser: item.IsHiddenFromUser,
                                    TaxBase: item.TaxBase,
                                    Taxes: item.Taxes
                                        ? item.Taxes.map((tax: APIv3.TaxValue) => ({
                                            TaxID: tax.TaxID,
                                            Value: tax.Value,
                                            IsInclusive: tax.IsInclusive,
                                        }))
                                        : null,
                                    GrossValue: item.GrossValue,
                                    NettValue: item.NettValue,
                                    SaleValueInitial: item.SaleValueInitial,
                                    SaleValueFinal: item.SaleValueFinal,
                                }))
                                : null,
                            DisplayName: menuflow.DisplayName,
                            DisplayDescription: menuflow.DisplayDescription,
                            Quantity: menuflow.Quantity,
                            InitialQuantity: menuflow.InitialQuantity,
                            UnitPrice: menuflow.UnitPrice,
                            SpecialInstructions: menuflow.SpecialInstructions,
                            IsUpsell: menuflow.IsUpsell,
                            Discount: menuflow.Discount
                                ? {
                                    OnlineOrderId: menuflow.Discount.OnlineOrderId,
                                    OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                                    Value: menuflow.Discount.Value,
                                    DiscountType: menuflow.Discount.DiscountType,
                                    AmountApplied: menuflow.Discount.AmountApplied,
                                }
                                : null,
                            TotalValueInitial: menuflow.TotalValueInitial,
                        }))
                        : null,
                    OnlineDiscounts: data.OnlineDiscounts
                        ? data.OnlineDiscounts.map((item: APIv3.OnlineOrderDiscount) => ({
                            Id: item.Id,
                            OnlineOrderId: item.OnlineOrderId,
                            Value: item.Value,
                            DiscountType: item.DiscountType,
                            AmountApplied: item.AmountApplied,
                            VoucherCode: item.VoucherCode,
                        }))
                        : null,
                    ExternalPartners: data.ExternalPartners
                        ? data.ExternalPartners.map((item: APIv3.OnlineOrderExternalPartner) => ({
                            CustomerFacingMessage: item.CustomerFacingMessage,
                            OrderID: item.OrderID,
                            Slug: item.Slug,
                            MemberID: item.MemberID,
                            PriceLevelID: item.PriceLevelID,
                        }))
                        : null,
                    Surcharges: data.Surcharges
                        ? data.Surcharges.map((item: APIv3.OnlineOrderSurchargeBusinessModel) => ({
                            OnlineOrderId: item.OnlineOrderId,
                            OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                            CustomerFriendlyName: item.CustomerFriendlyName,
                            CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                            DisplayIndex: item.DisplayIndex,
                            IsVisibleForCustomer: item.IsVisibleForCustomer,
                            ProductId: item.ProductId,
                            PLU: item.PLU,
                            Value: item.Value,
                            SurchargeType: item.SurchargeType,
                            SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                            MediaNo: item.MediaNo,
                            AmountApplied: item.AmountApplied
                        }))
                        : null,
                    OrderTypeDetails: data.OrderTypeDetails
                        ? data.OrderTypeDetails.map((item: APIv3.OnlineOrderTypeDetailBusinessModel) => ({
                            OnlineOrderId: item.OnlineOrderId,
                            DetailsDefinitionId: item.DetailsDefinitionId,
                            CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                            ValueProvided: item.ValueProvided,
                        }))
                        : null,
                    OrderTypeDisclaimers: data.OrderTypeDisclaimers
                        ? data.OrderTypeDisclaimers.map((item: APIv3.OnlineOrderSaleDisclaimerBusinessModel) => ({
                            Id: item.Id,
                            OrderId: item.OrderId,
                            OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                            CustomerFriendlyName: item.CustomerFriendlyName,
                            CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                            DisplayIndex: item.DisplayIndex,
                            IsAccepted: item.IsAccepted,
                        }))
                        : null,
                    DeliveryDetails: data.DeliveryDetails
                        ? {
                            Street: data.DeliveryDetails.Street,
                            Building: data.DeliveryDetails.Building,
                            Unit: data.DeliveryDetails.Unit,
                            City: data.DeliveryDetails.City,
                            PostCode: data.DeliveryDetails.PostCode,
                            Latitude: data.DeliveryDetails.Latitude,
                            Longitude: data.DeliveryDetails.Longitude,
                            ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                            DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                            EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                        }
                        : null,
                    ActivatedVouchers: data.ActivatedVouchers
                        ? data.ActivatedVouchers.map((item: APIv3.ActivatedOrderVoucherDetails) => ({
                            VoucherName: item.VoucherName,
                            VoucherCode: item.VoucherCode,
                            VoucherId: item.VoucherId,
                            VoucherIssueId: item.VoucherIssueId,
                            Mode: item.Mode,
                            AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                            ActivatedMenuFlow: item.ActivatedMenuFlow,
                            ActivatedOrderDiscount: item.ActivatedOrderDiscount
                                ? {
                                    Id: item.ActivatedOrderDiscount.Id,
                                    OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                                    Value: item.ActivatedOrderDiscount.Value,
                                    DiscountType: item.ActivatedOrderDiscount.DiscountType,
                                    AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                                    VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                                }
                                : null,
                            ActivatedItemDiscount: item.ActivatedItemDiscount
                                ? {
                                    OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                                    OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                                    Value: item.ActivatedItemDiscount.Value,
                                    DiscountType: item.ActivatedItemDiscount.DiscountType,
                                    AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                                    DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                                }
                                : null,
                            VoucherType: item.VoucherType,
                        }))
                        : null,
                    VirtualLocations: data.VirtualLocations
                        ? data.VirtualLocations.map((location: APIv3.OnlineOrderVirtualLocationModel) => ({
                            LocationNo: location.LocationNo,
                            LocationClassification: location.LocationClassification,
                            LocationFriendlyName : location.LocationFriendlyName
                        }))
                        : null
                }))
                : null
        };

        mappedData.Items = mappedData.Items?.map((item) => Utils.OnlineOrders.onlineOrderModelFix(item)) || null;

        return mappedData;
    }

    public static mapOnlineOrderGETResponse(data: APIv3.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        if (!data) return null;

        const mappedData = {
            MemberFirstName: data.MemberFirstName,
            MemberLastName: data.MemberLastName,
            MemberMobilePhone: data.MemberMobilePhone,
            MemberMobilePhoneCountryID: data.MemberMobilePhoneCountryID,
            MemberHomePhone: data.MemberHomePhone,
            MemberEmail: data.MemberEmail,
            ExtraInstructions: data.ExtraInstructions,
            Barcode: data.Barcode,
            Id: data.Id,
            Source: data.Source,
            SaleName: data.SaleName,
            PickupLocation: data.PickupLocation,
            PickUpDate: data.PickUpDate,
            OrderedDate: data.OrderedDate,
            TableNumber: data.TableNumber,
            IsDelivery: data.IsDelivery,
            Status: data.Status,
            MemberId: data.MemberId,
            Covers: data.Covers,
            AddedDate: data.AddedDate,
            DisplayNumber: data.DisplayNumber,
            PartialMember: data.PartialMember
                ? {
                    FirstName: data.PartialMember.FirstName,
                    LastName: data.PartialMember.LastName,
                    Email: data.PartialMember.Email,
                    MobileNumber: data.PartialMember.MobileNumber,
                    MobileNumberCountryID: data.PartialMember.MobileNumberCountryID,
                }
                : null,
            ClerkId: data.ClerkId,
            OnlineOrderType: data.OnlineOrderType,
            MasterOrderID: data.MasterOrderID,
            SendToKMS: data.SendToKMS,
            SendToPrinters: data.SendToPrinters,
            TotalNettValue: data.TotalNettValue,
            TotalNettValueBeforeSaleDiscount: data.TotalNettValueBeforeSaleDiscount,
            TotalGrossValue: data.TotalGrossValue,
            TotalSaleValueInitial: data.TotalSaleValueInitial,
            TotalSaleValueFinal: data.TotalSaleValueFinal,
            TotalTaxes: data.TotalTaxes
                ? data.TotalTaxes.map((tax) => ({
                    TaxID: tax.TaxID,
                    Value: tax.Value,
                    IsInclusive: tax.IsInclusive,
                }))
                : null,
            TotalLeftToPay: data.TotalLeftToPay,
            TotalSaleDiscountAppliedAmount: data.TotalSaleDiscountAppliedAmount,
            TotalPaid: data.TotalPaid,
            OrderTypeId: data.OrderTypeId,
            TransactionId: data.TransactionId,
            IsRated: data.IsRated,
            SendReceiptOnEmail: data.SendReceiptOnEmail,
            LoyaltyAppId: data.LoyaltyAppId,
            ReceiptNotificationEmailAdresses: data.ReceiptNotificationEmailAdresses,
            FavouriteId: data.FavouriteId,
            POSPrepaid: data.POSPrepaid,
            Items: data.Items
                ? data.Items.map((item: OLO.DTO.OnlineOrderItemModel) => ({
                    FreeProductID: item.FreeProductID,
                    Id: item.Id,
                    DisplayName: item.DisplayName,
                    DisplayDescription: item.DisplayDescription,
                    OrderId: item.OrderId,
                    PLU: item.PLU,
                    Quantity: item.Quantity,
                    InitialQuantity: item.InitialQuantity,
                    ExcludeFromExternalPartnerPricing: item.ExcludeFromExternalPartnerPricing,
                    Type: item.Type,
                    Value: item.Value,
                    InitialUnitPrice: item.InitialUnitPrice,
                    UnitPrice: item.UnitPrice,
                    RedeemedProductId: item.RedeemedProductId,
                    IsRedeemedByPoints: item.IsRedeemedByPoints,
                    PointsValue: item.PointsValue,
                    VirtualLocations: item.VirtualLocations ? item.VirtualLocations.map(virtualLocation => ({
                        LocationNo: virtualLocation.LocationNo,
                        LocationClassification: virtualLocation.LocationClassification,
                        LocationFriendlyName : virtualLocation.LocationFriendlyName
                    })) : null,
                    Modifiers: item.Modifiers
                        ? item.Modifiers.map((modifier: OLO.DTO.OnlineOrderItemModifier) => ({
                            Id: modifier.Id,
                            OnlineOrderId: modifier.OnlineOrderId,
                            OnlineOrderItemId: modifier.OnlineOrderItemId,
                            RecipeId: modifier.RecipeId,
                            NewPlu: modifier.NewPlu,
                            OldPlu: modifier.OldPlu,
                            NewQty: modifier.NewQty,
                            OldQty: modifier.OldQty,
                        }))
                        : null,
                    IngredientsChanges: item.IngredientsChanges
                        ? {
                            IngredientsModified: item.IngredientsChanges.IngredientsModified
                                ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                    ID: ingredientsModified.ID,
                                    OrderItemId: ingredientsModified.OrderItemId,
                                    IngredientPLU: ingredientsModified.IngredientPLU,
                                    ModifierID: ingredientsModified.ModifierID,
                                    ModifierName: ingredientsModified.ModifierName,
                                    ExtraPrice: ingredientsModified.ExtraPrice,
                                    TaxBase: ingredientsModified.TaxBase,
                                    Taxes: ingredientsModified.Taxes
                                        ? ingredientsModified.Taxes.map((tax) => ({
                                            TaxID: tax.TaxID,
                                            Value: tax.Value,
                                            IsInclusive: tax.IsInclusive,
                                        }))
                                        : null,
                                    GrossValue: ingredientsModified.GrossValue,
                                    NettValue: ingredientsModified.NettValue,
                                    SaleValueInitial: ingredientsModified.SaleValueInitial,
                                    SaleValueFinal: ingredientsModified.SaleValueFinal,
                                }))
                                : null,
                            IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                    ID: ingredientsRemoved.ID,
                                    OrderItemId: ingredientsRemoved.OrderItemId,
                                    IngredientPLU: ingredientsRemoved.IngredientPLU,
                                }))
                                : null,
                            IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                    ID: ingredientsAdded.ID,
                                    OrderItemId: ingredientsAdded.OrderItemId,
                                    IngredientPLU: ingredientsAdded.IngredientPLU,
                                    ModifierID: ingredientsAdded.ModifierID,
                                    ModifierName: ingredientsAdded.ModifierName,
                                    ExtraPrice: ingredientsAdded.ExtraPrice,
                                    TaxBase: ingredientsAdded.TaxBase,
                                    Taxes: ingredientsAdded.Taxes
                                        ? ingredientsAdded.Taxes.map((tax: APIv3.TaxValue) => ({
                                            TaxID: tax.TaxID,
                                            Value: tax.Value,
                                            IsInclusive: tax.IsInclusive,
                                        }))
                                        : null,
                                    GrossValue: ingredientsAdded.GrossValue,
                                    NettValue: ingredientsAdded.NettValue,
                                    SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                    SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                }))
                                : null,
                            IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                    ID: ingredientsSwapped.ID,
                                    OrderItemId: ingredientsSwapped.OrderItemId,
                                    OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                    NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                    ModifierID: ingredientsSwapped.ModifierID,
                                    ModifierName: ingredientsSwapped.ModifierName,
                                    ExtraPrice: ingredientsSwapped.ExtraPrice,
                                    TaxBase: ingredientsSwapped.TaxBase,
                                    Taxes: ingredientsSwapped.Taxes
                                        ? ingredientsSwapped.Taxes.map((tax) => ({
                                            TaxID: tax.TaxID,
                                            Value: tax.Value,
                                            IsInclusive: tax.IsInclusive,
                                        }))
                                        : null,
                                    GrossValue: ingredientsSwapped.GrossValue,
                                    NettValue: ingredientsSwapped.NettValue,
                                    SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                    SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                }))
                                : null,
                        }
                        : null,
                    SpecialInstructions: item.SpecialInstructions,
                    IsHiddenFromUser: item.IsHiddenFromUser,
                    Discount: item.Discount
                        ? {
                            OnlineOrderId: item.Discount.OnlineOrderId,
                            OnlineOrderItemId: item.Discount.OnlineOrderItemId,
                            Value: item.Discount.Value,
                            DiscountType: item.Discount.DiscountType,
                            AmountApplied: item.Discount.AmountApplied,
                            DiscountedItemPLU: item.Discount.DiscountedItemPLU,
                        }
                        : null,
                    VoucherId: item.VoucherId,
                    VoucherCode: item.VoucherCode,
                    TaxBase: item.TaxBase,
                    Taxes: item.Taxes
                        ? item.Taxes.map((tax) => ({
                            TaxID: tax.TaxID,
                            Value: tax.Value,
                            IsInclusive: tax.IsInclusive,
                        }))
                        : null,
                    GrossValue: item.GrossValue,
                    NettValue: item.NettValue,
                    SaleValueInitial: item.SaleValueInitial,
                    SaleValueFinal: item.SaleValueFinal,
                }))
                : null,
            Medias: data.Medias
                ? data.Medias.map((medias) => ({
                    Id: medias.Id,
                    CustomerFriendlyName: medias.CustomerFriendlyName,
                    MediaDescription: medias.MediaDescription,
                    MediaId: medias.MediaId,
                    OrderId: medias.OrderId,
                    Value: medias.Value,
                    PaymentType: medias.PaymentType,
                    PaymentTransactionId: medias.PaymentTransactionId,
                    IsTax: medias.IsTax,
                }))
                : null,
            MenuFlowActivations: data.MenuFlowActivations
                ? data.MenuFlowActivations.map((menuflow: OLO.DTO.OnlineOrderMenuFlowActivation) => ({
                    Id: menuflow.Id,
                    OnlineOrderId: menuflow.OnlineOrderId,
                    MenuFlowId: menuflow.MenuFlowId,
                    ActivationGuid: menuflow.ActivationGuid, // uuid
                    Value: menuflow.Value,
                    SaleValueInitial: menuflow.SaleValueInitial,
                    InitialUnitPrice: menuflow.InitialUnitPrice,
                    TotalValue: menuflow.TotalValue,
                    TotalValueInitial: menuflow.TotalValueInitial,
                    VirtualLocations: menuflow.VirtualLocations ? menuflow.VirtualLocations.map(virtualLocation => ({
                        LocationNo: virtualLocation.LocationNo,
                        LocationClassification: virtualLocation.LocationClassification,
                        LocationFriendlyName : virtualLocation.LocationFriendlyName
                    })) : null,
                    MenuFlowItems: menuflow.MenuFlowItems
                        ? menuflow.MenuFlowItems.map((item) => ({
                            Id: item.Id,
                            OnlineOrderMenuFlowActivationId: item.OnlineOrderMenuFlowActivationId,
                            OnlineOrderItemId: item.OnlineOrderItemId,
                            MenuFlowPageId: item.MenuFlowPageId,
                            PLU: item.PLU,
                            Quantity: item.Quantity,
                            Type: item.Type,
                            Value: item.Value,
                            DisplayName: item.DisplayName,
                            DisplayDescription: item.DisplayDescription,
                            UnitPrice: item.UnitPrice,
                            SpecialInstructions: item.SpecialInstructions,
                            IngredientsChanges: item.IngredientsChanges
                                ? {
                                    IngredientsModified: item.IngredientsChanges.IngredientsModified
                                        ? item.IngredientsChanges.IngredientsModified.map((ingredientsModified) => ({
                                            ID: ingredientsModified.ID,
                                            OrderItemId: ingredientsModified.OrderItemId,
                                            IngredientPLU: ingredientsModified.IngredientPLU,
                                            ModifierID: ingredientsModified.ModifierID,
                                            ModifierName: ingredientsModified.ModifierName,
                                            ExtraPrice: ingredientsModified.ExtraPrice,
                                            TaxBase: ingredientsModified.TaxBase,
                                            Taxes: ingredientsModified.Taxes
                                                ? ingredientsModified.Taxes.map((ingredientsTax) => ({
                                                    TaxID: ingredientsTax.TaxID,
                                                    Value: ingredientsTax.Value,
                                                    IsInclusive: ingredientsTax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsModified.GrossValue,
                                            NettValue: ingredientsModified.NettValue,
                                            SaleValueInitial: ingredientsModified.SaleValueInitial,
                                            SaleValueFinal: ingredientsModified.SaleValueFinal,
                                        }))
                                        : null,
                                    IngredientsRemoved: item.IngredientsChanges.IngredientsRemoved
                                        ? item.IngredientsChanges.IngredientsRemoved.map((ingredientsRemoved) => ({
                                            ID: ingredientsRemoved.ID,
                                            OrderItemId: ingredientsRemoved.OrderItemId,
                                            IngredientPLU: ingredientsRemoved.IngredientPLU,
                                        }))
                                        : null,
                                    IngredientsAdded: item.IngredientsChanges.IngredientsAdded
                                        ? item.IngredientsChanges.IngredientsAdded.map((ingredientsAdded) => ({
                                            ID: ingredientsAdded.ID,
                                            OrderItemId: ingredientsAdded.OrderItemId,
                                            IngredientPLU: ingredientsAdded.IngredientPLU,
                                            ModifierID: ingredientsAdded.ModifierID,
                                            ModifierName: ingredientsAdded.ModifierName,
                                            ExtraPrice: ingredientsAdded.ExtraPrice,
                                            TaxBase: ingredientsAdded.TaxBase,
                                            Taxes: ingredientsAdded.Taxes
                                                ? ingredientsAdded.Taxes.map((ingredientsAddedTax) => ({
                                                    TaxID: ingredientsAddedTax.TaxID,
                                                    Value: ingredientsAddedTax.Value,
                                                    IsInclusive: ingredientsAddedTax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsAdded.GrossValue,
                                            NettValue: ingredientsAdded.NettValue,
                                            SaleValueInitial: ingredientsAdded.SaleValueInitial,
                                            SaleValueFinal: ingredientsAdded.SaleValueFinal,
                                        }))
                                        : null,
                                    IngredientsSwapped: item.IngredientsChanges.IngredientsSwapped
                                        ? item.IngredientsChanges.IngredientsSwapped.map((ingredientsSwapped) => ({
                                            ID: ingredientsSwapped.ID,
                                            OrderItemId: ingredientsSwapped.OrderItemId,
                                            OldIngredientPLU: ingredientsSwapped.OldIngredientPLU,
                                            NewIngredientPLU: ingredientsSwapped.NewIngredientPLU,
                                            ModifierID: ingredientsSwapped.ModifierID,
                                            ModifierName: ingredientsSwapped.ModifierName,
                                            ExtraPrice: ingredientsSwapped.ExtraPrice,
                                            TaxBase: ingredientsSwapped.TaxBase,
                                            Taxes: ingredientsSwapped.Taxes
                                                ? ingredientsSwapped.Taxes.map((ingredientsSwappedTax) => ({
                                                    TaxID: ingredientsSwappedTax.TaxID,
                                                    Value: ingredientsSwappedTax.Value,
                                                    IsInclusive: ingredientsSwappedTax.IsInclusive,
                                                }))
                                                : null,
                                            GrossValue: ingredientsSwapped.GrossValue,
                                            NettValue: ingredientsSwapped.NettValue,
                                            SaleValueInitial: ingredientsSwapped.SaleValueInitial,
                                            SaleValueFinal: ingredientsSwapped.SaleValueFinal,
                                        }))
                                        : null,
                                }
                                : null,
                            IsHiddenFromUser: item.IsHiddenFromUser,
                            TaxBase: item.TaxBase,
                            Taxes: item.Taxes
                                ? item.Taxes.map((tax) => ({
                                    TaxID: tax.TaxID,
                                    Value: tax.Value,
                                    IsInclusive: tax.IsInclusive,
                                }))
                                : null,
                            GrossValue: item.GrossValue,
                            NettValue: item.NettValue,
                            SaleValueInitial: item.SaleValueInitial,
                            SaleValueFinal: item.SaleValueFinal,
                            TotalValueInit: item.TotalValueInit,
                        }))
                        : null,
                    DisplayName: menuflow.DisplayName,
                    DisplayDescription: menuflow.DisplayDescription,
                    Quantity: menuflow.Quantity,
                    InitialQuantity: menuflow.InitialQuantity,
                    UnitPrice: menuflow.UnitPrice,
                    SpecialInstructions: menuflow.SpecialInstructions,
                    IsUpsell: menuflow.IsUpsell,
                    Discount: menuflow.Discount
                        ? {
                            OnlineOrderId: menuflow.Discount.OnlineOrderId,
                            OnlineOrderMFActivationID: menuflow.Discount.OnlineOrderMFActivationID,
                            Value: menuflow.Discount.Value,
                            DiscountType: menuflow.Discount.DiscountType,
                            AmountApplied: menuflow.Discount.AmountApplied,
                        }
                        : null,
                }))
                : null,
            OnlineDiscounts: data.OnlineDiscounts
                ? data.OnlineDiscounts.map((item: OLO.DTO.OnlineOrderDiscount) => ({
                    Id: item.Id,
                    OnlineOrderId: item.OnlineOrderId,
                    Value: item.Value,
                    DiscountType: item.DiscountType,
                    AmountApplied: item.AmountApplied,
                    VoucherCode: item.VoucherCode,
                }))
                : null,
            ExternalPartners: data.ExternalPartners
                ? data.ExternalPartners.map((item: OLO.DTO.OnlineOrderExternalPartner) => ({
                    CustomerFacingMessage: item.CustomerFacingMessage,
                    OrderID: item.OrderID,
                    Slug: item.Slug,
                    MemberID: item.MemberID,
                    PriceLevelID: item.PriceLevelID,
                }))
                : null,
            Surcharges: data.Surcharges
                ? data.Surcharges.map((item: OLO.DTO.OnlineOrderSurchargeBusinessModel) => ({
                    OnlineOrderId: item.OnlineOrderId,
                    OrderSurchargeDefinitionId: item.OrderSurchargeDefinitionId,
                    CustomerFriendlyName: item.CustomerFriendlyName,
                    CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                    DisplayIndex: item.DisplayIndex,
                    IsVisibleForCustomer: item.IsVisibleForCustomer,
                    ProductId: item.ProductId,
                    PLU: item.PLU,
                    Value: item.Value,
                    SurchargeType: item.SurchargeType,
                    SurchargeIsTaxExempt: item.SurchargeIsTaxExempt,
                    MediaNo: item.MediaNo,
                    AmountApplied: item.AmountApplied
                }))
                : null,
            OrderTypeDetails: data.OrderTypeDetails
                ? data.OrderTypeDetails.map((item: OLO.DTO.OnlineOrderTypeDetailBusinessModel) => ({
                    OnlineOrderId: item.OnlineOrderId,
                    DetailsDefinitionId: item.DetailsDefinitionId,
                    CustomerFriendlyValueName: item.CustomerFriendlyValueName,
                    ValueProvided: item.ValueProvided,
                }))
                : null,
            OrderTypeDisclaimers: data.OrderTypeDisclaimers
                ? data.OrderTypeDisclaimers.map((item: OLO.DTO.OnlineOrderSaleDisclaimerBusinessModel) => ({
                    Id: item.Id,
                    OrderId: item.OrderId,
                    OrderDisclaimerDefinitionId: item.OrderDisclaimerDefinitionId,
                    CustomerFriendlyName: item.CustomerFriendlyName,
                    CustomerFriendlyDescription: item.CustomerFriendlyDescription,
                    DisplayIndex: item.DisplayIndex,
                    IsAccepted: item.IsAccepted,
                }))
                : null,
            DeliveryDetails: data.DeliveryDetails
                ? {
                    Street: data.DeliveryDetails.Street,
                    Building: data.DeliveryDetails.Building,
                    Unit: data.DeliveryDetails.Unit,
                    City: data.DeliveryDetails.City,
                    PostCode: data.DeliveryDetails.PostCode,
                    Latitude: data.DeliveryDetails.Latitude,
                    Longitude: data.DeliveryDetails.Longitude,
                    ExtraInstructions: data.DeliveryDetails.ExtraInstructions,
                    DesiredDeliveryTime: data.DeliveryDetails.DesiredDeliveryTime, // date-time
                    EstimatedDeliveryTime: data.DeliveryDetails.EstimatedDeliveryTime,
                }
                : null,
            ActivatedVouchers: data.ActivatedVouchers
                ? data.ActivatedVouchers.map((item: OLO.DTO.ActivatedOrderVoucherDetails) => ({
                    VoucherName: item.VoucherName,
                    VoucherCode: item.VoucherCode,
                    VoucherId: item.VoucherId,
                    VoucherIssueId: item.VoucherIssueId,
                    Mode: item.Mode,
                    AddedVoucherProductsPLUs: [...item.AddedVoucherProductsPLUs],
                    ActivatedMenuFlow: item.ActivatedMenuFlow,
                    ActivatedOrderDiscount: item.ActivatedOrderDiscount
                        ? {
                            Id: item.ActivatedOrderDiscount.Id,
                            OnlineOrderId: item.ActivatedOrderDiscount.OnlineOrderId,
                            Value: item.ActivatedOrderDiscount.Value,
                            DiscountType: item.ActivatedOrderDiscount.DiscountType,
                            AmountApplied: item.ActivatedOrderDiscount.AmountApplied,
                            VoucherCode: item.ActivatedOrderDiscount.VoucherCode,
                        }
                        : null,
                    ActivatedItemDiscount: item.ActivatedItemDiscount
                        ? {
                            OnlineOrderId: item.ActivatedItemDiscount.OnlineOrderId,
                            OnlineOrderItemId: item.ActivatedItemDiscount.OnlineOrderItemId,
                            Value: item.ActivatedItemDiscount.Value,
                            DiscountType: item.ActivatedItemDiscount.DiscountType,
                            AmountApplied: item.ActivatedItemDiscount.AmountApplied,
                            DiscountedItemPLU: item.ActivatedItemDiscount.DiscountedItemPLU,
                        }
                        : null,
                    VoucherType: item.VoucherType,
                }))
                : null,
            CancelledDate: data.CanceledDate,
            VirtualLocations: data.VirtualLocations
        };

        return Utils.OnlineOrders.onlineOrderModelFix(mappedData);
    }
}
