import { createSelector } from '@ngrx/store';
import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';
import { getCardConnectLocationConfig, getActiveCardDetails } from '../../../creditCards/selectors';

/**
 * Checks if current config and active card require filling billing details.
 * If null is returned, data is being downloaded and should wait for boolean value
 */
export const isBillingDetailsForCardRequired = createSelector(getActiveCardDetails, getCardConnectLocationConfig, (activeCard, cardConnectLocationConfig) => {
    const isBillingSupported = Utils.BillingDetails.isBillingDetailsSupported();
    if (!isBillingSupported || activeCard?.BillingDetails) {
        return false;
    }

    const {
        payments: { baseProvider },
    } = new Statics.ConfigStatic().current;

    if (baseProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT) {
        if (!cardConnectLocationConfig.data) {
            return null;
        }

        return cardConnectLocationConfig.data.IsAvsEnabled;
    }

    /**
     * True for FatZebra if billing address is required
     */
    return true;
});
