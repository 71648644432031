import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';


import * as Tokens from '@shared/core/tokens';

import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    public currentPosition: GeolocationCoordinates | boolean;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        protected _store: Store<OLO.State>
    ) {
        this.initGeolocationAfterAllowing();
    }

    public getUserCoords(): Promise<GeolocationCoordinates> {
        return new Promise((resolve, reject) => {

            if (this.currentPosition) {
                resolve(this.currentPosition as GeolocationCoordinates);
            }

            if (!navigator.geolocation) {
                this.currentPosition = false;
                reject('GeolocationError');
            }

            navigator.geolocation.getCurrentPosition(
                ({ coords }) => {
                    this.currentPosition = {
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                        altitude: coords.altitude,
                        accuracy: coords.accuracy,
                        altitudeAccuracy: coords.altitudeAccuracy,
                        heading: coords.heading,
                        speed: coords.speed
                    };

                    resolve(this.currentPosition);

                },
                (error) => {
                    this.currentPosition = false;
                    reject(error);
                });
        });
    }

    public requestUserGeolocationCoordinates(): void {
        this._store.pipe(
            select(
                selectors.getGeolocationState
            ),
            take(1)
        ).subscribe(state => {
            if (state.isRequesting || state.hasFailed || state.hasSucceeded) return;

            this._store.dispatch(actions.geolocationPositionRequest());
        });
    }

    public initGeolocationAfterAllowing(): void {
        if (navigator.permissions) {
            try {
                navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {

                    permissionStatus.onchange = () => {
                        const state = permissionStatus.state as OLO.Enums.GEOLOCATION_ACCESSS_STATUS;
                        if (state === OLO.Enums.GEOLOCATION_ACCESSS_STATUS.GRANTED) {
                            this._store.dispatch(actions.geolocationPositionRequest());
                        }
                    };
                });
            } catch (err) {
                console.error('Browser navigator api error', err);
            }
        }
    }
}
