import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Models from '@shared/core/models';

import { never, Observable, combineLatest, of } from 'rxjs';
import { take, map, catchError, withLatestFrom, switchMap, filter, auditTime } from 'rxjs/operators';


@Injectable()
export class OnlineOrdersEffects {
    @Effect() public saveOrderConfirmationUrl$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSaveConfirmationUrlRequest),
        switchMap(({ orderId, url }) => {
            const model: Models.OnlineOrderUrlModel = new Models.OnlineOrderUrlModel(orderId, url);

            return this._onlineOrdersService.insertOnlineOrderUrl(model).pipe(
                map((payload) =>
                    actions.OnlineOrderSaveConfirmationUrlSuccessRequest({
                        orderId,
                        url,
                        payload,
                    }),
                ),
                catchError((ex) => {
                    console.error('Unable to save order\'s confirmation url', ex);

                    return of(
                        actions.OnlineOrderSaveConfirmationUrlErrorRequest({
                            orderId,
                            url,
                            ex,
                        }),
                    );
                }),
            );
        }),
    );

    @Effect() public onOnlineOrderCreateRequestSuccess$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderCreateSuccessRequest),
        withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
        switchMap(([action, isAuthorized]) => {
            if (!isAuthorized) {
                const historyOrdersCache: OLO.DTO.OnlineOrderDetailedBusinessModel[] = JSON.parse(
                    this._cryptoService.decrypt(Utils.Storage.getItem(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA))
                );
                const updatedHistoryOrdersCache = [...historyOrdersCache, action.payload];
                /* Requirement that we should keep only 5 orders in cache - TOLO-2565 */
                const newHistoryOrdersCache = updatedHistoryOrdersCache.length > 5
                    ? updatedHistoryOrdersCache.slice(1)
                    : updatedHistoryOrdersCache;

                Utils.Storage.set(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA, this._cryptoService.encrypt(JSON.stringify(newHistoryOrdersCache)));
            }

            return [];
        })
    );

    public onOnlineOrderCreateDuplicationCheckForCard$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineOrderCreateDuplicationCheckForCard),
            switchMap((action) => {
                const orderId = action.previousOrderInfo.Id;
                const transactionId = action.previousOrderInfo.TransactionId;
                const orderStatus = this._onlineOrdersService.getOnlineOrderStatus(orderId, true).pipe(
                    catchError((error) => {
                        console.error('Error fetching order status', error);

                        return [];
                    }),
                );
                const paymentStatus = this._paymentService.getPaymentStatus(transactionId).pipe(
                    catchError((error) => {
                        console.error('Error fetching payment status', error);

                        return [];
                    }),
                );

                return combineLatest([of(action), orderStatus, paymentStatus]);
            }),
            map(([action, orderStatus, payment]) => ({ orderStatus, action, duplicateFlags: this._getDuplicateOrderFlags(action, orderStatus, payment) })),
            switchMap(({ action, duplicateFlags }) => {
                const { isRecentSameOrder, isDuringPaymentProcedure, isAlreadyPayed, isImproperOrder } = duplicateFlags;
                const orderId = action.previousOrderInfo.Id;

                if (isRecentSameOrder && isDuringPaymentProcedure) {
                    this._store.dispatch(actions.PaymentStepPaymentStatusCheck({ TransactionId: action.previousOrderInfo.TransactionId, OrderId: orderId }));

                    this._modalsServicService.show({
                        type: 'order-unprocessed-payment',
                        params: {
                            orderModel: action.model,
                            transactionId: action.previousOrderInfo.TransactionId,
                        },
                    });

                    return [];
                }

                if (isRecentSameOrder && isAlreadyPayed) {
                    this._modalsServicService.show({
                        type: 'order-duplicate-warn',
                        params: {
                            orderModel: action.model,
                        },
                    });

                    return [];
                }

                if (isImproperOrder) {
                    this._onlineOrdersService.cancelOnlineOrder(action.model.Id);
                }

                this._onlineOrdersService.clearSavedOrderInfo();

                this._onlineOrdersService.createNewOnlineOrderWithBaseInfoSave(action.model);

                return [];
            }),
        ),
    );

    public onOnlineOrderCreateDuplicationCheckForPayWithAccountCharge$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineOrderCreateDuplicationCheckForPayWithAccountCharge),
            switchMap((action) => {
                const orderId = action.previousOrderInfo.Id;
                const orderStatus = this._onlineOrdersService.getOnlineOrderStatus(orderId, true).pipe(
                    catchError((error) => {
                        console.error('Error fetching order status', error);

                        return [];
                    }),
                );

                return combineLatest([of(action), orderStatus]);
            }),
            map(([action, orderStatus]) => ({ action, duplicateFlags: this._getDuplicateOrderFlags(action, orderStatus) })),
            switchMap(({ action, duplicateFlags }) => {
                if (!duplicateFlags) return [];

                const { isSameGross, isRecentSameOrder, isAlreadyPayed } = duplicateFlags;

                if (isSameGross && isRecentSameOrder && isAlreadyPayed) {
                    this._modalsServicService.show({
                        type: 'order-duplicate-warn',
                        params: {
                            orderModel: action.model,
                        },
                    });

                    return [];
                }

                this._onlineOrdersService.createNewOnlineOrderWithBaseInfoSave(action.model);

                return [];
            }),
        ),
    );

    @Effect() public onOnlineOrderCreateRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderCreateRequest),
        withLatestFrom(
            this._store.select(selectors.getGuestData),
            this._store.select(selectors.getCurrentMember),
            this._store.select(selectors.isZeroDolarPayment),
            this._store.select(selectors.isCartZeroPrice),
            this._store.select(selectors.isPayInStoreSelected),
            this._store.select(selectors.isAccountChargeSelected),
        ),
        switchMap(([, guestData, memberData, isZeroDolarPayment, isZeroPricedOrder, isPayInStoreSelected, isAccountChargeSelected]) =>
            this._store.pipe(
                select(selectors.getOnlineOrderRecalcData),
                filter((calculatedOrder) => calculatedOrder.isRecalculating === false),
                take(1),
                withLatestFrom(
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId))
                ),
                switchMap(([calculatedOrder, orderState, LoyaltyAppId]) => {
                    if (calculatedOrder.hasFailed || !calculatedOrder.data) throw new Error('Invalid recalculated order data');

                    const MemberId = memberData ? memberData.UserId || memberData.MemberId : null;

                    const Status = isZeroDolarPayment || isZeroPricedOrder || isPayInStoreSelected || this._config.demoMode === true
                        ? OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED : calculatedOrder.data.Status;

                    const model: OLO.DTO.OnlineOrderDetailedBusinessModel = {
                        ...calculatedOrder.data,
                        Id: null,
                        MemberId: MemberId || null,
                        PartialMember: guestData || null,
                        LoyaltyAppId,
                        Status,
                        SendReceiptOnEmail: this._config.onlineOrders?.sendAutoReceiptEmail === true,
                        ReceiptNotificationEmailAdresses: [
                            guestData?.Email || memberData?.Email
                        ].filter(obj => obj),
                        ...Utils.OnlineOrders.extendWithOrderTypeDisclaimers(calculatedOrder.data, orderState.orderType)
                    };

                    const previousOrderInfo = Utils.Storage.getItem(OLO.Enums.CART_STORAGE.ORDER) && JSON.parse(Utils.Storage.getItem(OLO.Enums.CART_STORAGE.ORDER));

                    switch (true) {
                        case !!(previousOrderInfo?.Id && previousOrderInfo?.TransactionId && previousOrderInfo?.MemberId === MemberId):
                            return of(actions.OnlineOrderCreateDuplicationCheckForCard({ model: model, previousOrderInfo: previousOrderInfo }));
                        case !!(isAccountChargeSelected && previousOrderInfo?.Id && previousOrderInfo?.MemberId === MemberId):
                            return of(actions.OnlineOrderCreateDuplicationCheckForPayWithAccountCharge({ model: model, previousOrderInfo: previousOrderInfo }));
                        default:
                            return this._onlineOrdersService.createNewOnlineOrder(model).pipe(
                                map((payload) => {
                                    this._onlineOrdersService.saveOrderBaseInfo({
                                        OrderedDate: payload.OrderedDate,
                                        TotalGrossValue: payload.TotalGrossValue,
                                        Id: payload.Id,
                                        MemberId: payload.MemberId,
                                    });

                                    return actions.OnlineOrderCreateSuccessRequest({ payload });
                                }),
                            );
                    }
                }),
                catchError((ex) => of(actions.OnlineOrderCreateErrorRequest({ order: null, ex }))),
            ),
        ),
    );

    @Effect() public onOnlineOrderSendConfirmationEmail$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendConfrimationEmailRequest),
        switchMap((action) =>
            this._onlineOrdersService.sendEmailWithOrderConfirmation(action.orderId).pipe(
                map((result) => (result ? actions.OnlineOrderSendConfrimationEmailSuccessRequest({ result }) : actions.OnlineOrderSendConfrimationEmailErrorRequest({}))),
                catchError((ex) => of(actions.OnlineOrderSendConfrimationEmailErrorRequest({ ex }))),
            ),
        ),
    );

    @Effect() public onOnlineOrderRecalculateRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderRecalculateRequest),
        switchMap(() => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCartTotalValue),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                    this._store.pipe(select(selectors.getVirtualLocations))
                ),
                switchMap(([, cart,, member, orderState, LoyaltyAppId, virtualLocations]) => {
                    const isDelivery = Utils.OnlineOrders.detectOrderCollectionTypeGroup(orderState.data) === OLO.Enums.COLLECTION_TYPE.DELIVERY;
                    const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.onlineOrders.saleName, cart, cart.pickupTime, {
                        MemberId: member ? member.MemberId : null,
                        RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                        VirtualLocations: Utils.OnlineOrders.extendOnlineOrderWithVirtualLocations(cart, virtualLocations)
                    }, virtualLocations);

                    const orderModel: OLO.DTO.OnlineOrderDetailedBusinessModel = {
                        ..._tempOrderModel,
                        ...Utils.OnlineOrders.extendWithOrderTypeDisclaimers(_tempOrderModel, orderState.orderType),
                        LoyaltyAppId,
                        ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers,
                        IsDelivery: isDelivery,
                        DeliveryDetails: isDelivery ? orderState?.data?.DeliveryDetails : null,
                    };

                    return this._onlineOrdersService.recalculateOnlineOrder(orderModel).pipe(
                        map((payload) => actions.OnlineOrderRecalculateSuccessRequest({ payload })),
                        catchError((ex) => of(actions.OnlineOrderRecalculateErrorRequest({ order: orderModel, ex }))),
                    );
                })
            ))
        ,
    );

    @Effect() public onOnlineOrderAddVoucherRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderAddVoucherRequest),
        switchMap((AddVoucherRequestAction) => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getOnlineOrderVoucherCode),
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                    this._store.pipe(select(selectors.getVirtualLocations))
                ),
                switchMap(([, VoucherCode, cart, member, orderState, LoyaltyAppId, virtualLocations]) => {
                    const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.onlineOrders.saleName, cart, cart.pickupTime, {
                        MemberId: member ? member.MemberId : null,
                        RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                        VirtualLocations: Utils.OnlineOrders.extendOnlineOrderWithVirtualLocations(cart, virtualLocations)
                    }, virtualLocations);
                    const orderModel: OLO.DTO.ActivateVoucherRequest = {
                        Order: {
                            ..._tempOrderModel,
                            ...Utils.OnlineOrders.extendWithOrderTypeDisclaimers(_tempOrderModel, orderState.orderType),
                            LoyaltyAppId,
                            ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers
                        },
                        VoucherCode,
                        ActivatedEntityId: null // TODO Check this value
                    };

                    return this._onlineOrdersService.addVoucherOnlineOrder(orderModel).pipe(
                        switchMap((payload) => {

                            this._modalsServicService.close(AddVoucherRequestAction.id);

                            return [
                                actions.OnlineOrderAddVoucherSuccessRequest({ code: VoucherCode, payload }),
                                actions.CartAddActivatedVoucher({ payload })
                            ];
                        }),
                        catchError((ex) => of(actions.OnlineOrderAddVoucherErrorRequest({ order: orderModel.Order, ex, staticText: this._t }))),
                    );
                })
            ))
        ,
    );

    @Effect() public onOnlineOrderRemoveVoucherRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderRemoveVoucherRequest),
        switchMap(() => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getActiveVoucher),
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                    this._store.pipe(select(selectors.getVirtualLocations))
                ),
                switchMap(([, voucher, cart, member, orderState, LoyaltyAppId, virtualLocations]) => {
                    if (voucher) {
                        const tempOrderModel = Utils.OnlineOrders.convertCart(this._config.onlineOrders.saleName, cart, cart.pickupTime, {
                            MemberId: member ? member.MemberId : null,
                            RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                            VirtualLocations: Utils.OnlineOrders.extendOnlineOrderWithVirtualLocations(cart, virtualLocations)
                        }, virtualLocations);

                        const orderModel: OLO.DTO.DeactivateVoucherRequest = {
                            Order: {
                                ...tempOrderModel,
                                ...Utils.OnlineOrders.extendWithOrderTypeDisclaimers(tempOrderModel, orderState.orderType),
                                LoyaltyAppId,
                                ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers
                            },
                            VoucherCode: voucher?.VoucherCode
                        };

                        return this._onlineOrdersService.removeVoucherOnlineOrder(orderModel).pipe(
                            switchMap((payload) => [
                                actions.OnlineOrderRemoveVoucherSuccessRequest({ payload }),
                                actions.CartRemoveActivatedVoucher()]
                            ),
                            catchError((ex) => of(actions.OnlineOrderRemoveVoucherErrorRequest({ order: orderModel.Order, ex }))),
                        );
                    } else {
                        return never();
                    }
                })
            ))
        ,
    );

    @Effect() public onlineOrderRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderRequest),
        switchMap(({ orderId }) =>
            this._onlineOrdersService.getOnlineOrder(orderId).pipe(
                map((payload) => actions.OnlineOrderSuccessRequest({ payload })),
                catchError((ex) => of(actions.OnlineOrderErrorRequest({ orderId, ex }))),
            ),
        ),
    );

    @Effect() public updateOnlineOrderRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderUpdateRequest),
        switchMap((onlineOrderUpdatePayload) =>
            this._onlineOrdersService.updateOnlineOrder(onlineOrderUpdatePayload.order).pipe(
                switchMap(() => [
                    actions.OnlineOrderUpdateSuccessRequest({ payload: onlineOrderUpdatePayload.order }),
                    actions.HistoryOrderRequest({ orderId: onlineOrderUpdatePayload.order.Id })
                ]),
                catchError((ex) => of(actions.OnlineOrderUpdateErrorRequest({ orderId: onlineOrderUpdatePayload.order.OrderTypeId, ex }))),
            ),
        ),
    );

    @Effect() public cancelOnlineOrderRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderCancelRequest),
        switchMap((onlineOrderCancelPayload) =>
            this._onlineOrdersService.cancelOnlineOrder(onlineOrderCancelPayload.orderId).pipe(
                map((payload) => actions.OnlineOrderCancelSuccessRequest({
                    payload,
                    orderId: onlineOrderCancelPayload.orderId,
                    updateHistoryOrder: onlineOrderCancelPayload.updateHistoryOrder
                })),
                catchError((ex) => of(actions.OnlineOrderCancelErrorRequest({ orderId: onlineOrderCancelPayload.orderId, ex }))),
            ),
        ),
    );

    @Effect() public sendOrderReceipt$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendEmailReceiptRequest),
        switchMap(({ orderId }) =>
            this._onlineOrdersService.sendOnlineOrderReceipt(orderId).pipe(
                map((response) => (response ? actions.OnlineOrderSendEmailReceiptSuccessRequest({ orderId }) : actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId }))),
                catchError((ex) => {
                    console.log('Send order receipt error', ex);

                    return of(actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId, ex }));
                }),
            ),
        ),
    );

    @Effect() public resetReceiptEmailSendButton$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendEmailReceiptSuccessRequest),
        auditTime(1000),
        switchMap(() => of(actions.OnlineOrderSendEmailReceiptSuccessReset()))
    );

    @Effect() public onCartDeliveryAddressSet$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartSetDeliveryAddress),
        withLatestFrom(this._store.select(selectors.getOnlineOrder)),
        switchMap(([action, onlineOrder]) => {
            if (!onlineOrder) return [];
            const deliveryCollectionType = new Utils.CollectionTypeHelper(this._config.collectionTypes).getDeliveryCollectionTypeConfig();
            const address = new Models.DeliveryAddressModel(action.address).getDeliveryDetailsBusinessModel(onlineOrder?.PickUpDate, deliveryCollectionType.nextTick);

            return [actions.OnlineDeliveryAddressSet({ address })];
        })
    );

    private _getDuplicateOrderFlags(
        action: { model?: APIv3_SNAPSHOT.OnlineOrderDetailedBusinessModel; previousOrderInfo?: OLO.DTO.OnlineOrdersPreviousOrder; } & Action,
        orderStatus: OLO.Enums.ONLINE_ORDER_STATUS,
        payment?: APIv3_SNAPSHOT.GetTransactionResponse,
    ): OLO.Common.OrderDuplicationFlags {
        const duplicationFlags: OLO.Common.OrderDuplicationFlags = {};
        const paymentStatus = payment?.Status;

        duplicationFlags.timeDiffInOrders = Utils.Dates.datesDiffInMinutes(new Date(action.model.OrderedDate), new Date(action.previousOrderInfo.OrderedDate));
        duplicationFlags.isSameGross = action.previousOrderInfo.TotalGrossValue === action.model.TotalGrossValue;
        duplicationFlags.isRecentSameOrder = duplicationFlags.timeDiffInOrders <= 5 && duplicationFlags.isSameGross;

        if (payment) {
            duplicationFlags.isDuringPaymentProcedure = orderStatus === OLO.Enums.ONLINE_ORDER_STATUS.CREATED && paymentStatus === OLO.Enums.PAYMENT_STATUS.PENDING;
            duplicationFlags.isAlreadyPayed =
                orderStatus > OLO.Enums.ONLINE_ORDER_STATUS.CREATED && orderStatus < OLO.Enums.ONLINE_ORDER_STATUS.CANCELED && paymentStatus === OLO.Enums.PAYMENT_STATUS.SUCCESS;
            duplicationFlags.isImproperOrder = orderStatus === OLO.Enums.ONLINE_ORDER_STATUS.CREATED && paymentStatus === OLO.Enums.PAYMENT_STATUS.FAILED;
        } else {
            duplicationFlags.isAlreadyPayed = orderStatus > OLO.Enums.ONLINE_ORDER_STATUS.CREATED && orderStatus < OLO.Enums.ONLINE_ORDER_STATUS.CANCELED;
        }

        return duplicationFlags;
    }

    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) private _t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _modalsServicService: Services.ModalsService,
        private _store: Store<OLO.State>,
        private _paymentService: Services.PaymentsService,
        private _cryptoService: Services.CryptoService,
        protected _routeService: Services.RouteService,
    ) { }
}
