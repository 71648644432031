import { createSelector } from '@ngrx/store';
import { getLocationImage } from '@shared/state/locationsImages/selectors';
import { SubNavBuilder } from '@shared/core/models';

export const getVirtualLocationSubNavPage = (virtualLocation: OLO.DTO.VirtualLocationBusinessModel, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForLogo) =>
    createSelector(getLocationImage(imageType, virtualLocation.LocationNo), (image) => {
        const model = new SubNavBuilder(virtualLocation)
            .setId(virtualLocation.LocationNo)
            .setName(virtualLocation.LocationDescription)
            .setImgUrl(image?.data?.ImageUrl)
            .setAccentColour(virtualLocation.AccentColour)
            .build();

        return model;
    });
