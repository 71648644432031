import { createSelector } from '@ngrx/store';

import { onlineMenuVirtualLocationsState } from '../root';

export const getOnlineMenuVirtualLocation = (virtualLocationNo: number) => createSelector(
    onlineMenuVirtualLocationsState,
    onlineMenuVirtualLocations => {
        if (onlineMenuVirtualLocations?.length === 0) return null;
        const onlineMenu = onlineMenuVirtualLocations.find(onlineMenuVirtualLocation => onlineMenuVirtualLocation.virtualLocationNo === virtualLocationNo);

        return onlineMenu || null;
    }
);

