import * as moment from 'moment';

import * as Models from '@shared/core/models';
import * as Statics from '@shared/core/statics';
import { DATE_DISPLAY_FORMAT } from '@shared/core/consts';

import { Dates } from './dates.utils';
import { DateTimeRounder } from './date-time-rounder.utils';
import { LocationOpenStatus } from './location-open-status.utils';
import { LocationFutureOrdering } from './location-future-ordering.utils';
import { LocationOrderingTimeInfo } from './location-ordering-time-info.utils';
import { ReplacePlaceholderVariables } from './replace-variable-with-value.utils';

export class Pickups {
    /**
     * Creates time object that is used to create pickups lists
     *
     * @param {boolean} isSchedule determines wheter time object should be scheduled type
     * @param {Date} forDate start date
     * @param {Date} [edgeDate] end date if any
     * @param {number} [index] starting index for items list
     * @param {boolean} [isAsap]
     * @param {string} [displayFormat] date format
     * @returns {OLO.Ordering.PickupTime}
     */
    public static createTimeObj(
        isSchedule: boolean,
        forDate: Date,
        edgeDate: Date = null,
        index: number = null,
        isAsap: boolean = false,
        displayFormat: string = DATE_DISPLAY_FORMAT,
    ): OLO.Ordering.PickupTime {
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));
        const DayNo: number = Dates.datesDiffInDays(forDate, currentTime);
        const IsToday: boolean = DayNo === 0;

        return new Models.PickupModels().generate({
            Id: forDate.getTime(),
            Index: index,
            Name: Pickups.createPickupLabelName(isSchedule, 'common', forDate, isAsap, displayFormat, null, null),
            ShortName: `${Dates.hoursFromDate(forDate)}`,
            Date: new Date(forDate.getTime()),
            MinutesFromNow: Dates.datesDiffInMinutes(forDate, currentTime),
            IsAsap: isAsap,

            Hour: `${Dates.hoursFromDate(forDate, false, false)}:00` /* For comapring dates with online order */,
            DateLocalISO: `${Dates.getLocalISOFormatDate(forDate)}`,
            /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */
            PlaceOrderTimeout: edgeDate ? new Date(edgeDate.getTime()) : null /* For checking if user hasn't spent too much time placing an order */,
            IsToday,
            DayNo,
        });
    }

    /**
     * Creates time object that is used to create pickups lists with timespan
     *
     * @param {boolean} isSchedule determines wheter time object should be scheduled type
     * @param {Date} forDate start date
     * @param {Date} [edgeDate] end date if any
     * @param {number} [nextTick] next tick in mintues
     * @param {number} [index] starting index for items list
     * @param {boolean} [isAsap]
     * @param {string} [displayFormat] date format
     * @returns {OLO.Ordering.PickupTime}
     */
    public static createTimespanObj(
        isSchedule: boolean,
        forDate: Date,
        nextTick: number,
        edgeDate: Date = null,
        index: number = null,
        isAsap: boolean = false,
        displayFormat: string = DATE_DISPLAY_FORMAT,
    ): OLO.Ordering.PickupTime {
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));
        const DayNo: number = Dates.datesDiffInDays(forDate, currentTime);
        const IsToday: boolean = DayNo === 0;
        const dateIncludingNextTick: Date = new Date(forDate.getTime() + nextTick * 60 * 1000);
        const timeSpan = {
            startDate: new Date(forDate.getTime()),
            endDate: dateIncludingNextTick,
        };

        return new Models.PickupModels().generate({
            Id: forDate.getTime(),
            Index: index,
            Name: Pickups.createPickupLabelName(isSchedule, 'common', forDate, isAsap, displayFormat, null, timeSpan),
            ShortName: `${Dates.hoursFromDate(forDate, true, false)} - ${Dates.hoursFromDate(dateIncludingNextTick)}`,
            Date: new Date(forDate.getTime()),
            MinutesFromNow: Dates.datesDiffInMinutes(forDate, currentTime),
            IsAsap: isAsap,
            Hour: `${Dates.hoursFromDate(forDate, false, false)}:00` /* For comapring dates with online order */,
            DateLocalISO: `${Dates.getLocalISOFormatDate(forDate)}`,
            /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */
            PlaceOrderTimeout: edgeDate ? new Date(edgeDate.getTime()) : null /* For checking if user hasn't spent too much time placing an order */,
            IsToday,
            DayNo,
            TimeSpan: timeSpan,
        });
    }

    /**
     * Updates [[OLO.Ordering.IPickupTime]] object if `isAsap` flag is true with valid props
     * @param {OLO.Ordering.PickupTime} timeObject
     * @returns {OLO.Ordering.PickupTime} updated or the same object
     */
    public static overrdrivePickupTimeObjToAsap(timeObject: OLO.Ordering.PickupTime): OLO.Ordering.PickupTime {
        let pickupTimeObj = timeObject;

        if (pickupTimeObj.IsAsap) {
            const asapDate: Date = new Date(new Date().setSeconds(0, 0) + timeObject.MinutesFromNow * 60 * 1000);

            pickupTimeObj = new Models.PickupModels().generate({
                Id: asapDate.getTime(),
                Index: -1,
                Name: Pickups.createPickupLabelName(false, 'common', asapDate, true, DATE_DISPLAY_FORMAT, null, null),
                ShortName: `${Dates.hoursFromDate(asapDate, true)}`,
                Date: new Date(asapDate.getTime()),
                MinutesFromNow: timeObject.MinutesFromNow,
                IsAsap: true,

                Hour: `${Dates.hoursFromDate(asapDate, false, false)}:00` /* For comapring dates with online order */,
                DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapDate.getTime()))}`,
                /* Local time iso string - similar stuff gets returned from our API so it will be usefull to compare this date with api dates */

                PlaceOrderTimeout: new Date(timeObject.PlaceOrderTimeout.getTime()) /* For checking if user hasn't spent too much time placing an order */,
                IsToday: Dates.datesDiffInDays(new Date(), asapDate) === 0,
                DayNo: Dates.datesDiffInDays(new Date(), asapDate),
                TimeSpan: null,
            });
        }

        return pickupTimeObj;
    }

    /**
     * Checks if provided date is range of future date range based on days diff form NOW
     * @param {Date} date to validate
     * @param {number} futureOrdersMinDay inital range day number counting from 0
     * @param {number} futureOrdersMaxDay end range day number
     * @returns {boolean}
     */
    public static isDateInFutureOrdersTimeRange(date: Date | string, futureOrdersMinDay: number = null, futureOrdersMaxDay: number = null): boolean {
        if (futureOrdersMinDay === null || futureOrdersMaxDay === null) return false;
        if (!date) return false;
        date = typeof date === 'string' ? Dates.createDate(date) : (date as Date);

        const diff: number = Dates.datesDiffInDays(new Date(), date);

        return diff >= futureOrdersMinDay && diff <= futureOrdersMaxDay;
    }

    /**
     * Checks if provided `pickupTime` object is valid future day for desired location
     *
     * @param {OLO.Ordering.PickupTime} pickupTime object
     * @param {OLO.DTO.LocationOrderingTimeInfoModel} timeInfo of specific location
     * @param {number} futureOrdersMinDay range start - difference in days relative to today's date
     * @param {number} futureOrdersMinDay range end - difference in days relative to today's date
     * @returns {boolean}
     */
    public static isFuturePickupTimeValid(
        pickupTime: OLO.Ordering.PickupTime,
        timeInfo: OLO.DTO.LocationOrderingTimeInfoModel,
        futureOrdersMinDay: number = null,
        futureOrdersMaxDay: number = null,
    ): boolean {
        /* Check if selected pickup time is in range of open +1 - close-1 hours */
        const startDate: Date = new Date(
            Dates.createDate(
                timeInfo.Date.split('T')
                    .map((obj, index) => (!index ? obj : timeInfo.OpeningTime))
                    .join('T'),
            ).getTime() +
                1 * 60 * 1000,
        );
        const endDate: Date = new DateTimeRounder(
            Dates.createDate(
                timeInfo.Date.split('T')
                    .map((obj, index) => (!index ? obj : timeInfo.ClosingTime))
                    .join('T'),
            ),
            'down',
        ).roundByHour();

        const targetDate = Dates.createDate(pickupTime?.DateLocalISO || Dates.getLocalISOFormatDate(Dates.createDate(null)));

        const isInLocationTimeRange: boolean = Pickups.isDateInFutureOrdersTimeRange(targetDate, futureOrdersMinDay, futureOrdersMaxDay);

        return targetDate >= startDate && targetDate <= endDate && isInLocationTimeRange === true;
    }

    /**
     * Based on YYYY-MM-DD, compares two dates
     *
     * @param {string} dateToCompare
     * @returns {Function} configured validator
     */
    public static datesMatchByDayCallback(dateToCompare: string): (obj: any) => boolean {
        return (obj) => Dates.getChunk(obj?.Date) === (Dates.getChunk(dateToCompare) || null);
    }

    /**
     * Based on location details, it returns information that helps validate some BL
     *
     * @param {OLO.DTO.OnlineOrderingLocationBusinessModel} location details
     * @returns {APICommon.LocationOrderingTimeInfoExtendedModel[]} calculated location ordering time objects
     */
    public static getFilteredOrderingTimeInfo(
        location: OLO.DTO.OnlineOrderingLocationBusinessModel,
        orderTypeId: Nullable<number>,
    ): APICommon.LocationOrderingTimeInfoExtendedModel[] {
        const { FutureOrderingMinDaysAhead: min, FutureOrderingMaxDaysAhead: max } = LocationFutureOrdering.getLocationFutureOrderingDetails({
            location,
            orderTypeId,
        });
        //
        //  Min = null or/and Max = null  -> No future ordering
        //  Min = 0 and Max = 0  -> Only today
        //  Min = 0 and Max >0  -> today and other days
        //  Min >= 1 and Max >=1  -> today not available and can order for future days only
        //
        const isFutureOrderingAllowed = min !== null && max !== null;
        const isTodayAllowed = min === 0 || min === null;
        const orderingTimeInfo = new LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();
        const arr =
            orderingTimeInfo?.reduce((acc, timeInfo) => {
                const openStatus: LocationOpenStatus = new LocationOpenStatus(timeInfo);
                if (!openStatus.isOpen()) {
                    return acc;
                }

                const isToday: boolean = Dates.isToday(timeInfo.Date);

                switch (true) {
                    case isToday && isTodayAllowed:
                        /* Min = 0 and Max = 0  -> Only today */
                        return [...acc, timeInfo];
                    case isToday && !isTodayAllowed:
                        /* Min = 0 and Max = 0  -> Only today */
                        return acc;
                    case !isToday && isFutureOrderingAllowed && Pickups.isDateInFutureOrdersTimeRange(timeInfo.Date, min, max):
                        /* Min = 0 and Max >0  -> today and other days */
                        /* Min >= 1 and Max >=1  -> today not available and can order for future days only */
                        return [...acc, timeInfo];
                    default:
                        /* Min = null or/and Max = null  -> No future ordering */
                        return acc;
                }
            }, [] as OLO.DTO.LocationOrderingTimeInfoModel[]) || [];

        return arr.map((obj) => ({
            ...obj,
            IsOpen: new LocationOpenStatus(obj).isOpen(),
        }));
    }

    /**
     * Creates base pickup information for location
     *
     * @param {number} locationNo
     * @param {number} asapPickupMins current minimum asap in minutes
     * @param {OLO.DTO.LocationOrderingTimeInfoModel[]} openingHours location opening hours
     * @param {number} orderTimeoutBufferMins determines how much time user has to create the order
     * @param {number} startBufferMins buffer in mins for initial pickup time
     * @param {Date} date for which calculations will be performed. Default is `new Date()`
     * @param {IStaticTexts} staticTexts translations of static texts
     * @returns {OLO.Ordering.PickupForLocation | null} base pickup information object. If null is returned, it indicate that today's ordering is unavailable,
     * but location might be avaliable in future orderings
     */
    public static calcInitialTimesObj(params: Partial<OLO.Ordering.InitialPickupTimesParams>): OLO.Ordering.PickupForLocation {
        const opts: OLO.Ordering.InitialPickupTimesParams = {
            locationNo: null,
            asapPickupMins: null,
            openingHours: null,
            orderTimeoutBufferMins: 0,
            startBufferMins: 0,
            endBufferMins: 0,
            date: new Date(),
            ...params,
        };

        const { locationNo, asapPickupMins, openingHours, orderTimeoutBufferMins, startBufferMins, endBufferMins, date } = opts;

        if (orderTimeoutBufferMins == null || startBufferMins == null) {
            throw new Error('OrderTimeoutBufferMins and startBufferMins must be provided');
        }

        const localDate: string = date instanceof Date ? Dates.getLocalISOFormatDate(date) : date;
        const openingHourObj: OLO.DTO.LocationOrderingTimeInfoModel = openingHours?.find((day) => {
            const byDate = day.Date ? Dates.getChunk(day.Date) === Dates.getChunk(localDate) : false;
            const byDayOfWeek = day.DayOfWeek === date.getDay();

            return byDate || byDayOfWeek;
        });
        if (!openingHourObj) {
            /* No today ordering - location might be available in further days */
            return null;
        }
        const orderingInfoObj = Pickups.findLocationOrderingTimeInfoObject(date, openingHours);
        const pickupTimesObj = Pickups.findByDateLocationPickupTimeObject(date, orderingInfoObj) || orderingInfoObj?.PickupTimes?.[0] || null;
        const minimumPreperationTime = pickupTimesObj ? pickupTimesObj.MinimumPickupTime : null;
        const asapMinsFormDate = minimumPreperationTime != null ? minimumPreperationTime : asapPickupMins;
        const totalMinimumPickupMins: number = asapMinsFormDate < startBufferMins ? startBufferMins : asapMinsFormDate;
        const closingBuffer = /* totalMinimumPickupMins +  */ orderTimeoutBufferMins + endBufferMins;

        let startTime: Date;
        let closeTime: Date;
        let minimumPickupTimeForAsap: Date = Dates.resetSeconds(date); /* CHECK FOR NEAREST OPEN TIME IF IS CLOSED NOW */
        let minimumPickupTime: Date;
        let maximumPickupTime: Date;

        /* Comparing type */
        /* IOS FIX? AOLO-462 */
        const openingDateRaw: string = localDate.replace(/T.+/, '');
        const arrDate: number[] = openingDateRaw.split('-').map((d) => +d);
        const arrHoursOpen: number[] = openingHourObj.OpeningTime.split(':').map((d) => +d);
        const arrHoursClose: number[] = openingHourObj.ClosingTime.split(':').map((d) => +d);

        startTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursOpen[0], arrHoursOpen[1], arrHoursOpen[2]);
        closeTime = new Date(arrDate[0], arrDate[1] - 1, arrDate[2], arrHoursClose[0], arrHoursClose[1], arrHoursClose[2]);

        minimumPickupTime = new Date(new Date(date.getTime() + totalMinimumPickupMins * 60 * 1000).setSeconds(0, 0));
        maximumPickupTime = new Date(new Date(new Date(closeTime.getTime() - closingBuffer * 60 * 1000)).setSeconds(0, 0));

        if (minimumPickupTime >= maximumPickupTime) {
            minimumPickupTime = new Date(maximumPickupTime.getTime());
        }

        /* AOLO-215 */
        const diff: number = startTime.getTime() - date.getTime();
        if (diff >= totalMinimumPickupMins) {
            /* add asapiPickupTimeMins to openHours */
            minimumPickupTime = new Date(new Date(startTime.getTime() + asapMinsFormDate * 60 * 1000).setSeconds(0, 0));
        }

        /* ASAP Check if location is open to adjust asap time */
        const locationOpenHour: number = Dates.createHoursIntFromDate(openingHourObj.OpeningTime);
        const locationCloseHour: number = Dates.createHoursIntFromDate(openingHourObj.ClosingTime);
        const nowHour: number = Dates.createHoursIntFromDate(date);
        const isOpen: boolean = nowHour >= locationOpenHour && nowHour <= locationCloseHour;
        const locationWillBeOpenToday: boolean = locationOpenHour > nowHour;
        const locationIsClosing = minimumPickupTimeForAsap.getTime() + totalMinimumPickupMins * 60 * 1000 > maximumPickupTime.getTime();

        if (locationIsClosing === true && locationWillBeOpenToday === false) {
            return null;
        }

        if (!isOpen) {
            if (!locationWillBeOpenToday) {
                return null;
            }
            minimumPickupTimeForAsap = new Date(startTime.getTime() + totalMinimumPickupMins * 60 * 1000);
        }

        const finalObj = {
            DayOfWeek: openingHourObj.DayOfWeek,
            DayName: Dates.getDayName(openingHourObj.DayOfWeek),
            LocationNo: locationNo,
            Date: localDate,
            IsOpen: isOpen,
            OpeningTime: startTime,
            ClosingTime: closeTime,
            MinimumPickupTimeForAsap: minimumPickupTimeForAsap,
            MinimumPickupTime: minimumPickupTime,
            MaximumPickupTime: maximumPickupTime,
        };

        return finalObj;
    }

    /**
     * Helper that extracts location's time info object based on the given date
     *
     * @param {Date} date
     * @param {OLO.DTO.LocationOrderingTimeInfoModel[]} openingHours
     * @returns {OLO.DTO.LocationOrderingTimeInfoModel | null} extracted object
     */
    public static findLocationOrderingTimeInfoObject(date: Date, openingHours: OLO.DTO.LocationOrderingTimeInfoModel[]): Nullable<OLO.DTO.LocationOrderingTimeInfoModel> {
        const localDate: string = Dates.getLocalISOFormatDate(date);

        return (
            openingHours?.find((day) => {
                const byDate = day.Date ? Dates.getChunk(day.Date) === Dates.getChunk(localDate) : false;
                const byDayOfWeek = day.DayOfWeek === date.getDay();

                return byDate || byDayOfWeek;
            }) || null
        );
    }

    /**
     * Helper that extracts Pickup Time info model with MinimumPickupTime
     *
     * @param {Date} date
     * @param {OLO.DTO.LocationOrderingTimeInfoModel[]} openingHours
     * @returns {Nullable<OLO.DTO.LocationOrderingPickupTimeInfoModel>} extracted object
     */
    public static findByDateLocationPickupTimeObject(
        date: Date,
        orderingObj: Nullable<OLO.DTO.LocationOrderingTimeInfoModel>,
    ): Nullable<OLO.DTO.LocationOrderingPickupTimeInfoModel> {
        if (!orderingObj) {
            return null;
        }

        return orderingObj.PickupTimes?.find((obj) => Dates.isHourInHoursRange(date, obj.From, obj.To, 'both'));
    }

    /**
     * Generates pickups list for today only. Future pickups are excluded from the results.
     *
     * @param {OLO.Ordering.GeneratePickupsParams} params
     * @returns {OLO.Ordering.PickupTime[]} pickups list
     */
    public static generatePickupTimesList(params: OLO.Ordering.GeneratePickupsParams): OLO.Ordering.PickupTime[] {
        const defaults: OLO.Ordering.GeneratePickupsParams = {
            location: null,
            asapPickupMins: 0,
            openingHours: null,
            orderTimeoutBufferMins: 0,
            startBufferMins: 0,
            endBufferMins: 0,
            nextTick: 15,
            schedule: false,
            orderTypeId: null,
        };

        const opts = {
            ...defaults,
            ...params,
        };

        if (opts.asapPickupMins == null || !opts.openingHours) return [];
        const { FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead } = LocationFutureOrdering.getLocationFutureOrderingDetails({
            location: opts.location,
            orderTypeId: opts.orderTypeId,
        });

        const isFutureOrderingOnly =
            opts.schedule === true &&
            opts.location !== null &&
            FutureOrderingMaxDaysAhead > 0 &&
            FutureOrderingMinDaysAhead > 0 &&
            FutureOrderingMinDaysAhead <= FutureOrderingMaxDaysAhead;

        if (isFutureOrderingOnly) return [];

        const locationNo = opts.location?.LocationNo || 0;
        /* ASAP - AOLO-248 */
        const now = new Date();
        const initialAsapPickupValue = Pickups.calcInitialTimesObj({
            locationNo,
            asapPickupMins: opts.asapPickupMins,
            openingHours: opts.openingHours,
            orderTimeoutBufferMins: opts.orderTimeoutBufferMins,
            startBufferMins: opts.startBufferMins,
            endBufferMins: opts.endBufferMins,
            date: now,
        });
        if (!initialAsapPickupValue) return [];

        let asapPickup: Date = initialAsapPickupValue.MinimumPickupTimeForAsap;

        let arr: OLO.Ordering.PickupTime[] = [];
        if (!opts.displayAsTimespans) {
            arr.push({
                Id: asapPickup.getTime(),
                Index: 1,
                Name: opts.schedule
                    ? Pickups.createPickupLabelName(opts.schedule, 'common', asapPickup, true, opts.displayFormat, null, null)
                    : `${Dates.hoursFromDate(asapPickup, true)}`,
                ShortName: `${Dates.hoursFromDate(asapPickup, true)}`,
                Date: asapPickup,
                MinutesFromNow: opts.asapPickupMins,
                IsAsap: initialAsapPickupValue.IsOpen,
                Hour: `${Dates.hoursFromDate(asapPickup, false, false)}:00`,
                DateLocalISO: `${Dates.getLocalISOFormatDate(new Date(asapPickup.getTime()))}`,
                PlaceOrderTimeout: new Date(asapPickup.getTime() + opts.orderTimeoutBufferMins * 60 * 1000),
                IsToday: Dates.datesDiffInDays(initialAsapPickupValue.Date, now) === 0,
                DayNo: Dates.datesDiffInDays(initialAsapPickupValue.Date, now),
                TimeSpan: null,
            });
        }

        /* Later today */
        const initialLaterPickupValue = Pickups.calcInitialTimesObj({
            locationNo: opts.location?.LocationNo || 0,
            asapPickupMins: opts.asapPickupMins,
            openingHours: opts.openingHours,
            orderTimeoutBufferMins: opts.orderTimeoutBufferMins,
            startBufferMins: opts.startBufferMins,
            endBufferMins: opts.endBufferMins,
            date: now,
        });
        let nextLaterPickup: Date = new DateTimeRounder(initialLaterPickupValue.MinimumPickupTime, 'up', opts.nextTick).round();
        let nextLaterPlaceOrderTimeout: Date = new Date(nextLaterPickup.getTime() - (opts.startBufferMins - opts.orderTimeoutBufferMins) * 60 * 1000);
        /* Timeout to place an order for selected pickup time */

        if (nextLaterPickup > initialLaterPickupValue.MaximumPickupTime) return arr;

        while (nextLaterPickup < initialLaterPickupValue.MaximumPickupTime) {
            if (nextLaterPickup > initialLaterPickupValue.MinimumPickupTimeForAsap) {
                if (opts.displayAsTimespans) {
                    arr.push(Pickups.createTimespanObj(opts.schedule, nextLaterPickup, opts.nextTick, nextLaterPlaceOrderTimeout, arr.length + 1));
                } else {
                    arr.push(Pickups.createTimeObj(opts.schedule, nextLaterPickup, nextLaterPlaceOrderTimeout, arr.length + 1));
                }
            }

            nextLaterPickup = new Date(nextLaterPickup.setMinutes(nextLaterPickup.getMinutes() + opts.nextTick));
            nextLaterPlaceOrderTimeout = new Date(nextLaterPlaceOrderTimeout.setMinutes(nextLaterPlaceOrderTimeout.getMinutes() + opts.nextTick));
        }

        return arr;
    }

    /**
     * Base object that helps build future orders lists or validate data
     *
     * @param {OLO.DTO.LocationOrderingTimeInfoModel} obj location's time info object
     * @param {string} format date format. Default ```DATE_DISPLAY_FORMAT```
     * @param {boolean} prefixes add ```Today``` or ```Tomorrow``` prefixes to dates
     * @returns {OLO.Ordering.Period}
     */
    public static createPeriodObject(obj: OLO.DTO.LocationOrderingTimeInfoModel, format: string = DATE_DISPLAY_FORMAT, prefixes: boolean = true): OLO.Ordering.Period {
        const text = new Statics.TextsStatic().current;
        const config = new Statics.ConfigStatic().current;
        const locale = config.localization?.locale || 'en-AU';
        const normalizeDate = (date: string) =>
            date
                .split('T')
                .map((key, index) => (index === 0 ? key : obj?.ClosingTime ? obj.ClosingTime + '.000' : '12:00:00.000'))
                .join('T');
        const now: Date = new Date();
        const newDate: string = normalizeDate(obj.Date);
        const Id = Dates.datesDiffInDays(now, newDate);

        let Name: string = '';
        if (prefixes && Id < 2) {
            if (Id === 0) {
                Name = `${text.pickup.today}, `;
            } else {
                Name = `${text.pickup.tomorrow}, `;
            }
        }

        Name += moment(Dates.createDate(newDate)).locale(locale)
            .format(format);

        return {
            Id: Id + 1,
            DayName: Dates.getDayName(obj.DayOfWeek),
            DayOfWeek: obj.DayOfWeek,
            Date: newDate,
            IsToday: Id === 0,
            Name,
        };
    }

    /**
     * Creates pickups list for future orders. Similar to [[Pickups.generatePickupTimesList]] method
     *
     * @param {OLO.Ordering.Period} period object generated by [[Pickups.createPeriodObject]] method
     * @param {OLO.Ordering.GeneratePickupsParams} params
     * @returns {OLO.Ordering.PickupTime[]} future orders pickups list
     */
    public static generatePickupTimesFutureList(period: OLO.Ordering.Period, params: OLO.Ordering.GeneratePickupsParams): OLO.Ordering.PickupTime[] {
        const now = new Date();
        const defaults: OLO.Ordering.GeneratePickupsParams = {
            location: null,
            openingHours: null,
            orderTimeoutBufferMins: 60,
            startBufferMins: 60,
            endBufferMins: 0,
            nextTick: 60,
            displayFormat: DATE_DISPLAY_FORMAT,
            orderTypeId: null,
        };

        const opts: OLO.Ordering.GeneratePickupsParams = {
            ...defaults,
            ...params,
        };
        if (!opts.openingHours) return [];
        const openingHours: OLO.DTO.LocationOrderingTimeInfoModel = params.openingHours.find((obj) => Dates.getChunk(obj.Date) === Dates.getChunk(period.Date));
        let endDate: Date = Dates.createDate(
            period.Date.split('T')
                .map((obj, index) => (!index ? obj : openingHours.ClosingTime))
                .join('T'),
        );

        const startDate: Date = new Date(
            Dates.createDate(
                period.Date.split('T')
                    .map((obj, index) => (!index ? obj : openingHours.OpeningTime))
                    .join('T'),
            ).getTime() +
                opts.startBufferMins * 60 * 1000,
        );

        if (openingHours.ClosingTime && openingHours.ClosingTime.split(':')[1] === '59') {
            endDate = new Date(endDate.getTime() + 1 * 60 * 1000);
        }

        let nextDate: Date = new DateTimeRounder(startDate, 'down').roundByHour();

        const arr: OLO.Ordering.PickupTime[] = [];
        if (nextDate > endDate) return arr;

        const isToday: boolean = Dates.isToday(endDate);
        if (isToday) {
            const listForToday = Pickups.generatePickupTimesList({
                ...params,
            });

            return listForToday;
        }

        while (nextDate.getTime() < endDate.getTime()) {
            if (opts.displayAsTimespans) {
                arr.push(Pickups.createTimespanObj(opts.schedule, nextDate, opts.nextTick, null, arr.length + 1));
            } else {
                arr.push(Pickups.createTimeObj(opts.schedule, nextDate, null, arr.length + 1));
            }

            nextDate = new Date(nextDate.setMinutes(nextDate.getMinutes() + opts.nextTick));
        }

        const result = arr.filter((obj) => obj.Date > now && obj.Date >= startDate);

        return result;
    }

    /**
     * Generate label for different types of scenarios
     *
     * @param {boolean} isScheduleOrderingEnabled
     * @param {OLO.Types.PICKUP_LABEL_TYPES} type
     * @param {Date | string} date
     * @param {boolean} isAsap default ```false```
     * @param {string} format default ```DATE_DISPLAY_FORMAT```
     * @param {number} asapMinutesFromNow default ```null```
     * @param { { startDate: Date; endDate: Date; } } timeSpan
     * @returns {string}
     */
    public static createPickupLabelName(
        isScheduleOrderingEnabled: boolean,
        type: OLO.Types.PICKUP_LABEL_TYPES,
        date: Date | string,
        isAsap: boolean = false,
        format: string = DATE_DISPLAY_FORMAT,
        asapMinutesFromNow: number = null,
        timeSpan: Nullable<OLO.Ordering.PickupTimeSpan>,
    ): string {
        const text = new Statics.TextsStatic().current;
        if (!format) {
            format = DATE_DISPLAY_FORMAT;
        }
        if (typeof date === 'string') {
            date = Dates.createDate(date);
        }
        if (typeof timeSpan?.endDate === 'string') {
            timeSpan.endDate = Dates.createDate(timeSpan.endDate);
        }
        if (!(date instanceof Date)) {
            date = new Date();
            isAsap = true;
        }
        const currentTime: Date = new Date(new Date().setSeconds(0, 0));
        const daysDiff: number = Dates.datesDiffInDays(date, currentTime);
        let minutesFromNow: number = Math.floor((date.getTime() - currentTime.getTime()) / (60 * 1000));
        if (minutesFromNow < 0) {
            minutesFromNow = 0;
        }

        if (asapMinutesFromNow !== null) {
            minutesFromNow = asapMinutesFromNow;
        }

        let label: string = timeSpan?.endDate ? `${Dates.hoursFromDate(date, true, false)} - ${Dates.hoursFromDate(timeSpan.endDate)}` : `${Dates.hoursFromDate(date, true)}`;

        if (!isScheduleOrderingEnabled && !timeSpan) {
            if (isAsap) {
                switch (true) {
                    case type === 'common':
                        return text.pickup.asap;
                    case type === 'checkoutBox':
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.inXmins, 'minutesFromNow', `${minutesFromNow}`);
                    case type === 'location':
                        if (minutesFromNow) {
                            return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.asapMins, 'minutesFromNow', `${minutesFromNow}`);
                        } else {
                            return text.pickup.asap;
                        }

                    case type === 'filter':
                        return text.pickup.now;
                }
            } else {
                switch (true) {
                    case type === 'common':
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.atLabel, 'label', `${label}`);
                    case type === 'checkoutBox':
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.atLabel, 'label', `${label}`);
                    case type === 'location':
                        return `${label}`;
                    case type === 'filter':
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.capitalAtLabel, 'label', `${label}`);
                }
            }

            return label;
        }

        if (type === 'orderConfirmation') {
            switch (true) {
                case daysDiff === 0:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.todayAtLabel, 'label', `${label}`);
                case daysDiff === 1:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.tomorrowAtLabel, 'label', `${label}`);
                case daysDiff < 7:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dateAtLabel, [
                        { variable: 'date', value: `${Dates.getDayName(date.getDay())}` },
                        { variable: 'label', value: `${label}` },
                    ]);
                default:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dayAtLabel, [
                        { variable: 'day', value: `${moment(date).format(format || 'Do of D MMM')}` },
                        { variable: 'label', value: `${label}` },
                    ]);
            }
        }

        if (type === 'common') {
            if (!timeSpan) {
                switch (true) {
                    case daysDiff === 0:
                        if (isAsap) {
                            return text.pickup.todayAsap;
                        } else {
                            return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.todayAtLabel, 'label', `${label}`);
                        }
                    case daysDiff === 1:
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.tomorrowAtLabel, 'label', `${label}`);
                    case daysDiff < 7:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.thisDayAtLabel, [
                            { variable: 'day', value: `${Dates.getDayName(date.getDay())}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                    default:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dateAtLabel, [
                            { variable: 'date', value: `${moment(date).format(format || DATE_DISPLAY_FORMAT)}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                }
            } else {
                switch (true) {
                    case daysDiff === 0:
                        return ReplacePlaceholderVariables.replaceVariableWithValue(`${text.pickup.today} {{label}}`, 'label', `${label}`);
                    case daysDiff === 1:
                        return ReplacePlaceholderVariables.replaceVariableWithValue(`${text.pickup.tomorrow} {{label}}`, 'label', `${label}`);
                    case daysDiff < 7:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues('{{day}} {{label}}', [
                            { variable: 'day', value: `${Dates.getDayName(date.getDay())}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                    default:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues('{{date}} {{label}}', [
                            { variable: 'date', value: `${moment(date).format(format || DATE_DISPLAY_FORMAT)}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                }
            }
        }

        if (type === 'checkoutBox') {
            switch (true) {
                case daysDiff === 0:
                    if (isAsap) {
                        return text.pickup.todayAsap;
                    } else {
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.todayAtLabel, 'label', `${label}`);
                    }
                case daysDiff === 1:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.tomorrowAtLabel, 'label', `${label}`);
                case daysDiff < 7:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.thisDayAtLabel, [
                        { variable: 'day', value: `${Dates.getDayName(date.getDay())}` },
                        { variable: 'label', value: `${label}` },
                    ]);
                default:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dateAtLabel, [
                        { variable: 'date', value: `${moment(date).format(format || DATE_DISPLAY_FORMAT)}` },
                        { variable: 'label', value: `${label}` },
                    ]);
            }
        }

        if (type === 'location') {
            if (!timeSpan) {
                switch (true) {
                    case daysDiff === 0:
                        if (isAsap && minutesFromNow) {
                            return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.todayAsapMins, 'minutesFromNow', `${minutesFromNow}`);
                        }

                        if (isAsap && !minutesFromNow) {
                            return text.pickup.todayAsap;
                        }

                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.todayAtLabel, 'label', `${label}`);

                    case daysDiff === 1:
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.tomorrowAtLabel, 'label', `${label}`);
                    case daysDiff < 7:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dayAtLabel, [
                            { variable: 'day', value: `${Dates.getDayName(date.getDay())}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                    default:
                        return ReplacePlaceholderVariables.replaceVariablesWithValues(text.pickup.dateAtLabel, [
                            { variable: 'date', value: `${moment(date).format(format || DATE_DISPLAY_FORMAT)}` },
                            { variable: 'label', value: `${label}` },
                        ]);
                }
            }

            switch (true) {
                case daysDiff === 0:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(`${text.pickup.today} {{label}}`, 'label', `${label}`);
                case daysDiff === 1:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(`${text.pickup.tomorrow} {{label}}`, 'label', `${label}`);
                case daysDiff < 7:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues('{{day}} {{label}}', [
                        { variable: 'day', value: `${Dates.getDayName(date.getDay())}` },
                        { variable: 'label', value: `${label}` },
                    ]);
                default:
                    return ReplacePlaceholderVariables.replaceVariablesWithValues('{{date}} {{label}}', [
                        { variable: 'date', value: `${moment(date).format(format || DATE_DISPLAY_FORMAT)}` },
                        { variable: 'label', value: `${label}` },
                    ]);
            }
        }

        if (type === 'filter') {
            switch (true) {
                case daysDiff === 0:
                    if (isAsap) {
                        return text.pickup.now;
                    } else {
                        return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.labelToday, 'label', `${label}`);
                    }
                case daysDiff === 1:
                    return ReplacePlaceholderVariables.replaceVariableWithValue(text.pickup.labelTomorrow, 'label', `${label}`);
                case daysDiff < 7:
                    return `${label} ${Dates.getDayName(date.getDay())}`;
                default:
                    return `${label} ${moment(date).format(format)}`;
            }
        }

        return 'undefined case';
    }

    public static createPickUpLabel(value: OLO.Ordering.PickupTime | string, type: OLO.Types.PICKUP_LABEL_TYPES = 'common', format: string = DATE_DISPLAY_FORMAT): string {
        const config = new Statics.ConfigStatic().current;
        const timeSpan = typeof value !== 'string' && value?.TimeSpan ? { startDate: value.TimeSpan?.startDate, endDate: value.TimeSpan?.endDate } : null;

        if (!value) return Pickups.createPickupLabelName(config.onlineOrders.scheduledOrders, type, new Date(), true, format, null, null);
        if (typeof value === 'string') {
            const converted: Date = Dates.createDate(value);
            if (converted.toString() === 'Invalid Date') return value;

            return Pickups.createPickupLabelName(config.onlineOrders.scheduledOrders, type, converted, Dates.datesDiffInDays(new Date(), converted) === 0, format, null, null);
        }

        return Pickups.createPickupLabelName(config.onlineOrders.scheduledOrders, type, value.DateLocalISO, value.IsAsap, format, null, timeSpan);
    }
}
