// https://docs.fatzebra.com/docs/testing - test card numbers
import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FatZebraPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/fat-zebra.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class FatZebra3DSPaymentProviderService {
    private _scriptElem: Nullable<HTMLScriptElement> = null;

    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _httpClient: HttpClient) {}

    public async addHtmlElementsToDOM(): Promise<boolean> {
        if (this._scriptElem) return null;

        return new Promise((resolve, reject) => {
            this._scriptElem = document.createElement('script');

            this._scriptElem.src = this._config.payments.testMode ? 'https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js' : 'https://cdn.pmnts.io/sdk/v1/fatzebra.js';
            this._scriptElem.onload = () => resolve(true);
            this._scriptElem.onerror = () => reject('Unable to load fat zebra script element');

            const iframeContainer = document.createElement('div');
            iframeContainer.id = 'checkoutIframe';

            document.body.appendChild(this._scriptElem);
            document.body.appendChild(iframeContainer);
        });
    }

    public requestConfig(locationNo: number): Observable<OLO.DTO.FatZebraSettingsResponse> {
        if (!locationNo) {
            return throwError('No locationNo provided for Stripe payment provider');
        }

        return this._getSettingsForLocation(locationNo);
    }

    private _produceCustomerDetails(
        member: OLO.DTO.MemberModel | APICommon.OnlineOrderPartialMember,
        billingDetails?: OLO.CreditCards.CreditCardBillingDetails,
    ): FatZebra.VerifyCardParams['customer'] {
        return {
            firstName: 'FirstName' in member ? member.FirstName : member.MemberName,
            lastName: 'LastName' in member ? member.LastName : member.MemberSurname,
            email: member.Email,
            address: `${billingDetails?.Street} ${billingDetails?.HouseNumber}`,
            city: billingDetails?.City,
            country: billingDetails?.CountryIso2Code,
            state: billingDetails?.StateOrProvince,
            postcode: billingDetails?.PostalCode,
        };
    }

    public async verifyCard(data: {
        card: OLO.Members.MemberCreditCardDetails;
        cardToken: string;
        order: OLO.DTO.OnlineOrderDetailedBusinessModel;
        currency: string;
        locationNo: number;
        member: OLO.DTO.MemberModel | APICommon.OnlineOrderPartialMember;
        paymentAccountId?: Nullable<number | string>;
    }): Promise<OLO.DTO.FatZebra3DSTokenDetails | never> {
        const { member, card, cardToken, order, locationNo, currency, paymentAccountId } = data;
        const amount = Utils.Pricing.priceToCents(order.TotalGrossValue);
        const reference = order.SaleName;
        const verificationDetails = await this._getVerificationDetails({
            locationNo: locationNo,
            saleName: reference,
            amount,
            paymentAccountId: (paymentAccountId as number) ?? null,
        }).toPromise();

        // https://docs.fatzebra.com/docs/obtain-oauth-token
        Utils.Storage.set('fz-access-token', verificationDetails.sessionToken);

        const fz: FatZebra = new FatZebra({
            username: verificationDetails.merchantName,
        });

        return new Promise((resolve, reject) => {
            // Handle validation related errors, e.g. client-side validation
            fz.on('fz.validation.error', function (event) {
                // ...
                console.error('fz.validation.error', event.detail?.errors, event);
                reject(event);
            });

            // Receive the result of the 3DS2 check.
            fz.on<FatZebra.SuccessCardPaymentIntentDetails>('fz.sca.success', function (event) {
                // Obtain 3DS2 results which will be used to make a purchase in the backend
                console.warn('fz.sca.success', event);
                resolve(FatZebraPaymentProviderMapper.map3DSVerificationDetails(event.detail.data));
            });

            // Handle errors related to SCA
            fz.on('fz.sca.error', function (event) {
                // Show an error message to the customer
                console.error('fz.sca.error', event.detail?.errors, event);
                reject(event);
            });

            const customer: FatZebra.VerifyCardParams['customer'] = this._produceCustomerDetails(member, card.BillingDetails);

            const fzPayload: FatZebra.VerifyCardParams = {
                customer,
                paymentIntent: {
                    payment: {
                        amount,
                        currency,
                        reference,
                    },
                    verification: verificationDetails.verification,
                },
                paymentMethod: {
                    type: 'card_on_file',
                    data: {
                        token: verificationDetails.cardToken ? verificationDetails.cardToken : cardToken,
                    },
                },
            };

            /**
             * This is official hack for handling corrupted data - by fat zebra.
             * If surname is not present, delete info about customer at all from the payload - otherwise fatzebra will throw an error.
             */
            if (!customer.lastName) {
                fzPayload.customer.firstName = '';
                fzPayload.customer.lastName = '';
            }

            fz.verifyCard(fzPayload);
        });
    }

    protected _getVerificationDetails(details: OLO.DTO.FatZebraVerificationRequestModel) {
        const model = FatZebraPaymentProviderMapper.mapVerificationRequestDetails(details);

        return this._httpClient.post(`${this._config.api.base}/Payments/fatZebra/settings/3ds`, model).pipe(
            map(FatZebraPaymentProviderMapper.mapVerificationResponse),
            catchError((ex) => {
                console.error('FatZebra 3ds verification details error', ex);

                return throwError(ex);
            }),
        );
    }

    protected _getSettingsForLocation(locationNo: number): Observable<OLO.DTO.FatZebraSettingsResponse> {
        return this._httpClient.get<APIv3.PaymentsGetFatZebraSettings.Responses.$200>(`${this._config.api.base}/Payments/fatZebra/settings/${locationNo}`).pipe(
            map((response) => FatZebraPaymentProviderMapper.mapGetSettingsForLocation(response)),
            catchError((ex) => {
                console.error('LocationNo not provided', ex);

                return throwError(ex);
            }),
        );
    }
}
