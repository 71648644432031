import { createAction, props } from '@ngrx/store';

export const CreditCardsStateReset = createAction('[Credit cards] Reset state');

export const CreditCardsResetError = createAction('[Credit cards] Reset errors');

export const SetCardBillingAddress = createAction(
    '[Credit cards] Set card billing details',
    props<{ cardId: OLO.CreditCards.CreditCardId; billingDetails: OLO.CreditCards.CreditCardBillingDetails; }>(),
);

export const CreditCardsStoreWithOrder = createAction('[Credit cards] Store card details tokenized later during order', props<{ card: OLO.Members.MemberCreditCardDetails; }>());

/* Request cards */
export const CreditCardsRequest = createAction('[Credit cards] Request user cards');

export const CreditCardsSuccessRequest = createAction('[Credit cards] Success request user cards', props<{ payload: OLO.Members.MemberCreditCardDetails[]; }>());

export const CreditCardsErrorRequest = createAction('[Credit cards] Error request user cards', props<{ ex?: any; }>());

/* Request cards token */
export const GetCreditCardToken = createAction('[Credit cards] Request token for a new card', props<OLO.CreditCards.CreditCardDetails>());
export const GetCreditCardTokenWithRedirect = createAction('[Credit cards] Request token for a new card with redirect', props<OLO.CreditCards.CreditCardDetails>());
export const __DEMO__getCardToken = createAction('[Credit cards - DEMO] Request token for a new card', props<OLO.CreditCards.CreditCardDetails>());

export const CreditCardsSuccessRequestToken = createAction('[Credit cards] token for a new card success', props<OLO.DTO.CreditCardTokenResponse>());
export const CreditCardsSuccessRequestTokenWithRedirect = createAction('[Credit cards] token for a new card success with redirect', props<OLO.DTO.CreditCardTokenResponse>());
export const __DEMO__CreditCardsSuccessRequestToken = createAction('[Credit cards - DEMO] token for a new card success', props<OLO.DTO.CreditCardTokenResponse>());

export const CreditCardsErrorRequestToken = createAction('[Credit cards] token for a new card error', props<{ ex?: any; }>());

export const CreditCardsErrorRequestTokenWithRedirect = createAction('[Credit cards] token for a new card error with redirect', props<{ ex?: any; }>());

/* Add card */
export const CreditCardsAddRequest = createAction('[Credit cards] Request add new card', props<{ card: OLO.Members.MemberCreditCardDetails; }>());
export const CreditCardsAddAfterRedirectRequest = createAction('[Credit cards] Request add new card after redirect', props<{ card: OLO.Members.MemberCreditCardDetails; }>());

export const CreditCardsAddSuccessRequest = createAction('[Credit cards] Success request add new card', props<{ newCard: OLO.DTO.CreatePaymentAccountResponse; }>());

export const CreditCardsAddAfterRedirectSuccessRequest = createAction(
    '[Credit cards] Success request add new card after redirect',
    props<{ card: OLO.Members.MemberCreditCardDetails; newCard: OLO.DTO.CreatePaymentAccountResponse; }>(),
);

export const CreditCardsAddErrorRequest = createAction('[Credit cards] Error request add new card', props<{ ex?: any; }>());

export const CreditCardsAddAfterRedirectErrorRequest = createAction(
    '[Credit cards] Error request add new card after redirect',
    props<{ card: OLO.Members.MemberCreditCardDetails; ex?: any; }>(),
);

export const CreditCardsSelectDefaultPaymentMethod = createAction('[Credit cards] Select default payment method');

export const CreditCardShowForm = createAction('[Credit cards] Set is credit card adding mode', props<{ isAdding: boolean; }>());

export const SelectActiveCreditCardId = createAction('[Credit cards] select active card id', props<{ cardId: OLO.CreditCards.CreditCardId; }>());

export const SelectActiveCreditCardToken = createAction('[Credit cards] select active card token', props<{ token: string; }>());

export const AddCardToState = createAction('[Credit cards] add card to state only', props<{ card: OLO.Members.MemberCreditCardDetails; }>());

export const CreditCardTokenDataReset = createAction('[Credit cards] reset current token data');

/* Delete card  */
export const CreditCardsRemoveRequest = createAction('[Credit cards] Request remove card', props<{ cardId: OLO.CreditCards.CreditCardId; }>());

export const CreditCardsRemoveSuccessRequest = createAction('[Credit cards] Success request remove card', props<{ cardId: OLO.CreditCards.CreditCardId; }>());

export const CreditCardsRemoveErrorRequest = createAction('[Credit cards] Error request remove card', props<{ ex?: any; }>());

export const CreditCardsValidateReset = createAction('[Credit cards] Reset card validation');

export const CreditCardsValidateRequest = createAction(
    '[Credit cards] Request card validation',
    props<{
        responseParams: APICommon.PaymentProviderPossibleResponseParams;
    }>(),
);

export const CreditCardsValidateSuccessRequest = createAction(
    '[Credit cards] Success request card validation',
    props<{ responseParams: APICommon.PaymentProviderPossibleResponseParams; card: OLO.DTO.PaymentExpressCardIdResponse; }>(),
);

export const CreditCardsValidateErrorRequest = createAction(
    '[Credit cards] Error request card validation',
    props<{ responseParams: APICommon.PaymentProviderPossibleResponseParams; }>(),
);

export const CreditCardsRemoveNonTokenizedCardCard = createAction('[Credit cards] Remove non tokenized card');

export const CreditCardsRemoveUnsavedCard = createAction('[Credit cards] Remove unsaved card', props<{ token: string; }>());

export const CreditCardsClearAllUnsavedCards = createAction('[Credit cards] Clear all unsaved cards');

export const CreditCardsSetErrorValidationStatusToValidatingCards = createAction('[Credit cards] Set error validation status to awaiting validation cards');

export const CreditCardsAppendCVVNumber = createAction('[Credit cards] Append CVV number', props<{ cardId: OLO.CreditCards.CreditCardId; cvv: string; }>());

/**
 * Card connect location config
 */
export const CardConnectLocationConfigRequest = createAction('[Credit cards] Card connect location config request', props<{ locationNo: number; }>());

export const CardConnectLocationConfigSuccessRequest = createAction(
    '[Credit cards] Card connect location config success request',
    props<{ locationNo: number; payload: OLO.DTO.CardConnectSettingsResponse; }>(),
);

export const CardConnectLocationConfigErrorRequest = createAction('[Credit cards] Card connect location config error request', props<{ locationNo: number; ex?: any; }>());

/**
 * Adyen PP setup actions
 */
export const CreditCardsAdyenAppendCVVNumber = createAction(
    '[Credit cards] Adyen append CVV number',
    props<{ cardId: OLO.CreditCards.CreditCardId; encryptedSecurityCode: string; }>(),
);

export const CreditCardsAdyenRemoveCVVNumber = createAction('[Credit cards] Adyen remove CVV number');

export const CreditCardsAdyenInit = createAction('[Credit cards] Adyen init');

export const CreditCardsAdyenConfigRequest = createAction('[Credit cards] Adyen setup request', props<{ locationNo: number; }>());

export const CreditCardsAdyenConfigSuccessRequest = createAction(
    '[Credit cards] Adyen setup success request',
    props<{ locationNo: number; config: OLO.DTO.AdyenSettingsResponse; }>(),
);

export const CreditCardsAdyenConfigErrorRequest = createAction('[Credit cards] Adyen setup error request', props<{ locationNo: number; }>());

/**
 * Stripe PP setup actions
 */
export const CreditCardsStripeInit = createAction('[Credit cards] Stripe init');

export const CreditCardsStripeConfigRequest = createAction('[Credit cards] Stripe setup request', props<{ locationNo: number; }>());

export const CreditCardsStripeConfigSuccessRequest = createAction(
    '[Credit cards] Stripe setup success request',
    props<{ locationNo: number; config: OLO.DTO.StripeSettingsResponse; }>(),
);

export const CreditCardsStripeConfigErrorRequest = createAction('[Credit cards] Adyen setup error request', props<{ locationNo: number; }>());

export const CreditCardsForceAskBillingDetailsForCard = createAction('[Credit cards] Force ask billing details for card', props<{ cardId: OLO.CreditCards.CreditCardId; }>());
