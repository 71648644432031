import { createSelector } from '@ngrx/store';

import { getOrderErrorsMapped } from '../../onlineOrder/getOrderErrorsMapped';
import { getCartTotalQuantity } from '@shared/state/cart/selectors';
import { isPaymentDisabledForOnlineOrderValidation } from '../isPaymentDisabledForOnlineOrderValidation';
import { isPaymentDisabledForMemberWithCreditCard } from '../isPaymentDisabledForMemberWithCreditCard';
import { isPaymentDisabledForPayments } from '@shared/state/payment/selectors';
import { isPaymentDisabledForCards } from '../isPaymentDisabledForCards';
import { isZeroPaymentsDisabled } from '../isZeroPaymentsDisabled';
import { hasExceededProductsLimit } from '../../restrictions/hasExceededProductsLimit';
import { isPaymentDisabledForAccountCharge } from '../isPaymentDisabledForAccountCharge';

export const isCardTypePaymentDisabled = (locationNo: number) =>
    createSelector(
        getOrderErrorsMapped(),
        getCartTotalQuantity,
        isPaymentDisabledForOnlineOrderValidation,
        isPaymentDisabledForMemberWithCreditCard,
        isPaymentDisabledForPayments,
        isPaymentDisabledForCards,
        isZeroPaymentsDisabled,
        hasExceededProductsLimit(locationNo),
        isPaymentDisabledForAccountCharge,
        (errors, cartTotalQuantity, orderPaymentDisabled, memberPaymentDisabled, paymentDisabledForPayments, cardsPaymentDisabled, zeroPaymentDisabled,
            exceededLimit, paymentDisabledForAccountCharge) =>
            !cartTotalQuantity ||
            paymentDisabledForPayments ||
            orderPaymentDisabled ||
            exceededLimit ||
            (memberPaymentDisabled && (zeroPaymentDisabled ?? memberPaymentDisabled)) ||
            (cardsPaymentDisabled && (zeroPaymentDisabled ?? cardsPaymentDisabled)) ||
            paymentDisabledForAccountCharge ||
            Boolean(errors),
    );
