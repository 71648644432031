import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartSimpleItemQuantity = (Plu: number, virtualLocationNo: number = null) => createSelector(
    cartState,
    (cart) => cart.itemsSimple.reduce((acc, curr) => {
        if (curr.Plu === Plu && curr.VirtualLocationNo === virtualLocationNo) {
            return acc += curr.Quantity;
        }

        return acc;
    }, 0)
);

