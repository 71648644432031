import { createSelector } from '@ngrx/store';
import { getOnlineMenuPagesFiltered } from '../getOnlineMenuPagesFiltered';
import { getOnlineMenuVirtualLocationsPagesFiltered } from '@shared/state/commonSelectors/onlineMenuVirtualLocations';
import * as Utils from '@shared/core/utils';

export const getOnlineMenuAndVirtualLocationsPagesFiltered = createSelector(
    getOnlineMenuPagesFiltered,
    getOnlineMenuVirtualLocationsPagesFiltered,
    (onlineMenuPages, onlineMenuVirtualLocationsPages) => Utils.Arrays.safeConcat(onlineMenuPages, onlineMenuVirtualLocationsPages),
);
