import * as Utils from '@shared/core/utils';

export class CardConnectPaymentProviderMapper {
    public static mapGetCardConnectSettings(cardConnectSettings: APIv3.CardConnectSettingsResponse): OLO.DTO.CardConnectSettingsResponse {
        return cardConnectSettings
            ? {
                ApiUrl: cardConnectSettings.ApiUrl,
                MerchantID: cardConnectSettings.MerchantID,
                IsAvsEnabled: cardConnectSettings.IsAvsEnabled,
            }
            : null;
    }

    public static mapCardConnectSecureCard(secureResponse: string): PPCardConnect.SecureResponse {
        /* '<?xml version="1.0" encoding="UTF-8"?><cardsecure><action>CE</action><data>9607172566116666</data></cardsecure>' */
        /* '<?xml version="1.0" encoding="UTF-8"?><cardsecure><action>ER</action><data>0008::Data not decimal digits</data></cardsecure>' */

        const obj: PPCardConnect.SecureResponse = Utils.XML.xmlString2Json(secureResponse);
        if (obj && obj.hasOwnProperty('cardsecure')) {
            return obj;
        }

        return null;
    }

    public static mapCardDetails(cardDetails: OLO.CreditCards.CreditCardDetails): PPCardConnect.RequestTokenBody {
        return {
            account: cardDetails.cardNumber,
            cvv: cardDetails.cvv,
            expiry: Utils.CreditCards.dateToCustomFormat(cardDetails.expiryDate, 'MMYY'),
        };
    }

    public static mapSecuredTokenResponse(response: PPCardConnect.ResponseToken): OLO.DTO.CardConnectTokenResponse {
        return {
            token: response.token,
            message: response.message,
            errorcode: response.errorcode,
        };
    }
}
