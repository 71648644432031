export class VirtualLocationsMapper {
    public static mapGetVirtualLocations(
        virtualLocations: APIv3.VirtualLocationBusinessModel[],
    ): OLO.DTO.VirtualLocationBusinessModel[] {
        return virtualLocations
            ? virtualLocations.map((virtualLocation: APIv3.VirtualLocationBusinessModel) => ({
                AccentColour: virtualLocation.AccentColour,
                LocationClassification: virtualLocation.LocationClassification,
                EOIPriceLevelID: virtualLocation.EOIPriceLevelID,
                LocationCode: virtualLocation.LocationCode,
                LocationDescription: virtualLocation.LocationDescription,
                LocationEmail: virtualLocation.LocationEmail,
                LocationFriendlyName: virtualLocation.LocationFriendlyName,
                LocationNo: virtualLocation.LocationNo,
                LocationNotes: virtualLocation.LocationNotes,
                LocationOLOIsActive: virtualLocation.LocationOLOIsActive,
                LocationPhone: virtualLocation.LocationPhone,
                LocationTags: virtualLocation.LocationTags ? virtualLocation.LocationTags.map(locationTag => ({
                    Id: locationTag.Id,
                    TagId: locationTag.TagId,
                    LocationNo: locationTag.LocationNo,
                    TagName: locationTag.TagName
                })) : null,
                MinimumPickupTime: virtualLocation.MinimumPickupTime,
                OnlineOrderConfirmationEmailNotes: virtualLocation.OnlineOrderConfirmationEmailNotes,
                OnlineOrderingStatus: virtualLocation.OnlineOrderingStatus,
                OrderTypes: virtualLocation.OrderTypes ? virtualLocation.OrderTypes.map(orderType => ({
                    Id: orderType.Id,
                    TypeDescription: orderType.TypeDescription,
                    IsEnabledForFutureOrdering: orderType.IsEnabledForFutureOrdering,
                    FutureOrderingMinDaysAhead: orderType.FutureOrderingMinDaysAhead,
                    FutureOrderingMaxDaysAhead: orderType.FutureOrderingMaxDaysAhead,
                    OrderingTimeInfo: orderType.OrderingTimeInfo ? orderType.OrderingTimeInfo.map(orderingTimeInfo => ({
                        DayOfWeek: orderingTimeInfo.DayOfWeek,
                        Date: orderingTimeInfo.Date,
                        OpeningTime: orderingTimeInfo.OpeningTime,
                        ClosingTime: orderingTimeInfo.ClosingTime,
                        PickupTimes: orderingTimeInfo.PickupTimes ? orderingTimeInfo.PickupTimes.map(pickupTime => ({
                            From: pickupTime.From,
                            To: pickupTime.To,
                            MinimumPickupTime: pickupTime.MinimumPickupTime
                        })) : null
                    })): null
                })) : null,
            })) : null;
    }
}
