import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { switchMap, take, distinct, withLatestFrom, filter } from 'rxjs/operators';

@Injectable()
export class CurrentLocationEffects {
    public onOrderTypeSetSelectionAndAvailablePickupCalculationSetCurrentLocationPickupTime$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.AvailablePickupsCalculateSuccessRequest),
            switchMap(() =>
                this._store.pipe(
                    select(selectors.getCurrentLocationDetails),
                    filter((location) => location != null),
                    take(1),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getOrderTypeId)),
                        this._store.pipe(select(selectors.isCollectionTypeDineIn)),
                        this._store.pipe(select(selectors.getCurrentPickupTime)),
                    ),
                    switchMap(([location, orderTypeId, isDineIn, currentPickupTime]) => {
                        const pickups = Utils.LocationPickups.getAvailablePickupTimesWithFutureForLocation({
                            location,
                            orderTypeId,
                            futureOrders: this._config.onlineOrders.scheduledOrders === true,
                        });

                        const asapPickupForCurrentLocation = pickups?.find((obj) => obj.IsAsap === true && obj.IsToday === true);
                        if (isDineIn) {
                            if (!asapPickupForCurrentLocation) {
                                console.error(`Unable to get asap pickup time for current location: ${location.LocationNo}`);

                                return [];
                            }
                            // CURRENT PICKUPTIME FOR LOCATION IS SAME AS ASAP
                            if (JSON.stringify(asapPickupForCurrentLocation) === JSON.stringify(currentPickupTime)) {
                                return [];
                            }

                            return [actions.CurrentLocationPickupTimeSet(asapPickupForCurrentLocation)];
                        }

                        return [actions.CurrentLocationPickupTimeSet(pickups?.[0])];
                    }),
                ),
            ),
        ),
    );

    @Effect() public onCartPickupTimeUpdateForceUpdateCurrentLocationPickupTime$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartPickupTimeUpdate),
        switchMap((action) => [
            actions.LocationsFiltersSyncPickupTime(action.pickupTime.IsAsap ? null : action.pickupTime),
            actions.CurrentLocationPickupTimeSet(action.pickupTime),
        ]),
    );

    @Effect({ dispatch: false }) public removeLocalStorageDataOnReset$: Observable<void> = this._actions$.pipe(
        ofType(actions.CurrentLocationReset),
        switchMap(() => {
            Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);
            Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
            Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_LOCATION_UPDATE_DATA as unknown as string);

            return [];
        }),
    );

    @Effect({ dispatch: false }) public savePickupTimeInLocalStorage$: Observable<void> = this._actions$.pipe(
        ofType(actions.CurrentLocationPickupTimeSet, actions.CurrentLocationFiltersPickupTimeSync),
        switchMap(({ pickupTime }) => {
            if (!pickupTime) {
                Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string);

                return [];
            }

            const obj = {
                ...pickupTime,
                Date: pickupTime.Date.getTime(),
                PlaceOrderTimeout: pickupTime.Date.getTime(),
            };

            Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME as unknown as string, obj);

            return [];
        }),
    );

    @Effect({ dispatch: false }) public onLocationSetSaveItToLocalStorage$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CurrentLocationSet),
        distinct((action) => action.locationNo),
        switchMap((action) => {
            Utils.Storage.set(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string, action.locationNo);

            return [];
        }),
    );

    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _actions$: Actions, private _store: Store<OLO.State>) {}
}
