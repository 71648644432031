import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { VirtualLocationsEffects } from './virtual-locations.effects';
import { virtualLocationsReducer } from './virtual-locations.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('virtualLocations', virtualLocationsReducer),
        EffectsModule.forFeature([
            VirtualLocationsEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class VirtualLocationsStateModule { }
