import { createSelector } from '@ngrx/store';
import { creditCardsState } from '../root';

export const getActiveCardDetails = createSelector(creditCardsState, (creditCards) => {
    const activeId: OLO.CreditCards.CreditCardId = creditCards.activeCardId;
    const activeToken: string = creditCards.activeCardToken;
    const cards: OLO.Members.MemberCreditCardDetails[] = creditCards.data;

    const foundNonTokenizedCard = cards?.find((card) => card.Id == null && card.Token == null) || null;
    if (!activeId && !activeToken && foundNonTokenizedCard) {
        return foundNonTokenizedCard;
    }

    if ((!activeId && !activeToken) || !cards || cards.length === 0) return null;

    return cards.find((card) => (activeId ? card.Id === activeId : card.Token === activeToken)) || null;
});
