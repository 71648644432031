import { FormGroup, FormArray, FormControl } from '@angular/forms';

export class Forms {
    public static extractValues<T extends { [key: string]: any; }>(form: FormGroup): T {
        return Object.keys(form.controls).reduce((acc, controlName) => {
            const nextCtrl = form.get(controlName);

            return ({
                ...acc,
                [controlName]: nextCtrl instanceof FormGroup ? Forms.extractValues(nextCtrl) : nextCtrl.value,
            });
        }, {} as T);
    }

    public static labelToName(str: string): string {
        if (!str || typeof str !== 'string') return null;

        return str.toLowerCase().replace(/[^a-z]/g, '');
    }

    /**
     * Searches for a specific form control field
     * @param {FormGroup | FormArray} formControl - form or part of the form
     * @param {string} search - control name
     */
    public static findControl = (formControl: FormGroup | FormArray, search: string): Nullable<FormControl> => {
        if (!formControl) return null;

        return formControl.controls[search] || Forms.findControl(formControl.parent, search);
    };
}
