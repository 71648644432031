import { createSelector } from '@ngrx/store';
import { getOnlineMenuVirtualLocation } from '@shared/state/onlineMenuVirtualLocations/selectors';
import { getOnlineMenuPageImage } from '@shared/state/onlineMenuPagesImages/selectors';

export const getOnlineMenuVirtualLocationsPageDetails = (locationNo: number, pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb) =>
    createSelector(getOnlineMenuVirtualLocation(locationNo), getOnlineMenuPageImage(pageId, imageType), (virtualLocationOnlineMenu, image) => {
        if (!virtualLocationOnlineMenu || !virtualLocationOnlineMenu?.data) return null;

        const foundPage = virtualLocationOnlineMenu?.data.Pages.find((obj) => obj.Id === pageId);
        if (!foundPage) return null;

        return {
            title: foundPage.Name,
            description: foundPage.Description,
            image: image,
        } as Nullable<OLO.Components.OnlineMenuPageDetailsWithStateImage>;
    });
