import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Utils from '@shared/core/utils';

import { DrawCollapseService } from '@shared/core/directives/drawCollapse/draw-collapse.shared.service';

import { BehaviorSubject, Subject } from 'rxjs';
import { RegistryId, Registry, RegistryItem, RegisterTargetParams, SetCurrentParams, RemoveOpts, GlobalScrollParams } from './sub-nav.shared.interface';

@Injectable({
    providedIn: 'root',
})
export class SubNavService {
    public scrollingTime: number = 500;
    public activationThreshold: number = 70;
    public isScrolling: boolean = false;

    public registries: { [registryId: RegistryId]: Registry; } = {};
    /** For each different registry provide information, which item is currently active */
    public activeItemsInRegistries: { [registryId: RegistryId]: RegistryItem; } = {};

    public currentTargets$: BehaviorSubject<typeof this.activeItemsInRegistries> = new BehaviorSubject(this.activeItemsInRegistries);
    public heightUpdate$: Subject<number> = new Subject();
    public reorderingContainerRects?: ClientRect;

    constructor(protected _drawCollapseService: DrawCollapseService, protected _router: Router) {}

    public forceHeightUpdate(): void {
        this.heightUpdate$.next(Math.random());
    }

    public registerTarget({ registryId, targetId, htmlElement, setDefault = true, contextTargetId = null, contextRegistryId = null }: RegisterTargetParams): void {
        //
        //  Register target to scroll
        //  and set default currentTarget if not set
        //
        if (!this.registries[registryId]) {
            this.registries[registryId] = new Set();
        }

        const newItem: RegistryItem = {
            registryId,
            targetId,
            htmlElement,
            contextTargetId,
            contextRegistryId,
        };

        if (!newItem.targetId == null) {
            // LOADING CASE - want to avoid that.Otherwise it will prevent from proper scrolling
            return;
        }

        this.registries[registryId].add(newItem);

        if (!this.activeItemsInRegistries[registryId] && setDefault) {
            this.setCurrent(newItem);
        }
    }

    public setCurrent({ registryId, targetId, contextTargetId = null, contextRegistryId = null }: SetCurrentParams): void {
        if (this.isScrolling) return;

        const reg = this.registries[registryId];
        const foundInRegistry =
            Array.from(reg).find((obj) => obj.targetId === targetId && obj.contextTargetId === contextTargetId && obj.contextRegistryId === contextRegistryId) || null;
        if (!foundInRegistry && targetId) {
            return;
        }

        this.activeItemsInRegistries[registryId] = foundInRegistry;

        /**
         * Make sure context changes as well
         */
        const ctx = this.registries?.[foundInRegistry?.contextRegistryId];
        const shouldUpdateContext = foundInRegistry?.contextRegistryId !== registryId && ctx;
        if (shouldUpdateContext) {
            const contextRegItem = Array.from(ctx).find((obj) => obj.targetId === foundInRegistry.contextTargetId) || null;
            this.activeItemsInRegistries[foundInRegistry.contextRegistryId] = contextRegItem;
        }

        this.currentTargets$.next(this.activeItemsInRegistries);
    }

    public removeTarget({ registryId, targetId, contextTargetId = null }: RemoveOpts): void {
        //
        //  Remove target areas and cleanup in current registry
        //
        const reg = this.registries[registryId];
        if (!reg) {
            return;
        }
        const foundRegItem = Array.from(reg).find((obj) => obj.targetId === targetId && obj.contextTargetId === contextTargetId);
        if (!foundRegItem) return;

        reg.delete(foundRegItem);
        if (reg.size === 0) {
            delete this.registries[registryId];
        }

        const activeRegItem = this.activeItemsInRegistries[registryId];
        if (!activeRegItem) {
            return;
        }

        if (activeRegItem.targetId === targetId && activeRegItem.contextTargetId === contextTargetId) {
            if (!this.registries[registryId]) {
                delete this.activeItemsInRegistries[registryId];

                this.currentTargets$.next(this.activeItemsInRegistries);
            } else {
                this.setCurrent({
                    registryId,
                    targetId: null,
                });
            }
        }
    }

    public unsetRegistry(registryId: RegistryId): void {
        delete this.activeItemsInRegistries[registryId];
        delete this.registries[registryId];
    }

    public clear(): void {
        this.activeItemsInRegistries = {};
        this.registries = {};
        this.currentTargets$.next(this.activeItemsInRegistries);
    }

    public navToUrl(url: string): Promise<boolean> {
        return this._router.navigate([url]);
    }

    public async globalScrollTo({
        parentHeightAdjustElement = null,
        index = null,
        registryId,
        targetId,
        contextTargetId = null,
        contextRegistryId = null,
    }: GlobalScrollParams): Promise<boolean> {
        return new Promise((resolve) => {
            const registry = this.registries[registryId];
            if (!registry) return;

            const regItems = Array.from(registry);
            const foundRegItem = regItems.find((obj) => obj.targetId === targetId && obj.contextTargetId === contextTargetId && obj.contextRegistryId === contextRegistryId);
            if (!foundRegItem) {
                return;
            }

            const elem: HTMLElement = foundRegItem.htmlElement;
            if (!elem) return;

            this.setCurrent(foundRegItem);
            this.isScrolling = true;

            //
            //  Due to draw directive and f** scrolling page ideas, we need to
            //  'help' scroller to scroll back to first element... so CS with 'index'
            //
            const parentHeightOffset: number =
                contextTargetId === null && index === 0 ? 0 : (parentHeightAdjustElement?.clientHeight || 0) + this._drawCollapseService.maxContentMargin;
            const targetOffset: number | HTMLElement = elem.offsetTop + parentHeightOffset;

            Utils.Scroll.smoothScrollTo(targetOffset, this.scrollingTime, 'easeInOutCubic', () => {
                setTimeout(() => {
                    this.isScrolling = false;
                    resolve(true);
                }, 200);
            });
        });
    }

    public getRegistryItem(registryId: RegistryId, hostBottomEdge: number): RegistryItem | null {
        let foundRegistryItem = null;
        for (const nextRegItem of this.registries[registryId]) {
            if (foundRegistryItem) {
                break;
            }

            const rects: ClientRect = nextRegItem.htmlElement.getClientRects()[0];
            if (!rects) {
                break;
            }

            const edgeTop: number = this.reorderingContainerRects ? rects.top - this.reorderingContainerRects.height : rects.top;
            const edgeBottom: number = rects.top + nextRegItem.htmlElement.clientHeight;

            const isHostBelowTopEdge: boolean = edgeTop - this.activationThreshold < hostBottomEdge;
            const isHostAboveElemsBottomEdge: boolean = edgeBottom - this.activationThreshold > hostBottomEdge;
            if (isHostBelowTopEdge && isHostAboveElemsBottomEdge) {
                foundRegistryItem = nextRegItem;
            }
        }

        return foundRegistryItem;
    }
}
