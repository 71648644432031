import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';

import { Observable, of, never, iif } from 'rxjs';
import { switchMap, take, first, filter, map, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class MenuFlowsEffects {
    @Effect() public onRequestMenuFlowDetails$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MenuFlowsDetailsRequest),
            mergeMap(({ locationNo, menuFlowId, virtualLocationNo }) => this._store.select(selectors.getMenuFlow(menuFlowId, locationNo))
                .pipe(
                    first(),
                    filter(menuFlow => menuFlow.MenuFlowId !== null),
                    mergeMap(({ LocationNo, MenuFlowId }) => this._menuFlowsService.getMenuFlowDetailsForLocation(MenuFlowId, LocationNo)
                        .pipe(
                            map(payload => actions.MenuFlowsDetailsSuccessRequest({ virtualLocationNo, menuFlowId: MenuFlowId, locationNo: LocationNo, payload })),
                            catchError(ex => of(actions.MenuFlowsDetailsErrorRequest({ virtualLocationNo, menuFlowId: MenuFlowId, locationNo: LocationNo, ex })))
                        )
                    ),
                )),
        );

    @Effect() public onMenuFlowSuccessRequestGetUpsell$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.MenuFlowsDetailsSuccessRequest),
            switchMap(action =>
                /* Get upsell upon success menuflow request due to EDIT problems on cart when hitting page with different url */
                iif(() => !action.payload.UpsellMenuFlowId, never(), this._store.pipe(
                    select(selectors.getMenuFlow(action.payload.UpsellMenuFlowId, action.locationNo)),
                    filter(upsellMenuFlow => upsellMenuFlow === undefined || upsellMenuFlow === null || upsellMenuFlow.hasFailed === true),
                    take(1),
                    switchMap(upsell => of(actions.MenuFlowsDetailsRequest({
                        menuFlowId: action.payload.UpsellMenuFlowId,
                        locationNo: action.locationNo
                    })))
                ))
            )
        );

    constructor(
        private _actions$: Actions,
        private _menuFlowsService: Services.MenuFlowsService,
        private _store: Store<OLO.State>,
    ) { }
}
