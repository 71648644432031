import { createSelector } from '@ngrx/store';

import { isMemberAuthorizedJWT, getMemberState, freeValidProducts, loyaltyProducts } from '@shared/state/members/selectors';
import { getAllValidLoyaltyPrograms, isDownloadingAnyProgram, getLoyaltyProductPrograms } from '@shared/state/loyaltyProductPrograms/selectors';

export const getLoyaltyFreeProductsForCurrentMember = createSelector(
    isMemberAuthorizedJWT,
    getMemberState,
    freeValidProducts,
    loyaltyProducts,
    getLoyaltyProductPrograms,
    getAllValidLoyaltyPrograms,
    isDownloadingAnyProgram,
    (isAuthorized, member, freeProducts, loyaltyProductsResult, programsState, programs, isDownloadingPrograms) => {
        if (!isAuthorized || !member?.data || !freeProducts || !loyaltyProductsResult || !loyaltyProductsResult.data) return null;

        return freeProducts.reduce((acc, product) => {
            if (product?.MemberId !== member?.data?.MemberId) return acc;

            const pr: OLO.DTO.GetLoyaltyProductProgramBusinessModel = programs?.find((obj) => obj.ProductId === product.ProductId);
            if (!pr) return acc;
            const loyaltyProduct = loyaltyProductsResult?.data?.find((obj) => obj.PLU === pr.PLU); /* ROTFL ROTFL ROTFL... */
            if (!loyaltyProduct) return acc;

            const codeName: string = programsState?.data?.find((obj) => obj.Id === pr.Id)?.ProgramName || null;

            return [
                ...acc,
                {
                    isLoading: isDownloadingPrograms || member.freeProducts.isDownloading || member.loyaltyProducts.isDownloading,
                    freeProduct: [product],
                    loyaltyProduct,
                    program: pr,
                    codeName,
                },
            ];
        }, []) as OLO.Ordering.LoyaltyFreeProductItemModel[];
    },
);
