import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { SubNavBuilder } from '@shared/core/models';
import { getLocationDetails } from '@shared/state/locations/selectors/getLocationDetails';
import { getAllLocationImages } from '@shared/state/locationsImages/selectors/getAllLocationImages';
import { getOnlineMenuPagesFiltered } from '../../onlineMenu/getOnlineMenuPagesFiltered';
import { getOnlineMenuVirtualLocationsFiltered } from '../../onlineMenuVirtualLocations/getOnlineMenuVirtualLocationsFiltered';
import { getOrderTypeId } from '@shared/state/collectionType/selectors';

const pageMapper = (locationNo: number) => (page: APIv3_SNAPSHOT.OnlineMenuPageResponseModel) => {
    const model = new SubNavBuilder(page).setId(page.Id)
        .setParentId(locationNo)
        .setName(page.Name)
        .build();

    return model;
};

/**
 * Returns full tree of location and virtual locations with online menu pages
 */
export const getLocationSubNav = (locationNo?: Nullable<number>, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForLogo) =>
    createSelector(
        getLocationDetails(locationNo),
        getAllLocationImages,
        getOnlineMenuPagesFiltered,
        getOnlineMenuVirtualLocationsFiltered,
        getOrderTypeId,
        (location, images, mainOnlineMenuPages, virtualLocationsOnlineMenu, orderTypeId) => {
            const virtualLocationsOnlineMenuData = (location?.VirtualLocations || [])
                .filter(virtualLocation => virtualLocation.OrderTypes.some(virtualLocationOrderType => virtualLocationOrderType.Id === orderTypeId))
                .filter(virtualLocation => virtualLocationsOnlineMenu
                    ?.find((onlineMenuVirtualLocation) => onlineMenuVirtualLocation.virtualLocationNo === virtualLocation.LocationNo)
                );

            let result: Nullable<OLO.Locations.SubNavLocationPagesTree> = null;
            if (!location || !virtualLocationsOnlineMenuData?.length) {
                return result;
            }
            const targetImageType = Utils.Images.toImageTypeString(imageType);

            const virtualLocations = virtualLocationsOnlineMenuData
                .map(virtualLocation => {
                    const imgsVirtualLocation = images[targetImageType]?.filter((obj) => obj.Id === virtualLocation.LocationNo) || [];
                    const imgUrl = imgsVirtualLocation.find((imgObj) => imgObj.Id === virtualLocation.LocationNo)?.data?.ImageUrl;

                    const items = virtualLocationsOnlineMenu
                        ?.find((onlineMenuVirtualLocation) => onlineMenuVirtualLocation.virtualLocationNo === virtualLocation.LocationNo)
                        ?.data?.Pages?.map(pageMapper(virtualLocation.LocationNo));

                    const model = new SubNavBuilder<APIv3_SNAPSHOT.OnlineMenuPageResponseModel>(virtualLocation)
                        .setId(virtualLocation.LocationNo)
                        .setName(virtualLocation.LocationDescription)
                        .setAccentColour(virtualLocation.AccentColour)
                        .setIsVirtualLocation(true)
                        .setParentId(locationNo)
                        .setImgUrl(imgUrl)
                        .setItems(items)
                        .build();

                    return model;
                });

            const imgs = images[targetImageType]?.filter((obj) => obj.Id === locationNo) || [];
            const mainLocationImg = imgs.find((obj) => obj.Id === locationNo)?.data?.ImageUrl;
            const mainLocationPages = mainOnlineMenuPages?.map(pageMapper(locationNo));
            const mainLocationModel = new SubNavBuilder<APIv3_SNAPSHOT.OnlineMenuPageResponseModel>(location)
                .setId(locationNo)
                .setName(location.LocationDescription)
                .setImgUrl(mainLocationImg)
                .setItems(mainLocationPages)
                .build();

            result = {
                mainLocation: mainLocationModel,
                virtualLocations,
            };

            return result;
        },
    );
