import { Injector, Injectable, InjectionToken } from '@angular/core';

@Injectable()
export class StaticProvider<T = any> {
    public static instance: StaticProvider<any>;

    protected _injector: Injector;

    private _current!: T;

    constructor() {
        if (StaticProvider.instance) {
            return StaticProvider.instance;
        }

        StaticProvider.instance = this;

        return this;
    }

    public registerInjector(injector: Injector): StaticProvider<T> {
        if (this._injector) return this;

        this._injector = injector;

        return this;
    }

    protected _setCurrent(current: InjectionToken<T>): void {
        this._current = this._injector?.get(current);
    }

    public get current() {
        return this._current;
    }

    public set current(val) {
        console.error('You are not allowed to modify statics');
    }
}

/**
 * Creates statics - a way to provide angular-injected static values into not angular related modules, i.e. utils or selectors
 * @param injector
 * @returns static provider
 */
export const staticProviderFactory = (injector: Injector) => {
    const provider = new StaticProvider();

    return provider.registerInjector(injector);
};
