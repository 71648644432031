import { Model } from '../model';
import { Objects } from '@shared/core/utils/objects.utils';

export class ApplicationConfig extends Model<OLO.Config> {
    constructor(config: OLO.Config) {
        super();

        this._model = config;
    }

    /** Returns deep sealed config object */
    public toDeepSealedJson(): OLO.Config {
        const json = this.toJson();
        Objects.uberDeepFreez(json);

        if (json.demoMode) {
            console.warn('Demo mode on. Standard payment flows will be omitted.');
        }

        if (json.payments.testMode) {
            console.warn('Test mode is turned on for some payment providers. This should not be used in production envrionment.');
        }

        return json;
    }

    public static get defaultConfig(): OLO.Config {
        return {
            appMode: 0,
            demoMode: false,
            title: 'Task OLO - Order your meal online and save time',
            geolocation: true,
            api: {
                base: 'https://taskoloapidev.xchangefusion.com/api/v3',
                key: 'b6985afc-7087-4fe0-9e2a-a89cefbdba9c',
            },
            venue: {
                id: null,
                name: null,
            },
            virtualLocations: {
                enabled: false
            },
            payments: {
                accountCharge: {
                    enabled: true,
                    balanceDisplayThreshold: 1001,
                },
                baseProvider: null,
                redirectType: true,
                payInStore: true,
                testMode: false,
                googlePay: {
                    enabled: true,
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    merchantName: null,
                    gateway: 'windcave',
                    gatewayMerchantId: null,
                    allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                    allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                },
                applePay: {
                    enabled: true,
                    merchantId: 'test',
                    displayName: 'testMerchantName',
                    initiativeContext: 'tasksoftware.com',
                    apiVersion: 3,
                    supportedNetworks: ['visa', 'masterCard', 'jcb', 'discover', 'amex'],
                    merchantCapability: ['supports3DS', 'supportsCredit', 'supportsDebit', 'supportsEMV'],
                },
            },
            localization: {
                country: 'AU',
                units: 'metric',
                currency: 'USD',
                locale: 'en-US',
            },
            links: [
                {
                    id: 1,
                    name: 'contactUs',
                    label: 'Contact Us',
                    url: 'https://tasksoftware.com',
                },
                {
                    id: 2,
                    name: 'userAgreement',
                    label: 'User Agreement',
                    url: 'https://tasksoftware.com',
                },
                {
                    id: 3,
                    name: 'privacyPolicy',
                    label: 'Privacy Policy',
                    url: 'https://tasksoftware.com',
                },
            ],
            google: {
                maps: {
                    apiKey: '',
                    libraries: ['places'],
                    defaults: {
                        center: {
                            lat: 33.6294864,
                            lng: -117.71724360000002,
                        },
                        zoom: 10,
                        styles: [],
                    },
                    componentRestrictions: {
                        country: ['pl', 'au', 'us'],
                    },
                    searchDistance: 10,
                    markerWidth: 50,
                    markerHeight: 69,
                    markerSelectedWidth: 75,
                    markerSelectedHeight: 119,
                    markerDownsizeFactor: 2,
                },
                tagManager: {
                    containerIDs: [],
                },
            },
            images: {
                defaultLocationThumb: '/assets/images/img.location-thumb.jpg',
            },
            collectionTypes: [
                {
                    collectionTypeId: -1,
                    enabled: true,
                    displayName: 'Pickup',
                    displayIndex: 0,
                    orderTypeIds: [1, 2],
                    url: null,
                    startBufferMins: 0,
                    endBufferMins: 0,
                    orderTimeoutBufferMins: 0,
                    nextTick: 15,
                },
                {
                    collectionTypeId: -2,
                    enabled: true,
                    displayName: 'Delivery',
                    displayIndex: 0,
                    orderTypeIds: [9999],
                    url: null,
                    startBufferMins: 0,
                    endBufferMins: 0,
                    orderTimeoutBufferMins: 0,
                    nextTick: 15,
                    displayAsTimespans: false,
                },
                {
                    collectionTypeId: -3,
                    enabled: true,
                    displayName: 'Dine In',
                    displayIndex: 1,
                    url: null,
                    orderTypeIds: [3, 4],
                    dineInTable: {
                        orderTypeId: 3,
                        modalIcon: 'tableno',
                        modalPreTitle: 'DINE IN ORDER',
                        modalTitle: 'Enter your table number',
                        modalDescription: ['Enter your table number, choose items from', 'our menu and we\'ll bring your order to you'],
                        modalDescriptionSelected: ['Start your order, choose some delicious Items from', 'our menu and we\'ll bring your order to you!'],
                    },
                    dineInBuzzer: {
                        orderTypeId: 4,
                        modalIcon: 'phone',
                        modalPreTitle: 'DINE IN ORDER',
                        modalDescription: ['Start your order, choose items from our', 'menu and we’ll send you an SMS message', 'when your order is ready to collect.'],
                    },
                },
                {
                    collectionTypeId: -4,
                    enabled: true,
                    displayName: 'Holiday Ordering',
                    displayIndex: 2,
                    orderTypeIds: [5],
                    url: 'holiday-ordering',
                    startBufferMins: 0,
                    endBufferMins: 0,
                    orderTimeoutBufferMins: 0,
                    nextTick: 15,
                },
            ],
            topBar: [
                {
                    orderTypeIds: [],
                    currentLocationOrderTypeText: 'Pick up from',
                },
                {
                    orderTypeIds: [3, 4],
                    currentLocationOrderTypeText: 'Dine in at',
                },
                {
                    orderTypeIds: [5],
                    currentLocationOrderTypeText: 'Holiday order from',
                },
            ],
            locationMoreInfoModal: {
                changeLocationButton: 'Change store',
                viewMenuButton: 'View menu',
            },
            landingPage: [
                {
                    orderTypeIds: [],
                    heroBackground: {
                        topGradient: true,
                        overlay: 'half-angled',
                        image: 'hero.bg.jpg',
                    },
                    locationMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: 'Find a nerby store to begin your order',
                        searchPlaceholderText: 'Search by suburb, city, postcode',
                        searchMode: OLO.Enums.SEARCH_MODE.LOCATION_ADDRESS,
                        topLocationsArea: {
                            title: 'Your closest stores',
                            viewAllLocationsButton: 'View all stores',
                        },
                    },
                    venueMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: {
                            line1: 'When would you like to pick up your',
                            line2: 'order?',
                        },
                        topLocationsArea: {
                            title: 'Popular stores available now',
                            viewAllLocationsButton: 'View all available stores',
                        },
                    },
                    dynamicBanners: [],
                },
                {
                    orderTypeIds: [1, 2],
                    heroBackground: {
                        topGradient: true,
                        overlay: 'half-angled',
                        image: 'hero.bg.jpg',
                    },
                    locationMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: 'Find a nerby store to begin your order',
                        searchPlaceholderText: 'Search by suburb, city, postcode',
                        searchMode: OLO.Enums.SEARCH_MODE.LOCATION_ADDRESS,
                        topLocationsArea: {
                            title: 'Your closest stores',
                            viewAllLocationsButton: 'View all stores',
                        },
                    },
                    venueMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: {
                            line1: 'When would you like to pick up your',
                            line2: 'order?',
                        },
                        topLocationsArea: {
                            title: 'Popular stores available now',
                            viewAllLocationsButton: 'View all available stores',
                        },
                    },
                    dynamicBanners: [
                        {
                            header: 'Holiday ordering now available',
                            body: ['Enter the number on your printer card. Don\'t have a card?', 'Just skip this step below and we will make you a shiny new', 'digital one'],
                            actionButtonText: 'Order now',
                            actionButtonUrl: '/holiday-ordering',
                            image: 'hero.bg.jpg',
                        },
                    ],
                },
                {
                    orderTypeIds: [9999],
                    heroBackground: {
                        topGradient: true,
                        overlay: 'full',
                        image: 'hero.bg.jpg',
                    },
                    locationMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: 'Find a nerby store to begin your order',
                        searchPlaceholderText: 'Search by suburb, city, postcode',
                        searchMode: OLO.Enums.SEARCH_MODE.DELIVERY_ADDRESS,
                        topLocationsArea: {
                            title: 'Your closest stores',
                            viewAllLocationsButton: 'View all stores',
                        },
                    },
                    venueMode: {
                        preHeader: {
                            morningText: 'Good Morning',
                            afternoonText: 'Good Afternoon',
                            eveningText: 'Good Evening',
                        },
                        header: {
                            line1: 'When would you like to pick up your',
                            line2: 'order?',
                        },
                        topLocationsArea: {
                            title: 'Popular stores available now',
                            viewAllLocationsButton: 'View all available stores',
                        },
                    },
                    dynamicBanners: [],
                },
                {
                    orderTypeIds: [5],
                    heroBackground: {
                        topGradient: true,
                        overlay: 'full',
                        image: 'hero.bg.jpg',
                    },
                    locationMode: {
                        preHeader: {
                            morningText: 'Happy Holidays',
                            afternoonText: 'Happy Holidays',
                            eveningText: 'Happy Holidays',
                        },
                        header: 'Find a store to place your holiday order',
                        searchPlaceholderText: 'Search by suburb, city, postcode',
                        searchMode: OLO.Enums.SEARCH_MODE.LOCATION_ADDRESS,
                        topLocationsArea: {
                            title: 'Your closest stores',
                            viewAllLocationsButton: 'View all stores',
                        },
                    },
                    venueMode: {
                        preHeader: {
                            morningText: 'Happy Holidays',
                            afternoonText: 'Happy Holidays',
                            eveningText: 'Happy Holidays',
                        },
                        header: {
                            line1: 'When would you like to pick up your',
                            line2: 'holiday order?',
                        },
                        topLocationsArea: {
                            title: 'Popular stores available for holiday order',
                            viewAllLocationsButton: 'View all available stores',
                        },
                    },
                    dynamicBanners: [],
                },
            ],
            locationListPage: [
                {
                    orderTypeIds: [],
                    locationMode: {
                        listResultsCountText: 'store found',
                        listResultsCountTextPlural: '{{searchResultCount}} stores found',
                    },
                    venueMode: {
                        listResultsCountText: 'store available {{pickupInfo}}',
                        listResultsCountTextPlural: '{{searchResultCount}} stores available {{pickupInfo}}',
                    },
                },
                {
                    orderTypeIds: [5],
                    locationMode: {
                        listResultsCountText: 'Holiday ordering near {{searchTerm}}',
                        listResultsCountTextPlural: '{{searchResultCount}} Holiday ordering near {{searchTerm}}',
                    },
                    venueMode: {
                        listResultsCountText: 'Holiday order available {{pickupInfo}}',
                        listResultsCountTextPlural: '{{searchResultCount}} Holiday order available {{pickupInfo}}',
                    },
                },
            ],
            onlineOrders: {
                saleName: 'TASK',
                scheduledOrders: true,
                sendAutoConfirmationEmail: true,
                sendAutoReceiptEmail: true,
                allowModifiers: true,
                allowClosedLocationOrders: true,
                discounts: null,
                scheduleOrderModals: [
                    {
                        orderTypeIds: [],
                        modal: {
                            preTitle: 'SCHEDULED ORDER',
                            title: ['When do you want to order?'],
                            description: ['Select from the available days & times below'],
                        },
                        showCollectionTypeDropdown: true,
                    },
                ],
                updateOrderModals: [
                    {
                        orderTypeIds: [1, 5],
                        statusFilter: [0, 1, 2, 3, 4],
                        pickupDateFilterInHours: 24,
                        modal: {
                            preTitle: 'Update Your Order',
                            title: ['Change collection time'],
                            description: ['Choose a new collection time and date from', 'The below available times.'],
                            buttonText: 'Collect on {{pickupDate}}',
                        },
                    },
                ],
                cancelOrderModals: [
                    {
                        orderTypeIds: [1, 2, 5],
                        statusFilter: [0, 1, 2, 3, 4],
                        pickupDateFilterInHours: 24,
                        modal: {
                            preTitle: 'Update Your Order',
                            title: ['Are you sure you want to', 'cancel your order'],
                            description: [],
                            buttonYesText: 'Yes, cancel',
                            buttonNoText: 'No',
                            actionSuccess: {
                                header: ['Your order #{{orderNumber}}', 'has been cancelled'],
                                body: [],
                            },
                            actionFailure: {
                                header: ['Something went wrong!'],
                                body: ['Please try again later'],
                            },
                        },
                    },
                ],
                activeOrderModals: [
                    {
                        orderTypeIds: [],
                        modal: {
                            preTitle: 'Order Details',
                            title: ['Pick up {{pickupDate}}', 'From {{locationName}}'],
                            detailsSection: {
                                header: [],
                                body: [],
                            },
                        },
                    },
                    {
                        orderTypeIds: [5],
                        modal: {
                            preTitle: 'Order Details',
                            title: ['Collect {{pickupDate}}', 'From {{locationName}}'],
                            detailsSection: {
                                header: ['Holiday Order'],
                                body: ['Collect on {{pickupDate}}'],
                            },
                        },
                    },
                ],
            },
            onlineMenu: {
                energyUnits: 1,
                disclaimer: '*The average adult daily energy intake is 8700 kj',
                searchPlaceholderText: 'Search products',
                showSpecialInstructionsForProducts: true,
                showSpecialInstructionsForMenuFlows: true,
                description: {
                    startAt: 0,
                    limit: 62,
                    ellipsis: '...',
                    completeWords: false,
                },
                wizzardDescription: {
                    startAt: 0,
                    limit: 82,
                    ellipsis: '...',
                    completeWords: false,
                },
            },
            locationDetailsPage: [
                {
                    orderTypeIds: [],
                    showCategoriesOnInitInMobileView: true,
                    firstLoadIntroductionModal: null,
                    editableAmountProduct: false,
                },
                {
                    orderTypeIds: [5],
                    showCategoriesOnInitInMobileView: false,
                    editableAmountProduct: false,
                    firstLoadIntroductionModal: {
                        modalPreTitle: 'START YOUR ORDER',
                        modalTitle: 'TASK holiday ordering',
                        modalDescription: ['Placing your holiday order is easy. Set your collection', 'Time and date below, then start browsing our', 'delicious holiday menu'],
                        showCollectionTypeDropdown: true,
                    },
                },
            ],
            cart: {
                checkoutMaxCartItemsLines: 3,
                emptyCartBody: 'Your bag is empty',
                emptyCartButton: 'Choose store',

                clearOnLogoutMode: 2,
                clearOnLogoutModal: {
                    header: 'You have items in the cart',
                    body: 'There are still some items in your cart. Do you want to clear cart before logout?',
                    yesButtonText: 'Yes',
                    noButtonText: 'No',
                },
            },
            checkoutPage: [
                {
                    orderTypeIds: [],
                    orderTypesDisclaimers: true,
                    allowGuestsOrders: true,
                    mediaSectionHeader: 'Paid with:',
                    isMemberInterfaceEnabled: false,
                    dynamicOrderTypeDetails: null,
                },
                {
                    orderTypeIds: [5],
                    orderTypesDisclaimers: true,
                    allowGuestsOrders: true,
                    mediaSectionHeader: null,
                    isMemberInterfaceEnabled: true,
                    dynamicOrderTypeDetails: {
                        associateWithPaymentStep: 1,
                        callToAction: {
                            icon: 'plus',
                            text: 'Add another guest authorised to pick up this order',
                        },
                        addDetailsButtonText: 'Add',
                        modal: {
                            preTitle: 'CHECKOUT',
                            title: ['Add a guest'],
                            description: ['Add the details of a nominated person to collect your order'],
                        },
                        dataCollected: ['{{firstDynamicDetailValue}} will collect the order'],
                    },
                },
            ],
            orderConfirmationPage: [
                {
                    orderTypeIds: [],
                    pageHeader: {
                        preTitle: 'Order Successful',
                        title: ['Woohoo! Your', 'Order #{{orderNumber}} has been', 'Sent to {{locationName}}'],
                        note: '{{locationAddress}}',
                    },
                    orderDetailsSection: {
                        orderDetailsHeader: {
                            preHeader: 'Your Order Details',
                            title: ['Pick up {{pickupDate}}', 'From {{locationName}}'],
                            description: 'Your order number is #{{orderNumber}}',
                        },
                    },
                    orderStatusSection: {
                        orderStatusHeader: {
                            success: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Pick up {{pickupDate}} from {{locationName}}',
                            },
                            failed: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Order cancelled on {{cancelledDate}}',
                            },
                        },
                        isLiveViewEnabled: true,
                        liveViewSection: [
                            {
                                title: 'Order placed',
                                description: 'Pick up order {{pickupDate}}',
                                statusFilter: [0, 1],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 0,
                            },
                            {
                                title: 'Confirming order',
                                description: '{{locationName}} has confirmed your order',
                                statusFilter: [3, 4],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 1,
                            },
                            {
                                title: 'Preparing order',
                                description: 'Your order is in the kitchen and will be with you shortly',
                                statusFilter: [2],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 2,
                            },
                            {
                                title: 'Ready for collection',
                                description: '{{orderTypeName}}: {{orderTypeDetails}}',
                                statusFilter: [5],
                                pickupDateFilter: false,
                                isSuccess: true,
                                isFinal: true,
                                displayIndex: 3,
                            },
                            {
                                title: 'Your order has been cancelled',
                                description: '{{orderTypeName}}: {{orderTypeDetails}}',
                                statusFilter: [6],
                                pickupDateFilter: false,
                                isSuccess: false,
                                isFinal: true,
                                displayIndex: 4,
                            },
                        ],
                    },
                },
                {
                    orderTypeIds: [3],
                    pageHeader: {
                        preTitle: 'Order Successful',
                        title: ['Woohoo! Your', 'Order #{{orderNumber}} has been', 'Sent to the kitchen'],
                        note: '{{locationName}} - Table #{{orderTypeDetailValue}}',
                    },
                    orderDetailsSection: {
                        orderDetailsHeader: {
                            preHeader: 'Your Order Details',
                            title: ['Dine in order table #{{orderTypeDetailValue}}', '{{locationName}}'],
                            description: 'Your order number is #{{orderNumber}}',
                        },
                    },
                    orderStatusSection: {
                        orderStatusHeader: {
                            success: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Sit tight! We will bring your order to the table when ready',
                            },
                            failed: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Dinein order cancelled on {{cancelledDate}}',
                            },
                        },
                        isLiveViewEnabled: true,
                        liveViewSection: [
                            {
                                title: 'Order placed',
                                description: 'Today for Dine In',
                                statusFilter: [0, 1],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 0,
                            },
                            {
                                title: 'Confirming order',
                                description: '{{locationName}} has confirmed your order',
                                statusFilter: [3, 4],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 1,
                            },
                            {
                                title: 'Preparing order',
                                description: 'Your order is in the kitchen and will be with you shortly',
                                statusFilter: [2],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 2,
                            },
                            {
                                title: 'Order is on the way',
                                description: 'We will be delivering your order to table #{{orderTypeDetailValue}}',
                                statusFilter: [5],
                                pickupDateFilter: false,
                                isFinal: true,
                                isSuccess: true,
                                displayIndex: 3,
                            },
                        ],
                    },
                },
                {
                    orderTypeIds: [4],
                    pageHeader: {
                        preTitle: 'Order Successful',
                        title: ['Woohoo! Your', 'Order #{{orderNumber}} has been', 'Sent to the kitchen'],
                        note: '{{locationAddress}}',
                    },
                    orderDetailsSection: {
                        orderDetailsHeader: {
                            preHeader: 'Your Order Details',
                            title: ['Dine in order', '{{locationName}}'],
                            description: 'Your order number is #{{orderNumber}}',
                        },
                    },
                    orderStatusSection: {
                        orderStatusHeader: {
                            success: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'We will send you a message when your order is ready.',
                            },
                            failed: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Delivery order cancelled on {{cancelledDate}}',
                            },
                        },
                        isLiveViewEnabled: true,
                        liveViewSection: [
                            {
                                title: 'Order placed',
                                description: 'Today for Dine In',
                                statusFilter: [0, 1],
                                isSuccess: false,
                                isFinal: false,
                                pickupDateFilter: false,
                                displayIndex: 0,
                            },
                            {
                                title: 'Confirming order',
                                description: '{{locationName}} has confirmed your order',
                                statusFilter: [3, 4],
                                isSuccess: false,
                                isFinal: false,
                                pickupDateFilter: false,
                                displayIndex: 1,
                            },
                            {
                                title: 'Preparing order',
                                description: 'Your order is in the kitchen and will be with you shortly',
                                statusFilter: [2],
                                isSuccess: false,
                                isFinal: false,
                                pickupDateFilter: false,
                                displayIndex: 2,
                            },
                            {
                                title: 'Order ready',
                                description: 'Your order is ready for collection',
                                statusFilter: [5],
                                isSuccess: true,
                                isFinal: true,
                                pickupDateFilter: false,
                                displayIndex: 3,
                            },
                        ],
                    },
                },
                {
                    orderTypeIds: [5],
                    pageHeader: {
                        preTitle: 'Your Order Details',
                        title: ['FrOM {{locationName}}'],
                        note: '{{collectionTypeName}} {{pickupDate}}',
                    },
                    orderDetailsSection: {
                        orderDetailsHeader: {
                            preHeader: 'Your Order Details',
                            title: ['{{collectionTypeName}} {{pickupDate}}', 'From {{locationName}}'],
                            description: 'Your order number is #{{orderNumber}}',
                        },
                    },
                    orderStatusSection: {
                        orderStatusHeader: {
                            success: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: '{{collectionTypeName}} collection {{pickupDate}}',
                            },
                            failed: {
                                preHeader: 'Your Order Status',
                                title: 'Thanks {{userName}}',
                                description: 'Holiday order cancelled on {{cancelledDate}}',
                            },
                        },
                        isLiveViewEnabled: true,
                        liveViewSection: [
                            {
                                title: 'Order placed',
                                description: 'For collection {{pickupDate}}',
                                statusFilter: [0, 1],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 0,
                            },
                            {
                                title: 'Confirming order',
                                description: '{{locationName}} has confirmed your order',
                                statusFilter: [3, 4],
                                pickupDateFilter: false,
                                isFinal: false,
                                isSuccess: false,
                                displayIndex: 1,
                            },
                            {
                                title: 'Ready for collection',
                                description: 'Your order is ready for collection by {{orderTypeDetails}}',
                                statusFilter: [5],
                                pickupDateFilter: true,
                                isFinal: true,
                                isSuccess: true,
                                displayIndex: 2,
                            },
                            {
                                title: 'Your order has been cancelled',
                                description: 'Order was cancelled on {{cancelledDate}}',
                                statusFilter: [6],
                                pickupDateFilter: false,
                                isFinal: true,
                                isSuccess: false,
                                displayIndex: 3,
                            },
                        ],
                    },
                },
            ],
            modals: {
                animateTimeout: 500,
                bgClickClose: true,
                preventGlobalScroll: true,
            },
            loyalty: {
                freeProductTile: {
                    feel: 'gold',
                    titleInverted: true,
                },
                heroText: ['TASK Rewards.', 'Let\'s get started, login or create an account.'],
                layout: 'row',
                cta: {
                    title: 'Join now & start earning rewards',
                    description: [
                        'So you want in hey? Well, it ain’t that easy. You can’t just magically download the app and',
                        'register your details and expect to be granted entry into our super exclusive Vibe Club.',
                        'Wait. Actually, that’s exactly all you need to do. So go and do it so you can enjoy free',
                        'Boost stuff and juicy deals!',
                    ],
                    sectionFeel: 'primary',
                    btnFeel: 'secondary',
                    btnInverted: true,
                    titleInverted: true,
                },
            },
            membership: {
                defaultMemberGroupId: 1,
                membersCanDeleteAccounts: true,
                enableQuickSignInForReturningMembers: true,
                minimumAgeToSignUp: 14,
                maximumAgeToSignUp: null,
                birthdayRewards: true,
                memberCard: {
                    minLength: 2,
                    maxLength: 10,
                },
                passwordRules: {
                    minLength: 6,
                    upperCaseCount: 1,
                    lowerCaseCount: 1,
                    digitsCount: 1,
                    specialCharsCount: 1,
                    allowWhiteSpaces: false,
                },
            },
            loyaltyPoints: {
                enabled: true,
                mode: 0,
                units: 'pts',
                roundUnits: 0,
                circleHeader: 'Points',
                header: 'YOUR POINTS BALANCE',
            },
            signUpRewardsAccountLinking: {
                enabled: false,
                introText: 'Do you have a TASK Rewards Account? Select the “Link rewards account” below to sign up using this account and start earning rewards on online.',
                headerText: 'TASK Rewards',
                descriptionText: 'Link your rewards account. Enter your account number & associated email address.',
                cardNumberLabelText: 'Account number ( 16 digits ):',
            },
            historyOrdersPage: {
                ordersLimit: 10,
            },
            memberVerification: {
                createProfileForm: {
                    header: 'Create your profile',
                    body: 'Create a profile to remember your details and favourite orders for fast re-ordering next visit.',
                },
                landing: {
                    header: 'Welcome',
                    body: 'To complete registration<br />please click on the button below',
                    button: 'verify account',
                    sub: 'If you did not create an account using this address,<br />please ignore this email.',
                },
                success: {
                    header: 'Success!',
                    body: 'Your email has been verified. You can now sign in',
                },
                failure: {
                    header: 'Sorry!',
                    body: 'The confirmation token has expired<br />or it was used already',
                },
                failureVerify: {
                    header: 'Something went wrong!',
                    body: 'Your email address has already been verified',
                },
            },
            forgotPassword: {
                landing: {
                    header: 'Reset Password',
                    body: 'Enter a new password below',
                    button: 'Reset password',
                },
                success: {
                    header: 'Success!',
                    body: 'Your password has been successfully updated',
                },
                failure: {
                    header: 'Sorry!',
                    body: 'The reset password token has expired<br />or it was used already',
                },
                failureChange: {
                    header: 'Sorry!',
                    body: 'There was an error while changing your password',
                },
            },
            predownloadImages: {
                forLocations: false,
                forVenue: false,
            },
            imagesScaleFactor: '110%'
        };
    }
}
