import { PickupModels } from '@shared/core/models/pickup.model';
import * as Statics from '@shared/core/statics';
import { PICKUPS, DATE_DISPLAY_FORMAT } from '@shared/core/consts';
import { Dates } from '../../dates.utils';
import { Pickups } from '../../pickups.utils';
import { LocationOrderingTimeInfo } from '../../location-ordering-time-info.utils';
import { CollectionTypeHelper } from '../../collection-type-helper.utils';
import { CollectionTypeGroupDetector } from '../../collection-type-group-detector';

type DateType = Nullable<string | number | Date>;

type Params = {
    location: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    orderTypeId?: Nullable<number>;
    date?: DateType;
};

type GetMinimumPickupTimeForLocationByDateModel = {
    locationNo: number;
    minimumPickupTime: Nullable<number>;
};

type GetMinimumEdgeMinimumPickupTimeForAllLocationsParam = {
    locations?: Nullable<Array<OLO.DTO.OnlineOrderingLocationBusinessModel>>;
    orderTypeId?: Nullable<number>;
    date?: DateType;
};

type GetPickupTimesForDateParams = {
    locations?: Nullable<Array<OLO.DTO.OnlineOrderingLocationBusinessModel>>;
    orderTypeId?: Nullable<number>;
    date?: DateType;
};

type ComplereOrderInfoModel = {
    locationNo: Nullable<number>;
    openingTime: Nullable<string>;
    closingTime: Nullable<string>;
    isOpen: Nullable<boolean>;
    minimumPickupTime: Nullable<number>;
};

type EdgeOpeningHoursModel = {
    openingTime: Nullable<string>;
    closingTime: Nullable<string>;
};

type GeneratePickupTimesForAllLocationsParams = {
    locations?: Nullable<Array<OLO.DTO.OnlineOrderingLocationBusinessModel>>;
    orderTypeId?: Nullable<number>;
    date?: DateType;
    isSchedule?: boolean;
    limit?: Nullable<number>;
};

type GeneratePickupTimesForLocationsByPeriodParams = {
    locations?: Nullable<Array<OLO.DTO.OnlineOrderingLocationBusinessModel>>;
    orderTypeId?: Nullable<number>;
    period: OLO.Ordering.Period;
    pickupParams?: Nullable<OLO.Ordering.GeneratePickupsParams>;
};

type GeneratePickupTimesForLocationByPeriodParams = {
    location?: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    orderTypeId?: Nullable<number>;
    period: OLO.Ordering.Period;
    pickupParams?: Nullable<OLO.Ordering.GeneratePickupsParams>;
};

type GetAvailablePickupsForLocationParams = {
    location: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    orderTypeId?: Nullable<number>;
    date?: DateType;
};

type GetLocationPeriods = {
    location: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    /** Only provide for overwritinig selected one. Otherwise leave null */
    orderTypeId: Nullable<number>;
    format?: string;
    prefixes?: boolean;
    date?: Nullable<DateType>;
};

type GetLocationFuturePickupListParams = {
    location: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    orderTypeId: Nullable<number>;
    format?: string;
    prefixes?: boolean;
    date?: Nullable<DateType>;
};

type GetAvailablePickupTimesWithFutureForLocationParams = {
    location: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>;
    orderTypeId: Nullable<number>;
    format?: string;
    prefixes?: boolean;
    futureOrders?: boolean;
    date?: Nullable<DateType>;
};

type GeneratePeriodsForLocationsParams = {
    locations?: Nullable<Array<OLO.DTO.OnlineOrderingLocationBusinessModel>>;
    orderTypeId?: Nullable<number>;
    overwriteOrderTypeId?: Nullable<number>;
    format?: string;
    prefixes?: boolean;
};

export class LocationPickups {
    public static readonly DEFAULT_PICKUP_PARAMS: OLO.Ordering.GeneratePickupsParams = {
        endBufferMins: PICKUPS.END_BUFFER_MINS,
        startBufferMins: PICKUPS.START_BUFFER_MINS,
        orderTimeoutBufferMins: PICKUPS.ORDER_TIMEOUT_BUFFER_MINS,
        nextTick: PICKUPS.NEXT_TICK,
        orderTypeId: null,
        displayAsTimespans: false,
    };
    /**
     * Helper method - based on locations list, will select lowest minimum pickup time for list of locations
     */
    public static getEdgeMinimumPickupTimesForLocationsByDate(props: GetMinimumEdgeMinimumPickupTimeForAllLocationsParam): number {
        const { locations, orderTypeId, date } = props;

        const perLocationResult = locations?.map((location) => LocationPickups.getMinimumPickupTimeForLocationByDate({ location, orderTypeId, date }));
        const edgeMinimumPickupTimeForLocations = perLocationResult?.reduce((acc, curr) => {
            if (curr.minimumPickupTime === null) return acc;

            if (acc === null) {
                return curr.minimumPickupTime;
            }

            if (acc > curr.minimumPickupTime) {
                return curr.minimumPickupTime;
            }

            return acc;
        }, null as number);

        return edgeMinimumPickupTimeForLocations >= 0 ? edgeMinimumPickupTimeForLocations : null;
    }

    /**
     * Returns location minimum pickup time based on date and order type id. This is pickup specific, so additional pickup params are checked
     * @param {Params} params
     * @returns {GetMinimumPickupTimeForLocationByDateModel} pickup specific minimum pickup time
     */
    public static getMinimumPickupTimeForLocationByDate(params: Params): GetMinimumPickupTimeForLocationByDateModel {
        const { location, orderTypeId, date } = params;

        const baseObj: GetMinimumPickupTimeForLocationByDateModel = {
            locationNo: location?.LocationNo ?? null,
            minimumPickupTime: null,
        };

        const noDataAvailable = !location?.OrderingTimeInfo || location?.OrderingTimeInfo?.length === 0;

        if (noDataAvailable) {
            return baseObj;
        }
        const d = Dates.createDate(date);
        const currDay = d.getDay();
        /** Might need to switch to filter by Date first chunk instead of dayOfWeek */
        const foundDay = Pickups.getFilteredOrderingTimeInfo(location, orderTypeId).find((orderingTime) => orderingTime.DayOfWeek === currDay);

        /* When there is no data about selected day  - assume location is closed*/
        if (!foundDay) {
            return baseObj;
        }

        const isLocationOpenNowOrLaterToday: boolean = Dates.isHourInHoursRange(Dates.getLocalISOFormatDate(d), d, foundDay.ClosingTime, 'from');

        if (!isLocationOpenNowOrLaterToday) {
            return baseObj;
        }
        const foundCurrentPickupTime = foundDay.PickupTimes.find((pickupTime) => Dates.isHourInHoursRange(Dates.getLocalISOFormatDate(d), pickupTime.From, pickupTime.To, 'both'));
        if (!foundCurrentPickupTime) {
            /* By default we assume there is 0 preperation time */
            return {
                ...baseObj,
                minimumPickupTime: 0,
            };
        }

        return {
            ...baseObj,
            minimumPickupTime: foundCurrentPickupTime.MinimumPickupTime,
        };
    }

    /**
     * Returns ordering info for location based on date and order type id, like minimum pickup time, open and closing time, open status
     * @param {Params} params
     * @returns {ComplereOrderInfoModel}
     */
    public static getCompleteOrderInfoByDate(params: Params): ComplereOrderInfoModel {
        const { location, orderTypeId, date } = params;

        const baseObj: ComplereOrderInfoModel = {
            locationNo: location?.LocationNo ?? null,
            openingTime: null,
            closingTime: null,
            isOpen: null,
            minimumPickupTime: null,
        };

        if (!location) {
            return baseObj;
        }

        const orderingTimeInfo = new LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        const noDataAvailable: boolean = !orderingTimeInfo || orderingTimeInfo.length === 0;
        if (noDataAvailable) {
            return baseObj;
        }

        let d: Date = Dates.createDate(date);
        const isoChunk = Dates.getChunk(Dates.getLocalISOFormatDate(d));
        const foundDay = orderingTimeInfo.find((orderingTime) => Dates.getChunk(orderingTime.Date) === isoChunk);
        if (!foundDay) {
            /* When there is no data about selected day  - assume location is closed*/
            return baseObj;
        }

        const minimumPickupTimeForDay = foundDay.PickupTimes?.find((pickupTime) =>
            Dates.isHourInHoursRange(Dates.getLocalISOFormatDate(d), pickupTime.From, pickupTime.To, 'both'),
        );

        return {
            ...baseObj,
            openingTime: foundDay.OpeningTime,
            closingTime: foundDay.ClosingTime,
            isOpen: Dates.isHourInHoursRange(d, foundDay.OpeningTime, foundDay.ClosingTime, 'from'),
            minimumPickupTime: minimumPickupTimeForDay ? minimumPickupTimeForDay.MinimumPickupTime : 0,
        };
    }

    /**
     * Gets edge opening and closing time for all locations based on date and order type id
     * @param {GetMinimumEdgeMinimumPickupTimeForAllLocationsParam} props
     * @returns {EdgeOpeningHoursModel} edge opening hours
     */
    public static getEdgeOpeningHoursForLocations(props: GetMinimumEdgeMinimumPickupTimeForAllLocationsParam): EdgeOpeningHoursModel {
        const { locations, orderTypeId, date } = props;

        const agregatedLocationsOrderInfo = locations?.map((location) => LocationPickups.getCompleteOrderInfoByDate({ location, orderTypeId, date }));
        const baseObj: EdgeOpeningHoursModel = {
            openingTime: null,
            closingTime: null,
        };

        agregatedLocationsOrderInfo?.forEach((obj) => {
            if (!obj.openingTime || !obj.closingTime) return;
            const tempOpen = baseObj.openingTime === null ? null : Dates.createHoursIntFromDate(baseObj.openingTime);
            const tempClose = baseObj.openingTime === null ? null : Dates.createHoursIntFromDate(baseObj.closingTime);
            const openingTime = obj.openingTime ? Dates.createHoursIntFromDate(obj.openingTime) : null;
            const closingTime = obj.closingTime ? Dates.createHoursIntFromDate(obj.closingTime) : null;

            if (tempOpen === null || tempOpen > openingTime) {
                baseObj.openingTime = obj.openingTime;
            }

            if (tempClose === null || tempClose < closingTime) {
                baseObj.closingTime = obj.closingTime;
            }
        });

        return baseObj;
    }

    /**
     * Gets edge opening location times based on date and order type id. This is pickup specific, so additional pickup params will be taken into account when returning result
     * @param {GetPickupTimesForDateParams} params
     * @returns {Nullable<EdgeOpeningHoursModel>} edge opening hours
     */
    public static getEdgePickupOpeningHoursForLocations(params: GetPickupTimesForDateParams): Nullable<EdgeOpeningHoursModel> {
        const { locations, date, orderTypeId } = params;
        if (!locations) return null;
        const d = Dates.createDate(date);
        const dateIsoChunk = Dates.getChunk(Dates.getLocalISOFormatDate(d));

        const baseObj: EdgeOpeningHoursModel = {
            openingTime: null,
            closingTime: null,
        };

        locations.forEach((location) => {
            const openingHours = Pickups.getFilteredOrderingTimeInfo(location, orderTypeId).find((orderingObj) => Dates.getChunk(orderingObj.Date) === dateIsoChunk);
            if (openingHours) {
                if (!openingHours.OpeningTime || !openingHours.ClosingTime) return;
                const tempOpen = baseObj.openingTime === null ? null : Dates.createHoursIntFromDate(baseObj.openingTime);
                const tempClose = baseObj.openingTime === null ? null : Dates.createHoursIntFromDate(baseObj.closingTime);
                const openingTime = openingHours.OpeningTime ? Dates.createHoursIntFromDate(openingHours.OpeningTime) : null;
                const closingTime = openingHours.ClosingTime ? Dates.createHoursIntFromDate(openingHours.ClosingTime) : null;

                if (tempOpen === null || tempOpen > openingTime) {
                    baseObj.openingTime = openingHours.OpeningTime;
                }

                if (tempClose === null || tempClose < closingTime) {
                    baseObj.closingTime = openingHours.ClosingTime;
                }
            }
        });

        return baseObj;
    }

    public static generatePickupTimesForAllLocations(params: GeneratePickupTimesForAllLocationsParams) {
        const { locations, date, isSchedule, limit, orderTypeId } = params;
        const config = new Statics.ConfigStatic().current;

        const DayOfWeek: number = new Date().getDay();
        const collectionTypeConfig = new CollectionTypeHelper(config.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeId);

        const pickupParams: OLO.Ordering.GeneratePickupsParams = {
            ...LocationPickups.DEFAULT_PICKUP_PARAMS,
            orderTypeId,
            displayAsTimespans: collectionTypeConfig && 'displayAsTimespans' in collectionTypeConfig ? collectionTypeConfig.displayAsTimespans : false,
        };

        const minimumPickupTime = LocationPickups.getEdgeMinimumPickupTimesForLocationsByDate({ locations, orderTypeId, date });
        const openingTime = LocationPickups.getEdgeOpeningHoursForLocations({ locations, orderTypeId, date });
        const edgeCases = {
            minimumPickupTime,
            ...openingTime,
        };
        if (edgeCases.minimumPickupTime === null && isSchedule === false) {
            return null;
        }

        const asapModel: OLO.Ordering.PickupTime = new PickupModels().generateDefaultAsap();
        const scheduleModel: OLO.Ordering.PickupTime = new PickupModels().generateDefaultSchedule();

        if (collectionTypeConfig && 'endBufferMins' in collectionTypeConfig) {
            pickupParams.endBufferMins = collectionTypeConfig.endBufferMins;
            pickupParams.startBufferMins = collectionTypeConfig.startBufferMins;
            pickupParams.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
            pickupParams.nextTick = collectionTypeConfig.nextTick;
        }
        const list = Pickups.generatePickupTimesList({
            orderTypeId,
            location: null,
            asapPickupMins: edgeCases.minimumPickupTime,
            openingHours: [
                {
                    DayOfWeek: DayOfWeek as APICommon.DayOfWeek,
                    OpeningTime: edgeCases.openingTime,
                    ClosingTime: edgeCases.closingTime,
                },
            ],
            limit,
            ...pickupParams,
        }).filter((pickup) => pickup.IsAsap === false);

        list.unshift(asapModel);

        if (limit && list.length > limit) {
            list.length = limit;
        }

        if (isSchedule) {
            list.push(scheduleModel);
        }

        return list;
    }

    /**
     * Generates pickups list for many locations based on period, order type id and specific pickup parameters
     * @param {GeneratePickupTimesForLocationsByPeriodParams} params
     * @returns {OLO.Ordering.PickupTime[]} pickup times list
     */
    public static generateFuturePickupTimesForLocationsByPeriod(params: GeneratePickupTimesForLocationsByPeriodParams): OLO.Ordering.PickupTime[] {
        const { locations, period, pickupParams, orderTypeId } = params;

        const edgeCases = LocationPickups.getEdgePickupOpeningHoursForLocations({ locations, orderTypeId, date: period.Date });

        const DayOfWeek: number = period.DayOfWeek;

        const opts: OLO.Ordering.GeneratePickupsParams = {
            ...LocationPickups.DEFAULT_PICKUP_PARAMS,
            ...pickupParams,
        };

        const result = Pickups.generatePickupTimesFutureList(period, {
            orderTypeId,
            location: null,
            asapPickupMins: opts.nextTick,
            openingHours: [
                {
                    Date: period.Date,
                    DayOfWeek: DayOfWeek as APICommon.DayOfWeek,
                    OpeningTime: edgeCases.openingTime,
                    ClosingTime: edgeCases.closingTime,
                },
            ],
            orderTimeoutBufferMins: opts.orderTimeoutBufferMins,
            startBufferMins: opts.startBufferMins,
            endBufferMins: opts.endBufferMins,
            nextTick: opts.nextTick,
        });

        return result;
    }

    /**
     * Generates pickups list for single location based on period, order type id and specific pickup parameters
     * @param {GeneratePickupTimesForLocationByPeriodParams} params
     * @returns {OLO.Ordering.PickupTime[]} pickup times list
     */
    public static generateFuturePickupTimesForLocationByPeriod(params: GeneratePickupTimesForLocationByPeriodParams): OLO.Ordering.PickupTime[] {
        const { location, period, orderTypeId, pickupParams } = params;

        if (!period) return null;

        const opts: OLO.Ordering.GeneratePickupsParams = {
            orderTypeId,
            ...pickupParams,
        };

        const { orderTimeoutBufferMins, startBufferMins, endBufferMins, nextTick, displayAsTimespans } = opts;

        const openingHours = new LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        const result = Pickups.generatePickupTimesFutureList(period, {
            orderTypeId,
            orderTimeoutBufferMins,
            startBufferMins,
            endBufferMins,
            nextTick,
            displayAsTimespans,
            location,
            openingHours,
        });

        return result;
    }

    public static getAvailablePickupsForLocation(params: GetAvailablePickupsForLocationParams) {
        const { location, orderTypeId, date } = params;
        if (!location) {
            return null;
        }

        const locationNo = location.LocationNo;

        const config = new Statics.ConfigStatic().current;
        const collectionTypeId = new CollectionTypeGroupDetector(orderTypeId, config).getCollectionType();
        const collectionTypeConfig = new CollectionTypeHelper(config.collectionTypes).getCollectionTypeConfig(collectionTypeId);
        if (!collectionTypeConfig) {
            const ex = `Pickup config not configured for location ${locationNo} (${location.LocationFriendlyName})`;

            throw new Error(ex);
        }

        const openingHours = location ? Pickups.getFilteredOrderingTimeInfo(location, orderTypeId) : null;
        const orderInfo = LocationPickups.getCompleteOrderInfoByDate({ location, date, orderTypeId });
        const calcParams: OLO.Ordering.GeneratePickupsParams = {
            orderTypeId,
            location,
            asapPickupMins: orderInfo.minimumPickupTime,
            openingHours,
            schedule: config.onlineOrders.scheduledOrders === true,
        };

        if ('orderTimeoutBufferMins' in collectionTypeConfig) {
            calcParams.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
            calcParams.startBufferMins = collectionTypeConfig.startBufferMins;
            calcParams.nextTick = collectionTypeConfig.nextTick;
            calcParams.endBufferMins = collectionTypeConfig.endBufferMins;
        }

        if ('displayAsTimespans' in collectionTypeConfig) {
            calcParams.displayAsTimespans = collectionTypeConfig.displayAsTimespans;
        }

        const result = Pickups.generatePickupTimesList(calcParams);

        return result;
    }

    public static getLocationPeriods(params: GetLocationPeriods): Nullable<OLO.Ordering.Period[]> {
        const opts: typeof params = {
            orderTypeId: null,
            location: null,
            format: DATE_DISPLAY_FORMAT,
            prefixes: true,
            date: null,
            ...params,
        };

        const { location, orderTypeId, format, prefixes, date } = opts;
        if (!location) {
            return null;
        }

        const availablePickups = LocationPickups.getAvailablePickupsForLocation({ date, location, orderTypeId });

        const nextOrderTypeId = orderTypeId;

        const result = Pickups.getFilteredOrderingTimeInfo(location, nextOrderTypeId).reduce((acc, obj) => {
            const period = Pickups.createPeriodObject(obj, format, prefixes);
            const isToday = Dates.isToday(obj.Date);
            if (isToday) {
                const todaysList = availablePickups?.filter((a) => a.IsToday === true);
                if (!todaysList || !todaysList?.length) return acc;
            }

            acc.push(period);

            return acc;
        }, [] as OLO.Ordering.Period[]);

        return result;
    }

    public static getLocationFuturePickupList(params: GetLocationFuturePickupListParams) {
        const opts: typeof params = {
            location: null,
            orderTypeId: null,
            format: DATE_DISPLAY_FORMAT,
            prefixes: true,
            date: null,
            ...params,
        };

        const { location, orderTypeId, format, prefixes, date } = opts;
        if (!location) {
            return null;
        }

        const periods = LocationPickups.getLocationPeriods({ location, orderTypeId, format, prefixes, date });
        if (!periods) {
            return null;
        }

        const config = new Statics.ConfigStatic().current;

        const collectionTypeConfig = new CollectionTypeHelper(config.collectionTypes).getDefaultCollectionType(orderTypeId);
        if (!collectionTypeConfig) return null;
        const orderingTimeInfo = new LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        const nextParams: OLO.Ordering.GeneratePickupsParams = {
            orderTypeId,
            location,
            openingHours: orderingTimeInfo,
        };

        if ('orderTimeoutBufferMins' in collectionTypeConfig) {
            nextParams.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
            nextParams.startBufferMins = collectionTypeConfig.startBufferMins;
            nextParams.endBufferMins = collectionTypeConfig.endBufferMins;
            nextParams.nextTick = collectionTypeConfig.nextTick;
        }
        if ('displayAsTimespans' in collectionTypeConfig) {
            nextParams.displayAsTimespans = collectionTypeConfig.displayAsTimespans;
        }

        const result = periods.reduce((acc, period) => [...acc, ...Pickups.generatePickupTimesFutureList(period, nextParams)], [] as OLO.Ordering.PickupTime[]);

        return result;
    }

    public static getAvailablePickupTimesWithFutureForLocation(params: GetAvailablePickupTimesWithFutureForLocationParams) {
        const opts: typeof params = {
            futureOrders: false,
            orderTypeId: null,
            location: null,
            ...params,
        };
        const { location, futureOrders } = opts;
        // TODO - make single calculations
        const availablePickups = LocationPickups.getAvailablePickupsForLocation(opts);
        const futurePickupList = LocationPickups.getLocationFuturePickupList(opts);

        let arr: OLO.Ordering.PickupTime[] = [];
        if (!location || !availablePickups) return arr;

        arr = [...availablePickups];
        if (futureOrders && futurePickupList) {
            futurePickupList.forEach((pickup) => {
                if (arr.some((obj) => obj.Id === pickup.Id)) return;

                arr.push(pickup);
            });
        }

        return arr;
    }

    public static generatePeriodsForLocations(params: GeneratePeriodsForLocationsParams) {
        const opts: typeof params = {
            locations: null,
            orderTypeId: null,
            format: 'dd, D MMM',
            prefixes: true,
            ...params,
        };

        const { format, prefixes, orderTypeId, locations } = opts;

        if (!locations) {
            return null;
        }

        const nextOrderTypeId = orderTypeId;

        const result = locations
            ?.reduce((acc, location) => {
                const locationIsConfiguredForFutureOrders = location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null;
                let min: number = location.FutureOrderingMinDaysAhead;
                let max: number = location.FutureOrderingMaxDaysAhead;

                Pickups.getFilteredOrderingTimeInfo(location, nextOrderTypeId).forEach((timeInfo) => {
                    const foundInAcc = acc.some((existingObj) => Dates.getChunk(existingObj.Date) === Dates.getChunk(timeInfo.Date));
                    if (!foundInAcc) {
                        const daysDiff = Dates.datesDiffInDays(new Date(), Dates.createDate(timeInfo.Date));

                        const isTodayWithoutFutureOrderingConfigured = !locationIsConfiguredForFutureOrders && daysDiff === 0;
                        const isAnyDateWithFutureOrderingConfigured = locationIsConfiguredForFutureOrders && daysDiff >= min && daysDiff <= max;

                        if (isTodayWithoutFutureOrderingConfigured || isAnyDateWithFutureOrderingConfigured) {
                            acc.push(Pickups.createPeriodObject(timeInfo, format, prefixes));
                        }
                    }
                });

                return acc;
            }, [] as OLO.Ordering.Period[])
            .sort((a, b) => a.Id - b.Id);

        return result;
    }
}
