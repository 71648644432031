import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../../../actions';
import * as selectors from '../../../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, filter, catchError, map } from 'rxjs/operators';

@Injectable()
export class CardConnectLocationConfigEffects {
    public onSetActiveCardRequestCardConnectLocationConfig$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.SelectActiveCreditCardId, actions.RouterNavigation),
            filter(() => this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT),
            withLatestFrom(
                this._store.pipe(select(selectors.getActiveCardDetails)),
                this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
                this._store.pipe(select(selectors.getCardConnectLocationConfig)),
                this._store.pipe(select(selectors.getCurrentLocationNo)),
            ),
            filter(([, , isCheckoutPage]) => isCheckoutPage),
            switchMap(([, , , cardConnectLocationSettings, locationNo]) => {
                if (cardConnectLocationSettings.locationNo === locationNo) {
                    return [];
                }

                return [actions.CardConnectLocationConfigRequest({ locationNo })];
            }),
        ),
    );

    public requestCardConnectLocationConfig$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CardConnectLocationConfigRequest),
            switchMap(({ locationNo }) =>
                this._cardConnectPaymentProviderService.getCardConnectSettings$(locationNo).pipe(
                    map((payload) => actions.CardConnectLocationConfigSuccessRequest({ locationNo, payload })),
                    catchError((ex) => {
                        console.error('CardConnectLocationSettingsRequest error', ex);

                        return [actions.CardConnectLocationConfigErrorRequest({ locationNo, ex })];
                    }),
                ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _store: Store<OLO.State>,
        private _actions$: Actions,
        private _cardConnectPaymentProviderService: Services.CardConnectPaymentProviderService,
    ) {}
}
