import { createSelector } from '@ngrx/store';
import { getLocations } from '@shared/state/locations/selectors';
import { getCurrentLocationDetails } from '../getCurrentLocationDetails/get-current-location-details.selector';

export const getVirtualLocationsForCurrentLocation = createSelector(
    getCurrentLocationDetails, getLocations,
    (currentLocationDetails, locations) => locations
        .find(location => location.LocationNo === currentLocationDetails?.LocationNo)?.VirtualLocations ?? null

);
