import * as io from 'io-ts';
import { arrayOfStrings, boolean, validString } from '@shared/core/types/common';
import { orderTypeIdsArray } from '@shared/core/types/consts';

const locationDetailsFirstLoadInroductionModalPageDefinition: io.MixedObject<OLO.Config.LocationDetailsPage.FirstLoadIntroductionModal> = {
    modalPreTitle: validString,
    modalTitle: validString,
    modalDescription: arrayOfStrings,
    showCollectionTypeDropdown: boolean,
};

const rootDefinition: io.MixedObject<OLO.Config.LocationDetailsPage> = {
    orderTypeIds: orderTypeIdsArray,
    showCategoriesOnInitInMobileView: boolean,
    firstLoadIntroductionModal: io.union([io.null, io.type(locationDetailsFirstLoadInroductionModalPageDefinition), io.literal(-1)]),
    editableAmountProduct: boolean
};

export const locationDetailsPageSchema = io.array(io.type(rootDefinition));
