import { createAction } from '@ngrx/store';

export const OnlineMenuPagesVirtualLocationRequest = createAction(
    '[Online menu - virtual location] Request menu pages',
    (displayIndex: number, name: string, accentColor: string, locationNo: number, virtualLocationNo: number, orderTypeId: number,
        pickupDate: string = new Date().toISOString()) =>
        ({ displayIndex, name, accentColor, locationNo, virtualLocationNo, orderTypeId, pickupDate }),
);

export const OnlineMenuPagesVirtualLocationSuccessRequest = createAction(
    '[Online menu - virtual location] Success request menu pages',
    (locationNo: number, virtualLocationNo: number, orderTypeId: number, pickupDate: string, payload: OLO.DTO.OnlineMenuResponseModel) =>
        ({ locationNo, virtualLocationNo, orderTypeId, pickupDate, payload })
);

export const OnlineMenuPagesVirtualLocationErrorRequest = createAction(
    '[Online menu - virtual location] Error request menu pages',
    (locationNo: number, virtualLocationNo: number, orderTypeId: number, pickupDate: string, ex: any = null) =>
        ({ locationNo, virtualLocationNo, orderTypeId, pickupDate, ex }),
);

export const OnlineMenuPagesVirtualLocationReset = createAction(
    '[Online menu - virtual location] Reset state'
);
