export class Arrays {
    /**
     * Returns array with unique entries based on props.
     * @warning this does not perform deep equal comparison. Best to use on primitve values
     * @param {Array<any>} array to deduplicate
     * @param {Array<string>} props
     * @returns {Array<any>} new array with unique objects
     */
    public static dedupeByProps<T extends Array<any>>(arr: T, ...byProps: (keyof Unpacked<T>)[]): T {
        return arr.reduce((acc, nextItem) => {
            const isDuplicated = acc.find((obj) => {
                const objVals = byProps.map((prop) => obj[prop]).filter((val) => val !== undefined);
                const nextVals = byProps.map((prop) => nextItem[prop]).filter((val) => val !== undefined);

                const merged = Array.from(new Set([...objVals, ...nextVals]));

                if (merged.length !== objVals.length) {
                    return false;
                }

                return true;
            });

            if (!isDuplicated) {
                acc.push({
                    ...nextItem,
                });
            }

            return acc;
        }, [] as T);
    }

    public static arraysMatch<T>(...arrays: T[][]): boolean {
        try {
            let isValid = true;
            let matchByCount: number = arrays[0].length;

            arrays.reduce((acc, nextArr) => {
                if (!isValid) return acc;

                if (nextArr instanceof Array === false) {
                    throw new Error(`Invalid arugment. Should be array, provided ${nextArr}`);
                }

                if (nextArr.length !== matchByCount) {
                    isValid = false;

                    return acc;
                }

                const nextSorted = [...nextArr].sort();

                acc.forEach((prevArr, prevIndex) => {
                    if (isValid && prevArr[prevIndex] !== nextSorted[prevIndex]) {
                        isValid = false;
                    }
                });

                return [...acc, nextSorted];
            }, [] as T[][]);

            return isValid;
        } catch (error) {
            console.error(error);

            return false;
        }
    }

    /** Helps to determine if provided object is an array with typeguard */
    public static isTypedArray<T>(arr: any[] | any): arr is T[] {
        return arr instanceof Array && Array.isArray(arr);
    }

    public static findCommonItems<T>(arr1: T[], arr2: T[]): T[] | null {
        try {
            if (!(arr1 instanceof Array) || !(arr2 instanceof Array)) {
                throw new Error('Unable to find common items in arrays. Invalid arguments. Need to provide arrays');
            }
            const a1 = [...arr1].sort((a: any, b: any) => a - b);
            const a2 = [...arr2].sort((a: any, b: any) => a - b);

            const initial = a1.length > a2.length ? a2 : a1;
            const second = initial === a1 ? a2 : a1;

            const common = initial.reduce((acc, item) => {
                if (second.includes(item)) {
                    return [...new Set([...acc, item])];
                }

                return acc;
            }, [] as T[]);

            return common.length ? common : null;
        } catch (error) {
            console.error(error, 'Provided:', arr1, arr2);

            return null;
        }
    }

    public static sortByDisplayIndex(items: any[], order: string = 'asc'): any[] {
        if (!items || (items instanceof Array && items.length === 0)) {
            return items;
        }

        let newArr: any[];

        if (order === 'desc') {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }

                return item2['DisplayIndex'] - item1['DisplayIndex'];
            });
        } else {
            newArr = items.sort((item1: any, item2: any) => {
                if (!item1.hasOwnProperty('DisplayIndex') || !item2.hasOwnProperty('DisplayIndex')) {
                    return 0;
                }

                return item1['DisplayIndex'] - item2['DisplayIndex'];
            });
        }

        return newArr;
    }

    public static sortByProp(propName: string, direction: string = 'desc'): any {
        return (a, b) => {
            switch (true) {
                case direction === 'desc' ? a[propName] < b[propName] : a[propName] > b[propName]:
                    return 1;
                case direction === 'desc' ? a[propName] > b[propName] : a[propName] < b[propName]:
                    return -1;
                default:
                    return 0;
            }
        };
    }

    public static remove(arr: any[], element: any): any[] {
        return arr.filter((item) => item !== element);
    }

    /**
     * Returns concatenated arrays with removing values which are not arrays
     * @param {Array<any>} array to concatenate
     * @returns {T} new array with unique objects
     */
    public static safeConcat<T extends Array<any>>(...arrs: T[]): T {
        return <T>[].concat(...arrs.filter(Array.isArray));
    }
}
