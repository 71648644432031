import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import { never, Observable, of } from 'rxjs';
import { combineLatest, delay, filter, map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class WizzardEffects {
    private readonly _queryModalIds: number[] = [];

    @Effect() public cancelWizzard$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardCancel),
        switchMap((action) => {
            this._modalsService.close(action.modalId);

            return of(actions.WizzardUnmountAll());
        }),
    );

    @Effect() public initWizzardFlow$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardSetupItem),
        switchMap((action) => {
            /* MenuFlow */
            if (action.item.MenuFlowId) return of(actions.WizzardStepMenuFlowInit(action.locationNo, action.item, action.modalId, action?.virtualLocationNo));

            /* Simple item */
            if (!action.item.hasOwnProperty('_Id')) {
                /* New */
                return of(actions.WizzardStepSimpleItemMount(
                    Utils.Items.convertToSanitizedSimpleItem({ ...action.item }, action.locationNo, action?.virtualLocationNo), action.modalId
                ));
            }

            /* Edit */
            return of(actions.WizzardStepSimpleItemMount(
                Utils.Items.convertToSanitizedSimpleItem(action.item as OLO.State.Cart.CartSimpleItem, null, action?.virtualLocationNo), action.modalId
            ));
        }),
    );

    @Effect({ dispatch: false }) public showModalForSimpleItem$: Observable<never> = this._actions$.pipe(
        ofType(actions.WizzardStepSimpleItemMount),
        withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
        switchMap(([{ item, modalId }, modals]) => {
            if (modalId && modals.find((obj) => obj.id === modalId)) {
                this._modalsService.swap(modalId, {
                    type: 'product',
                    locationNo: item.LocationNo,
                    menuFlowId: item.MenuFlowId,
                    isLoading: false,
                });
            } else {
                this._modalsService.show({
                    id: modalId || null,
                    type: 'product',
                    locationNo: item.LocationNo,
                    productId: item.ProductId,
                });
            }

            return never();
        }),
    );

    @Effect({ dispatch: false }) public showModalForAddVoucher$: Observable<never> = this._actions$.pipe(
        ofType(actions.WizzardAddVoucher),
        withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
        switchMap(([{ locationNo, modalId }, modals]) => {
            if (modalId && modals.find((obj) => obj.id === modalId)) {
                this._modalsService.swap(modalId, {
                    type: 'add-voucher',
                    locationNo: locationNo,
                    isLoading: false,
                });
            } else {
                this._modalsService.show({
                    id: modalId || null,
                    type: 'add-voucher',
                    locationNo: locationNo,
                });
            }

            return never();
        }),
    );

    @Effect() public stepInitMenuFlowWizzard$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardStepMenuFlowInit),
        switchMap((action) => {
            const modalMenuFlowPreSetup = () => {
                const modalId: number = action.modalId ? action.modalId : new Date().getTime() + Math.floor(Math.random() * 1000);

                this._modalsService.show({
                    id: modalId,
                    type: 'loading',
                    menuFlowId: action.item.MenuFlowId || null,
                    locationNo: action.locationNo,
                    virtualLocationNo: action?.virtualLocationNo
                });

                return of(actions.WizzardStepMenuFlowRequestData({ locationNo: action.locationNo, item: action.item, modalId, virtualLocationNo: action?.virtualLocationNo }));
            };

            /* Check if item is edited - no need to check online menu - context is in cart's model */
            if (action.item.hasOwnProperty('_Id') && action.item['_Id']) {
                return modalMenuFlowPreSetup();
            }

            /* Wait for online menu to be downloaded - current location context */
            return this._checkHasOnlineMenuOrIsVirtualLocation$(action?.virtualLocationNo).pipe(
                take(1),
                switchMap(() => modalMenuFlowPreSetup()),
            );
        }),
    );

    @Effect() public stepSilentInitMenuFlowWizzard$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardSilentSetupItem),
        switchMap((action) =>
            this._checkHasOnlineMenuOrIsVirtualLocation$(action?.virtualLocationNo).pipe(
                take(1),
                switchMap(() =>
                    of(actions.WizzardStepMenuFlowRequestData({
                        locationNo: action.locationNo,
                        item: action.item,
                        modalId: OLO.Components.Modals.MODAL_ID_TYPE.FORCE_NEW_MODAL,
                        virtualLocationNo: action?.virtualLocationNo
                    })),
                ),
            ),
        ),
    );

    @Effect() public stepRequestData$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardStepMenuFlowRequestData),
        tap((action) => {
            this._store.dispatch(actions.MenuFlowsDetailsRequest({ menuFlowId: action.item.MenuFlowId, locationNo: action.locationNo,
                virtualLocationNo: action.virtualLocationNo }));
        }),
        delay(100),
        switchMap((action) =>
            this._store.pipe(
                select(selectors.getMenuFlow(action.item.MenuFlowId, action.locationNo)),
                filter((menuFlow) => menuFlow.data !== null && menuFlow.isDownloading === false && menuFlow.hasFailed === false),
                take(1),
                switchMap(() => {
                    /* Edit */
                    if ((action.item as OLO.State.Cart.CartMenuFlow)._Id) {
                        return of(
                            actions.WizzardStepMenuFlowMount({
                                locationNo: action.locationNo,
                                menuFlowId: action.item.MenuFlowId,
                                item: action.item as OLO.State.Cart.CartMenuFlow,
                                modalId: action.modalId,
                            }),
                        );
                    }

                    /* New */
                    return of(
                        actions.WizzardStepMenuFlowActivateProducts({
                            locationNo: action.locationNo,
                            item: action.item,
                            modalId: action.modalId,
                            virtualLocationNo: action.virtualLocationNo
                        }),
                    );
                }),
            ),
        ),
    );

    @Effect() public stepActivateMenuFlowProducts$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardStepMenuFlowActivateProducts),
        switchMap((action) =>
            this._store.pipe(
                delay(100),
                select(selectors.getMenuFlow(action.item.MenuFlowId, action.locationNo)),
                combineLatest(
                    this._store.pipe(select(selectors.isDownloadingAnyMenuFlowDefaultActivation)),
                    this._store.pipe(select(selectors.isDownloadingAnyIngredientModifiers)),
                ),
                filter(([, downloadingIngredients, downloadingActivations]) => downloadingIngredients === false && downloadingActivations === false),
                take(1),
                withLatestFrom(
                    this._store.pipe(select(selectors.getMenuFlowDefaultActivation(action.item.MenuFlowId))),
                    this._store.select(selectors.getOnlineMenuItemById(action.item.MenuFlowId)),
                    this._store.select(selectors.getOnlineMenuVirtualLocationsItemById(action.virtualLocationNo, action.item.MenuFlowId)),
                    this._store.select(selectors.getIngredientsByLocationNo(action.locationNo)),
                ),
                switchMap(([[menuFlow], , onlineMenuItem, onlineMenuVirtualLocationItem, modifiers]) => {
                    const _onlineMenuItem = onlineMenuItem ?? onlineMenuVirtualLocationItem;
                    /* New */
                    const menuFlowItem: OLO.State.Wizzard.WizzardMenuFlow = Utils.Items.createMenuFlowItemFromMenuFlowDetailsModel((menuFlow as OLO.State.MenuFlow).data, {
                        MenuFlowId: action.item.MenuFlowId,
                        LocationNo: action.locationNo as number,
                        VirtualLocationNo: action?.virtualLocationNo,
                        DisplayName: _onlineMenuItem.DisplayName,
                        Quantity: _onlineMenuItem.MinQty || 1,
                        DietaryTags: _onlineMenuItem.DietaryTags,
                        Tags: _onlineMenuItem.Tags,
                        Kilojoules: _onlineMenuItem.Kilojoules,
                        PosDisplay: _onlineMenuItem.PosDisplay,
                        PosDescription: _onlineMenuItem.PosDescription,
                        UnitTotalValue: null,
                        UnitPrice: null,
                    });

                    //
                    //      Proper default activation
                    //
                    menuFlow.data.Pages.forEach((Page) => {
                        Page.Products.forEach((Product) => {
                            const canActivate: boolean = Product.State === 0 && Number.isInteger(Product.KioskAutoAddQty) && Product.KioskAutoAddQty > 0;
                            if (canActivate) {
                                const targetPage: OLO.State.Wizzard.WizzardMenuFlowPage = menuFlowItem.Pages.find((obj) => obj.PageIdentifier === Page.PageIdentifier);
                                if (targetPage) {
                                    const canUseModifiers = !(this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false);
                                    const modifier = modifiers.find((obj) => obj.ProductId === Product.ProductId && obj.data !== null);
                                    const product: OLO.State.Wizzard.WizzardMenuFlowItem = Utils.Items.generateWizzardMenuFlowItem(
                                        Product,
                                        modifier && modifier.data ? modifier.data : null,
                                        {
                                            Quantity: Product.KioskAutoAddQty,

                                            ...(canUseModifiers === true
                                                ? {}
                                                : {
                                                    IngredientsChanges: {
                                                        IngredientsModified: [],
                                                        IngredientsAdded: [],
                                                        IngredientsRemoved: [],
                                                        IngredientsSwapped: [],
                                                    },
                                                }),
                                        },
                                    );

                                    targetPage.Products.push(product);

                                    targetPage._AutoAddProducts.push({
                                        ...product,
                                    });
                                }
                            }
                        });
                    });

                    return of(
                        actions.WizzardStepMenuFlowMount({
                            locationNo: action.locationNo,
                            menuFlowId: action.item.MenuFlowId,
                            item: menuFlowItem,
                            modalId: action.modalId,
                        }),
                    );
                }),
            ),
        ),
    );

    @Effect() public stepMountMenuFlow$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardStepMenuFlowMount),
        switchMap((action) => {
            if (action.modalId && action.modalId !== OLO.Components.Modals.MODAL_ID_TYPE.FORCE_NEW_MODAL) {
                this._modalsService.swap(action.modalId, {
                    type: action.item.IsUpsell ? 'upsell' : Utils.MenuFlows.extractType(action.item.Tags),
                });

                return never();
            }

            /* Add as is scenario */
            return this._store.pipe(
                select(selectors.getMenuFlowDetailsByWizzard),
                take(1),
                tap((menuFlowDetails) => {
                    if (menuFlowDetails) {
                        this._store.dispatch(actions.WizzardRecalculatePricesForMenuFlowRequest({ menuFlowDetails, wizzardMenuFlowDetails: action.item }));
                    }
                }),
                switchMap(() =>
                    this._store.pipe(
                        select(selectors.getWizzard),
                        delay(10),
                        filter((wizzard) => wizzard.recalculatePrices.hasSucceeded === true),
                        take(1),
                        switchMap((wizzard) => of(actions.CartSetup({ modalId: action.modalId, locationNo: wizzard.itemsMenuFlow.LocationNo, item: wizzard.itemsMenuFlow,
                            virtualLocationNo: wizzard.itemsMenuFlow?.VirtualLocationNo }))),
                    ),
                ),
            );
        }),
    );

    /* Upsell request is dispatched from cartEffects after cart and location valiation */
    @Effect() public onUpsellSetupRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardMenuFlowUpsellSetupRequest),
        tap(({ menuFlowId, locationNo, modalId, upsellInitiatorId }) => {
            this._modalsService.swap(modalId, {
                type: 'loading',
                locationNo: locationNo,
                menuFlowId: menuFlowId,
                upsellInitiatorId: upsellInitiatorId,
            });

            this._store.dispatch(actions.MenuFlowsDetailsRequest({ menuFlowId, locationNo }));
        }),
        delay(50),
        switchMap((action) =>
            this._store.pipe(
                select(selectors.getMenuFlow(action.menuFlowId, action.locationNo)),
                combineLatest(
                    this._store.pipe(select(selectors.isDownloadingAnyMenuFlowDefaultActivation)),
                    this._store.pipe(select(selectors.isDownloadingAnyIngredientModifiers)),
                    this._store.pipe(select(selectors.wizzardRecalculatePricesObj)),
                ),
                filter(
                    ([menuFlow, isDownloadingAnyMenuFlowDefaultActivation, isDownloadingAnyIngredientModifiers, recalculatePrices]) =>
                        menuFlow.isDownloading === false &&
                        menuFlow.data !== null &&
                        isDownloadingAnyIngredientModifiers === false &&
                        isDownloadingAnyMenuFlowDefaultActivation === false &&
                        recalculatePrices.isCalculating !== true &&
                        recalculatePrices.hasSucceeded !== true,
                ),
                take(1),
                switchMap(([menuFlow]) => {
                    if (!menuFlow?.data?.IsActive) {
                        this._modalsService.close(action.modalId);

                        return [];
                    }

                    const item: OLO.State.Wizzard.WizzardMenuFlow = Utils.Items.createMenuFlowItemFromMenuFlowDetailsModel(menuFlow.data, {
                        IsUpsell: true,
                        _Id: null,
                        LocationNo: action.locationNo,
                        VirtualLocationNo: action.virtualLocationNo,
                        Quantity: 1,
                        PosDisplay: menuFlow.data.MenuFlowDescription || menuFlow.data.MenuFlowName,
                        PosDescription: menuFlow.data.MenuFlowDescription || menuFlow.data.MenuFlowName,
                        UnitTotalValue: null,
                        UnitPrice: menuFlow.data.OverridePrice || 0,
                        UpsellInitiatorId: action.upsellInitiatorId,
                    });

                    return of(actions.WizzardMountMenuFlowUpsell({ item, modalId: action.modalId }));
                }),
            ),
        ),
    );

    @Effect() public setWizzardForUpsell$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardMountMenuFlowUpsell),
        switchMap((action) => {
            this._modalsService.swap(action.modalId, {
                type: 'upsell',
            });

            return never();
        }),
    );

    @Effect() public onWizzardProductSelectedActivateDefaultModifier$ = this._actions$.pipe(
        ofType(actions.WizzardMenuFlowAddProduct),
        withLatestFrom(this._store.pipe(select(selectors.getWizzardMenuFlow))),
        switchMap(([action, wizzardMenuFlow]) => {
            const pageIdentifier = action.pageIdentifier;
            const productId = action.product.ProductId;
            const locationNo = wizzardMenuFlow.LocationNo;

            return this._store.pipe(
                select(selectors.getIngredientsForProduct(productId, locationNo)),
                skipWhile((ingredient) => !ingredient),
                filter(
                    (ingredient) =>
                        ingredient.hasSucceeded === true && ingredient.data !== null && ingredient.data.Ingredients !== null && ingredient.data.Ingredients.length !== 0,
                ),
                take(1),
                map((ingredient) => ({
                    ingredient: ingredient.data.Ingredients[0],
                    pageIdentifier,
                    product: action.product,
                    wizzardMenuFlow,
                })),
            );
        }),
        switchMap(() => {
            /* TODO!!!! What to do if modifier is optional? For now, just select first */
            if (this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false) return never();

            // Model has changed, there is no IsOptional flag in our api model
            // if (ingredient.IsOptional || !ingredient.IsOptional) {
            //     return of(actions.WizzardMenuFlowSelectModifier({ modifier: ingredient.Modifiers[0], pageIdentifier, productId: product.ProductId }));
            // }
            // if (ingredient) {
            // return of(actions.WizzardMenuFlowSelectModifier({ modifier: ingredient.Modifiers[0], pageIdentifier, productId: product.ProductId }));
            // }

            return never();
        }),
    );

    @Effect() public requestPricesUpdateWizzardMenuFlowPricesOnChange$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.WizzardStepMenuFlowMount,
            actions.WizzardMenuFlowSpecialInstructions,
            actions.WizzardMenuFlowRemoveProduct,
            actions.WizzardMenuFlowAddProduct,
            actions.WizzardMenuFlowProductIncrement,
            actions.WizzardMenuFlowProductChangeQuantity,
            actions.WizzardMenuFlowProductDecrement,
            actions.WizzardMenuFlowIncrement,
            actions.WizzardMenuFlowDecrement,
            actions.WizzardMenuFlowSelectModifier,
            actions.WizzardMenuFlowChangeQuantity,
            actions.WizzardMountMenuFlowUpsell,
            // actions.WizzardMenuFlowUpsellSetupRequest,
            actions.WizzardMenuFlowReplaceAllWithNew,
        ),
        switchMap(() =>
            this._store.pipe(
                select(selectors.getWizzardMenuFlow),
                filter((wizzardMenuFlow) => wizzardMenuFlow !== null && wizzardMenuFlow !== undefined),
                take(1),
                switchMap((wizzardMenuFlow) =>
                    this._store.select(selectors.getMenuFlow(wizzardMenuFlow.MenuFlowId, wizzardMenuFlow.LocationNo)).pipe(
                        filter((menuFlow) => menuFlow !== null && menuFlow !== undefined && menuFlow.hasSucceeded === true && menuFlow.data !== null),
                        take(1),
                        map(
                            (menuFlow) => actions.WizzardRecalculatePricesForMenuFlowRequest({ menuFlowDetails: menuFlow.data, wizzardMenuFlowDetails: wizzardMenuFlow }),
                            // return actions
                            // .WizzardRecalculatePricesForMenuFlowSuccessRequest({ priceObj: Utils.Pricing.calculatePricesFromMenuFlow(menuFlow.data, wizzardMenuFlow) });
                        ),
                    ),
                ),
            ),
        ),
    );

    @Effect() public updatePricesWizzardMenuFlowDetaisl$: Observable<Action> = this._actions$.pipe(
        ofType(actions.WizzardRecalculatePricesForMenuFlowRequest),
        switchMap(({ menuFlowDetails, wizzardMenuFlowDetails }) => {
            const priceObj: OLO.Ordering.PricingMenuFlow = Utils.Pricing.calculatePricesFromMenuFlow(menuFlowDetails, wizzardMenuFlowDetails);

            if (priceObj.HasErrors) {
                console.error('Adding to cart menu flow with bad price setup has been disabled');

                return of(actions.WizzardRecalculatePricesForMenuFlowErrorRequest({ menuFlowDetails, wizzardMenuFlowDetails, ex: 'Setup issue' }));
            }

            return of(actions.WizzardRecalculatePricesForMenuFlowSuccessRequest({ menuFlowDetails, wizzardMenuFlowDetails, priceObj }));
        }),
    );

    @Effect() public validateErrorsOnWizzardChange$: Observable<Action | never> = this._actions$.pipe(
        ofType(
            actions.WizzardMenuFlowRemoveProduct,
            actions.WizzardMenuFlowAddProduct,
            actions.WizzardMenuFlowProductIncrement,
            actions.WizzardMenuFlowProductChangeQuantity,
            actions.WizzardMenuFlowProductDecrement,
            actions.WizzardMenuFlowIncrement,
            actions.WizzardMenuFlowDecrement,
            actions.WizzardMenuFlowChangeQuantity
        ),
        withLatestFrom(this._store.select(selectors.getWizzardErrors), this._store.select(selectors.getMenuFlowDetailsByWizzard), this._store.select(selectors.getWizzardMenuFlow)),
        switchMap(([, errors, menuFlow, wizzardMenuFlow]) => {
            if (errors && errors.length > 0) {
                return of(actions.WizzardValidate({ errors: this._wizzardService.validateWizzardItems(menuFlow, wizzardMenuFlow) }));
            }

            return never();
        }),
    );

    @Effect() public openWizzardByUrlParams$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineMenuPagesSuccessRequest),
        combineLatest(
            this._store.pipe(
                select(selectors.getCurrentLocationNo),
                filter((location) => location != null),
                take(1),
            ),
        ),
        switchMap(([action, locationNo]) =>
            this._store.pipe(
                select(selectors.canOrderFromLocation(locationNo)),
                filter((canOrder) => canOrder !== null),
                take(1),
                combineLatest(
                    this._store.pipe(
                        select(selectors.routeIsLocationDetailsPage()),
                        filter((isLocationDetailsView) => isLocationDetailsView !== null),
                        take(1),
                    ),
                    this._store.pipe(select(selectors.getCurrentRouteQueryParams), take(1)),
                ),
                switchMap(([canOrder, isLocationDetailsView, params]) => {
                    const exit = () => {
                        if (params && isLocationDetailsView && action.locationNo === locationNo) {
                            this._queryParamsService.clearQueryParams('menuFlowId', 'productId', 'menuflowId', 'menuflowid', 'productid');
                        }

                        return never();
                    };
                    if (!canOrder || !locationNo || !isLocationDetailsView || !params || Object.keys(params).length === 0 || action.locationNo !== locationNo) return exit();

                    const transformedParams: { menuflowid?: number; productid?: number; } = {};
                    Object.keys(params).forEach((param) => {
                        transformedParams[param.toLowerCase()] = +params[param];
                    });

                    const menuFlowId: number = transformedParams.menuflowid || null;
                    const productId: number = transformedParams.productid || null;

                    if (!menuFlowId && !productId) return exit();

                    let product: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem | OLO.DTO.OnlineMenuProductResponseModel = null;
                    for (let i = 0, j = action.payload.Pages.length; i < j; i++) {
                        if (product) break;

                        const page: OLO.DTO.OnlineMenuPageResponseModel = action.payload.Pages[i];
                        for (let k = 0, l = page.Products.length; k < l; k++) {
                            if (product) break;

                            const pageProduct = page.Products[k];
                            if (menuFlowId && pageProduct.MenuFlowId === menuFlowId) {
                                product = pageProduct;
                                break;
                            }

                            if (productId && pageProduct.ProductId === productId) {
                                product = pageProduct;
                                break;
                            }
                        }
                    }

                    if (!product) return exit();

                    const modalId: number = Math.floor(Math.random() * 1000000) + new Date().getTime();
                    this._queryModalIds.push(modalId);

                    return of(actions.WizzardSetupItem(locationNo, product, modalId));
                }),
            ),
        ),
    );

    @Effect() public removeQueryParamsOnQueryModalClose$: Observable<Action> = this._actions$.pipe(
        ofType(actions.ModalClose, actions.ModalCloseAll),
        switchMap((action) => {
            let unmountWizzard: boolean = false;

            if (this._queryModalIds.length) {
                if (action.type === actions.ModalCloseAll.type) {
                    unmountWizzard = true;
                    this._queryModalIds.length = 0;
                } else {
                    const index = this._queryModalIds.indexOf(action.id);
                    if (index !== -1) {
                        unmountWizzard = true;
                        this._queryModalIds.splice(index, 1);
                    }
                }
            }

            if (unmountWizzard) {
                this._queryParamsService.clearQueryParams('menuFlowId', 'productId');

                return of(actions.WizzardUnmountAll());
            }

            return never();
        }),
    );

    private _checkHasOnlineMenuOrIsVirtualLocation$(virtualLocationNo: number = null): Observable<OLO.State.OnlineMenu> {
        return this._store.pipe(
            select(selectors.getOnlineMenu),
            filter(onlineMenu => {
                const onlineMenuHasData = onlineMenu.isDownloading === false && onlineMenu.data !== null;
                const onlineMenuHasNotDataButVirtualLocation = !onlineMenuHasData && Boolean(virtualLocationNo);

                return onlineMenuHasData || onlineMenuHasNotDataButVirtualLocation;
            })
        );
    }

    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _modalsService: Services.ModalsService,
        private _wizzardService: Services.WizzardService,
        private _queryParamsService: Services.QueryParamsService,
    ) {}
}
