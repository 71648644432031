import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import { map } from 'rxjs/operators';
import { FavoriteOrdersMapper } from '@shared/core/mappers/favorite-orders.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class FavoriteOrdersService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient
    ) {
    }

    public createCombos(comboModel: OLO.DTO.MemberFavouriteCombosModel): Observable<OLO.DTO.MemberFavouriteCombosModel[]> {
        /* You can add order name here */
        const postModel: APIv3.MemberFavouriteCombosModel = FavoriteOrdersMapper.mapCreateCombosPostRequest(comboModel);

        return this.httpClient
            .post<APIv3.MemberFavouriteCombosModel[]>(`${this.config.api.base}/FavouriteOrders/combos`, postModel)
            .pipe(
                map((results: APIv3.MemberFavouriteCombosModel[]) => FavoriteOrdersMapper.mapCreateCombosPostResponse(results))
            );
    }

    public getOrders(memberId: number): Observable<OLO.DTO.MemberFavouriteOrdersModel[]> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                memberId: String(memberId),
            },
        });

        return this.httpClient
            .get<APIv3.MemberFavouriteOrdersModel[]>(`${this.config.api.base}/FavouriteOrders/orders`, { params })
            .pipe(
                map((results: APIv3.MemberFavouriteOrdersModel[]) => FavoriteOrdersMapper.mapGetOrders(results))
            );
    }

    public remove(orderId: number): Observable<OLO.DTO.MemberFavouriteOrdersModel> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                orderId: String(orderId),
            },
        });

        return this.httpClient
            .delete<APIv3.MemberFavouriteOrdersModel>(`${this.config.api.base}/FavouriteOrders/orders`, { params })
            .pipe(
                map((results: APIv3.MemberFavouriteOrdersModel) => FavoriteOrdersMapper.mapRemoveResponse(results))
            );
    }

    public add(order: OLO.DTO.OnlineOrderDetailedBusinessModel, MemberId: number): Observable<OLO.DTO.MemberFavouriteOrdersModel> {
        const postModel: APIv3.MemberFavouriteOrdersModel = FavoriteOrdersMapper.mapAddRequest(order, MemberId);

        return this.httpClient
            .post<APIv3.MemberFavouriteOrdersModel>(`${this.config.api.base}/FavouriteOrders/orders`, postModel)
            .pipe(
                map((results: APIv3.MemberFavouriteOrdersModel) => FavoriteOrdersMapper.mapAddResponse(results))
            );
    }
}
