import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class IngredientsService {
    constructor(
        public store: Store<OLO.State>,
    ) {
    }
}
