// https://docs.adyen.com/payment-methods/cards/custom-card-integration?tab=codeBlockqeV1b_6#show-the-available-cards-in-your-payment-form
import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';

import { Observable, throwError, of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { AdyenPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/adyen.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class AdyenPaymentProviderService {
    private _instance: Adyen.ReactInstance;
    private _formHandlerActions: Adyen.PPFormHandlerActions;

    private _scriptElem: HTMLScriptElement;

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient) {}

    public requestConfig(locationNo: number, defaultSettings: OLO.DTO.AdyenSettingsResponse = null): Observable<OLO.DTO.AdyenSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for Adyen payment provider');

            return of(defaultSettings).pipe(take(1));
        }

        return this._getSettingsForLocation(locationNo);
    }

    protected _getSettingsForLocation(locationNo: number): Observable<OLO.DTO.AdyenSettingsResponse> {
        return this.httpClient.get<APIv3.AdyenSettingsResponse>(`${this.config.api.base}/Payments/adyen/settings/${locationNo}`).pipe(
            map((response) => AdyenPaymentProviderMapper.mapAdyenPaymentGetSettingsForLocation(response)),
            catchError((ex) => {
                console.error('LocationNo not provided', ex);

                return throwError(ex);
            }),
        );
    }

    public async addHtmlElementsToDOM(): Promise<boolean> {
        return this._addScriptElement();
    }

    public setupForm(config: OLO.DTO.AdyenSettingsResponse, targetHtmlElement: HTMLElement | string, configuration?: Adyen.Configuration): void {
        if (this._formHandlerActions) {
            this.destroyForm();
        }

        this._instance = new AdyenCheckout({
            locale: 'en_US',
            environment: config.Environment as Adyen.EnvironmentType,
            clientKey: config.ClientKey,
            onChange: this.onChangeHandler.bind(this),
        });

        this._formHandlerActions = this._instance.create('securedfields', {
            styles: {
                base: {
                    fontSize: '16px',
                },
                error: {
                    color: 'red',
                },
            },
            ...configuration,
        });

        this._formHandlerActions.mount(targetHtmlElement);
    }

    protected onChangeHandler(state: Adyen.StateObj, componentInstance: Adyen.ReactInstance): void {
        console.warn('Provider outter changeHanlder.');
    }

    public destroyForm(): void {
        this._instance = null;
        this._formHandlerActions = null;
    }

    protected async _addScriptElement(): Promise<boolean> {
        if (this._scriptElem) return null;

        return new Promise((resolve, reject) => {
            this._scriptElem = document.createElement('script');
            this._scriptElem.src = 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.13.0/adyen.js';
            this._scriptElem.integrity = 'sha384-cMH19I9HPj31iL3b/lcBcpsqbieCGSLyNef+RzjS7g3h5DhP2BI6j68/ogKSQCAh';
            this._scriptElem.crossOrigin = 'anonymous';

            this._scriptElem.onload = () => resolve(true);
            this._scriptElem.onerror = () => reject('Unable to load adyen script element');

            document.body.appendChild(this._scriptElem);
        });
    }

    protected _removeScriptElement(): void {
        if (!this._scriptElem) return;

        document.body.removeChild(this._scriptElem);
        this._scriptElem = null;
    }
}
